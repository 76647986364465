import type { SVGAttributes } from 'react';
import { HardwareIconSVG } from 'atto-svgs';
import React from 'react';

import type { SpacingValue } from '../../common/sizing';
import { styled } from '../../stitches.config';

export const HardwareIcons = {
  AccessPointDetailed: HardwareIconSVG.AccessPointDetailed,
  AccessPointSimple: HardwareIconSVG.AccessPointSimple,
  PDUDetailed: HardwareIconSVG.PDUDetailed,
  PDUSimple: HardwareIconSVG.PDUSimple,
  SecurityApplianceDetailed: HardwareIconSVG.SecurityApplianceDetailed,
  SecurityApplianceSimple: HardwareIconSVG.SecurityApplianceSimple,
  SwitchDetailed: HardwareIconSVG.SwitchDetailed,
  SwitchSimple: HardwareIconSVG.SwitchSimple,
};

export const HardwareIconValues = [
  'access-point',
  'pdu',
  'power-distribution-unit',
  'security-appliance',
  'switch',
] as const;
export type HardwareIconName = (typeof HardwareIconValues)[number];
export const HardwareIconNames = Object.keys(HardwareIconValues) as HardwareIconName[];

export type HardwareIconPropSize = SpacingValue;
export type HardwareIconPropVariant = 'detailed' | 'simple';

export interface HardwareIconProps {
  /**
   * Set which piece of hardware to display.
   */
  icon: HardwareIconName;
  /**
   * Set a size of the component.
   */
  size?: HardwareIconPropSize;
  /**
   * Set which variant, either `detailed` or `simple`.
   */
  variant?: HardwareIconPropVariant;
}

const BaseSVG = styled('svg', {
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
});

const getHardwareIconName = (icon: HardwareIconName) => {
  switch (icon) {
    case 'security-appliance':
      return 'SecurityAppliance';
    case 'switch':
      return 'Switch';
    case 'pdu':
    case 'power-distribution-unit':
      return 'PDU';
    default:
      return 'AccessPoint';
  }
};

const getHardwareIconVariant = (variant: HardwareIconPropVariant) => {
  switch (variant) {
    case 'simple':
      return 'Simple';
    default:
      return 'Detailed';
  }
};

export function HardwareIcon({
  icon = 'access-point',
  size = 16,
  variant = 'detailed',
  ...remaining
}: HardwareIconProps) {
  const hardwareVariant = getHardwareIconName(icon) + getHardwareIconVariant(variant);
  const NamedHardwareIcon = HardwareIcons[hardwareVariant as keyof typeof HardwareIcons] as
    | React.ComponentType<SVGAttributes<SVGSVGElement>>
    | undefined;

  if (!NamedHardwareIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${icon}`);
    return null;
  }

  return <BaseSVG as={NamedHardwareIcon} width={size} height={size} {...remaining} />;
}
