import { FavIconSVG } from 'atto-svgs';
import React from 'react';

import type { SpacingValue } from '../../common/sizing';
import { styled } from '../../stitches.config';

export type FavIconSize = SpacingValue;

export const FavIcons = {
  favicon: FavIconSVG.FavIcon,
};

export type FavIconName = keyof typeof FavIcons;
export const FavIconNames = Object.keys(FavIcons) as FavIconName[];

export interface FavIconProps {
  /**
   * Set a size of the component.
   */
  size?: FavIconSize;
}

const BaseSVG = styled('svg', {
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
});

export function FavIcon({ size = 16, ...remaining }: FavIconProps) {
  return <BaseSVG as={FavIcons.favicon} width={size} height={size} {...remaining} />;
}
