import type {
  ShellContentProps,
  ShellFooterProps,
  ShellHeaderProps,
  ShellProps,
} from '../Shell/Shell';
import { styled } from '../../stitches.config';
import { LayoutSkimmer } from '../Layout/Layout';
import { Shell, ShellContent, ShellFooter, ShellHeader } from '../Shell/Shell';

const SkimmerHeaderContainer = styled(ShellHeader);

export function SkimmerHeader(props: ShellHeaderProps) {
  return <SkimmerHeaderContainer {...props} />;
}

const SkimmerContentContainer = styled(ShellContent);

export function SkimmerContent(props: ShellContentProps) {
  return <SkimmerContentContainer {...props} />;
}

const SkimmerFooterContainer = styled(ShellFooter);

export function SkimmerFooter(props: ShellFooterProps) {
  return <SkimmerFooterContainer {...props} />;
}

const SkimmerContainer = styled(Shell, {
  '@mobile': {
    width: '100%',
    minHeight: '$160',
    maxHeight: '$360',
  },

  '@notMobile': {
    width: '$360',
  },

  variants: {
    contentMode: {
      fit: {},
      stretch: {},
    },
  },
});

export function Skimmer({ children, ...props }: { children: React.ReactNode } & ShellProps) {
  return (
    <LayoutSkimmer>
      <SkimmerContainer {...props}>{children}</SkimmerContainer>
    </LayoutSkimmer>
  );
}
