import { merge } from 'lodash-es';

import type { CSS } from '../../stitches.config';
import type { SpacingScale } from './sizes';

export type MarginSpacing = SpacingScale;

export type MarginProps = {
  all?: MarginSpacing;
  x?: MarginSpacing;
  y?: MarginSpacing;
  top?: MarginSpacing;
  right?: MarginSpacing;
  bottom?: MarginSpacing;
  left?: MarginSpacing;
};

export const marginCSS = ({ all, x, y, top, right, bottom, left }: MarginProps = {}): CSS =>
  merge(
    {},
    all != null ? { margin: `$${all}` } : {},
    x != null ? { marginX: `$${x}` } : {},
    y != null ? { marginY: `$${y}` } : {},
    top != null ? { marginTop: `$${top}` } : {},
    right != null ? { marginRight: `$${right}` } : {},
    bottom != null ? { marginBottom: `$${bottom}` } : {},
    left != null ? { marginLeft: `$${left}` } : {},
  );
