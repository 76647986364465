import type { ButtonHTMLAttributes } from 'react';
import React from 'react';

import { Icon } from '../../assets/Icon/Icon';
import { FocusRing } from '../../common/focus_rings';
import { colors, darkThemeSelector, styled } from '../../stitches.config';

const CloseButtonIcon = styled(Icon, {
  '@maxSm': {
    width: '$12',
    height: '$12',
  },

  '@sm': {
    width: '$10',
    height: '$10',
  },
});

const CloseButtonContainer = styled('button', FocusRing, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.gray50,
  color: colors.gray600,
  strokeAll: colors.transparent,

  [darkThemeSelector]: {
    backgroundColor: colors.gray900,
    color: colors.gray200,
  },

  '&:hover': {
    backgroundColor: colors.bgApplicationLight,
    color: colors.gray800,
    strokeAll: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.bgApplicationDark,
      color: colors.gray50,
      strokeAll: colors.strokeNeutralDark,
    },
  },

  '@maxSm': {
    borderRadius: '$6',
    width: '$28',
    height: '$28',
  },

  '@sm': {
    borderRadius: '$4',
    width: '$20',
    height: '$20',
  },
});

export interface DeprecatedCloseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  'aria-label': string;
}

export const DeprecatedCloseButton = React.forwardRef<
  HTMLButtonElement,
  DeprecatedCloseButtonProps
>((props: DeprecatedCloseButtonProps, ref) => (
  <CloseButtonContainer type="button" {...props} ref={ref}>
    <CloseButtonIcon icon="cross" />
  </CloseButtonContainer>
));
