export function hasParentWithClass(element: Element, className: string) {
  let currentElement = element.parentElement;

  while (currentElement) {
    if (currentElement.classList.contains(className)) {
      return true;
    }
    currentElement = currentElement.parentElement;
  }

  return false;
}
