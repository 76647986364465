import type { DOMAttributes } from '@react-types/shared';
import * as React from 'react';
import { useMemo } from 'react';

import type { HasChildren } from '../../types/components';
import type { BaseFieldProps } from './common/types';

export interface CustomFieldPropsValue<D> {
  inputProps: DOMAttributes & object;
  fieldProps: Partial<
    Omit<BaseFieldProps, 'errorMessage'> & {
      errorMessage?: D;
    }
  >;
}

export const CustomFieldPropsContext = React.createContext<CustomFieldPropsValue<any>>({
  inputProps: {},
  fieldProps: {},
});

export function useCustomFieldProps() {
  return React.useContext(CustomFieldPropsContext);
}

export function createCustomFieldPropsProvider<T, D>(fn: (props: T) => CustomFieldPropsValue<D>) {
  return function FieldPropsProvider(props: T & HasChildren) {
    const { inputProps, fieldProps } = fn(props);
    const value = useMemo(() => ({ inputProps, fieldProps }), [inputProps, fieldProps]);
    return (
      <CustomFieldPropsContext.Provider value={value}>
        {props.children}
      </CustomFieldPropsContext.Provider>
    );
  };
}
