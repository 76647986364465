import type { ReactNode } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import { Icon } from '../../assets/Icon/Icon';
import { ControlSizeProvider } from '../../common/control_size';
import { ControlVariantProvider } from '../../common/control_variant';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body } from '../../text/Body';

const EmptyStateIcon = styled(Icon, {
  width: '$24',
  height: '$24',
  color: colors.gray600,
  [darkThemeSelector]: {
    color: colors.gray100,
  },
});

const EmptyStateHeading = styled(Body, {
  fontWeight: fontWeights.bold,
  color: colors.gray700,
  textAlign: 'center',
  [darkThemeSelector]: {
    color: colors.gray50,
  },
});

const EmptyStateSubheading = styled(Body, {
  color: colors.gray600,
  textAlign: 'center',
  [darkThemeSelector]: {
    color: colors.gray100,
  },
});

const EmptyStateHeader = styled('div', {
  vStack: '$0',
});

const EmptyStateContent = styled('div', {
  vStack: '$12',
  display: 'flex',
  flex: '70%',
});

const EmptyStateBuffer = styled('div', {
  display: 'flex',
  flex: '30%',
});

const EmptyStateContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  minHeight: '$180',
  padding: '$20 $32',
});

export interface EmptyStateProps {
  heading: ReactNode;
  subheading?: ReactNode;
  icon?: IconName;
  action?: ReactNode;
}

export function EmptyState({ heading, subheading, icon, action, ...remaining }: EmptyStateProps) {
  return (
    <EmptyStateContainer {...remaining}>
      <EmptyStateBuffer />
      <EmptyStateContent>
        {icon && <EmptyStateIcon icon={icon} />}
        <EmptyStateHeader>
          <EmptyStateHeading>{heading}</EmptyStateHeading>
          {subheading && <EmptyStateSubheading>{subheading}</EmptyStateSubheading>}
        </EmptyStateHeader>
        {action && (
          <ControlSizeProvider value="small">
            <ControlVariantProvider value="secondary">{action}</ControlVariantProvider>
          </ControlSizeProvider>
        )}
      </EmptyStateContent>
    </EmptyStateContainer>
  );
}
