import type { CollectionFactory } from '@react-stately/collections';
import type { Collection, Node } from '@react-types/shared';
import type { ListProps } from 'react-stately';
import { getChildNodes } from '@react-stately/collections';
import { ListCollection } from '@react-stately/list';
import React from 'react';
import { useCollection } from 'react-stately';

type FilterFn = (textValue: string, inputValue: string) => boolean;

// Largely replicates code from react-stately's useComboBoxState hook that isn't exported:
// https://github.com/adobe/react-spectrum/blob/main/packages/%40react-stately/combobox/src/useComboBoxState.ts

function filterNodes<T>(
  collection: Collection<Node<T>>,
  nodes: Iterable<Node<T>>,
  inputValue: string,
  filter: FilterFn,
): Iterable<Node<T>> {
  const filteredNode = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const node of nodes) {
    if (node.type === 'section' && node.hasChildNodes) {
      const filtered = filterNodes(collection, getChildNodes(node, collection), inputValue, filter);
      if ([...filtered].some((n) => n.type === 'item')) {
        filteredNode.push({ ...node, childNodes: filtered });
      }
    } else if (node.type === 'item' && filter(node.textValue, inputValue)) {
      filteredNode.push({ ...node });
    } else if (node.type !== 'item') {
      filteredNode.push({ ...node });
    }
  }
  return filteredNode;
}

function filterCollection<T extends object>(
  collection: Collection<Node<T>>,
  inputValue: string,
  filter: FilterFn,
): Collection<Node<T>> {
  return new ListCollection(filterNodes(collection, collection, inputValue, filter));
}

export function useFilteredList<T extends object>(
  props: ListProps<T>,
  filterValue: string,
  filterFn: FilterFn,
) {
  const factory: CollectionFactory<T, Collection<Node<T>>> = React.useCallback(
    (nodes) => filterCollection(new ListCollection<T>(nodes), filterValue, filterFn),
    [filterFn, filterValue],
  );

  return useCollection(props, factory);
}
