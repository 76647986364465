import React from 'react';

import type { PolymorphicRef } from '../types/polymorphicAsProp';
import type { StackProps } from './Stack';
import { Stack } from './Stack';

export type VStackProps = StackProps<'div'> & {
  children: React.ReactNode;
  reverse?: boolean;
};

export const VStack = React.forwardRef(
  <Tag extends React.ElementType>(
    { children, reverse, ...remaining }: VStackProps,
    ref: PolymorphicRef<Tag>,
  ) => (
    <Stack {...remaining} direction={reverse ? 'column-reverse' : 'column'} ref={ref}>
      {children}
    </Stack>
  ),
);
