import { colors, darkThemeSelector, styled } from '../stitches.config';
import { Text } from './Text';

const SubheadingBase = {
  color: colors.headingNeutralLight,
  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },
  variants: {
    internal: {
      true: {
        color: colors.internalBodyLight,
        [darkThemeSelector]: {
          color: colors.internalBodyDark,
        },
      },
      false: {},
    },
  },
};

export const SubheadingSansSizes = {
  '@notDesktop': {
    fontSize: '$16',
    lineHeight: '$24',
  },
  '@desktop': {
    fontSize: '$14',
    lineHeight: '$20',
  },
};

export const SubheadingMonoSizes = {
  '@notDesktop': {
    fontSize: '15px',
    lineHeight: '$24',
  },
  '@desktop': {
    fontSize: '13px',
    lineHeight: '$20',
  },
};

export const Subheading = styled(Text, SubheadingBase, {
  variants: {
    context: {
      body: {},
      heading: {},
    },
    family: {
      regular: SubheadingSansSizes,
      monospace: SubheadingMonoSizes,
    },
    weight: {
      bold: {},
    },
  },
  defaultVariants: {
    context: 'heading',
    family: 'regular',
    weight: 'bold',
  },
});
