import { SectionContent, SectionFooter, SectionHeader } from '../components/Section/Section';
import { DialogContent, DialogFooter, DialogHeader } from '../frame/Dialog/Dialog';
import { DrawerContent, DrawerFooter, DrawerHeader } from '../frame/Drawer/Drawer';
import { PaneContent, PaneFooter, PaneHeader } from '../frame/Pane/Pane';
import { SkimmerContent, SkimmerFooter, SkimmerHeader } from '../frame/Skimmer/Skimmer';

export type FrameProp = 'dialog' | 'drawer' | 'pane' | 'section' | 'skimmer';

export const getFrameHeader = (framing: FrameProp) => {
  if (framing === 'drawer') {
    return DrawerHeader;
  }
  if (framing === 'dialog') {
    return DialogHeader;
  }
  if (framing === 'section') {
    return SectionHeader;
  }
  if (framing === 'skimmer') {
    return SkimmerHeader;
  }
  return PaneHeader;
};

export const getFrameContent = (framing: FrameProp) => {
  if (framing === 'drawer') {
    return DrawerContent;
  }
  if (framing === 'dialog') {
    return DialogContent;
  }
  if (framing === 'section') {
    return SectionContent;
  }
  if (framing === 'skimmer') {
    return SkimmerContent;
  }
  return PaneContent;
};

export const getFrameFooter = (framing: FrameProp) => {
  if (framing === 'drawer') {
    return DrawerFooter;
  }
  if (framing === 'dialog') {
    return DialogFooter;
  }
  if (framing === 'section') {
    return SectionFooter;
  }
  if (framing === 'skimmer') {
    return SkimmerFooter;
  }
  return PaneFooter;
};
