import { merge } from 'lodash-es';

import type { ColorPalette } from '../../common/colors';
import type { CSS } from '../../stitches.config';
import { darkThemeSelector } from '../../stitches.config';

export type ColorTypes = ColorPalette;
export type ColorProps = { dark?: ColorTypes; light?: ColorTypes };

export const colorCSS = (dark?: ColorTypes, light?: ColorTypes): CSS =>
  merge(
    {},
    light != null ? { color: `$${light}` } : {},
    dark != null ? { [darkThemeSelector]: { color: `$${dark}` } } : {},
  );
