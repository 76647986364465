import type { TextContextProp, TextVariantProp } from './Text';
import { Body } from './Body';
import { Caption } from './Caption';
import { Heading } from './Heading';
import { Large } from './Large';
import { Small } from './Small';
import { Subheading } from './Subheading';
import { Title } from './Title';

export type DynamicTextPropType =
  | 'caption'
  | 'small'
  | 'body'
  | 'heading'
  | 'large'
  | 'title'
  | 'subheading';

export type DynamicTextProps = {
  context?: TextContextProp;
  children: React.ReactNode;
  type: DynamicTextPropType;
  variant?: TextVariantProp;
};

function DynamicTextElement({
  context = 'body',
  type,
  variant,
  ...remaining
}: Required<DynamicTextProps>) {
  switch (type) {
    case 'caption':
      return <Caption context={context} variant={variant} {...remaining} />;
    case 'small':
      return <Small context={context} variant={variant} {...remaining} />;
    case 'body':
      return <Body context={context} variant={variant} {...remaining} />;
    case 'subheading':
      return <Subheading context={context} variant={variant} {...remaining} />;
    case 'large':
      return <Large context={context} variant={variant} {...remaining} />;
    case 'heading':
      return <Heading context={context} variant={variant} {...remaining} />;
    case 'title':
      return <Title context={context} variant={variant} {...remaining} />;
  }
}

export function DynamicText({
  children,
  context = 'body',
  type,
  variant = 'neutral',
  ...remaining
}: DynamicTextProps) {
  return (
    <DynamicTextElement context={context} type={type} variant={variant} {...remaining}>
      {children}
    </DynamicTextElement>
  );
}
