import { createContext, useContext } from 'react';

import type { BadgeEnds } from './Badge';

const BadgeEndsContext = createContext<BadgeEnds | undefined>(undefined);
export const BadgeEndsProvider = BadgeEndsContext.Provider;
export const useBadgeEnds = (controlledEnds?: BadgeEnds, defaultEnds: BadgeEnds = 'pill') => {
  const controlEnds = useContext(BadgeEndsContext);
  return controlledEnds ?? controlEnds ?? defaultEnds;
};
