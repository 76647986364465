import type { CSS } from '../../stitches.config';

export type FontSizeProp = 11 | 12 | 13 | 14 | 16 | 20 | 24;

export const fontSizeCSS = (fontSize?: FontSizeProp): CSS =>
  fontSize != null
    ? {
        fontSize: `$${fontSize}`,
      }
    : {};
