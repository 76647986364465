import type { AriaLabelingProps } from '@react-types/shared';
import React from 'react';
import { useLabel } from 'react-aria';

import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { HStack } from '../../utilities/Stack/HStack';

export interface LabelProps extends AriaLabelingProps {
  /**
   * Displayed as the label of the component.
   */
  children: React.ReactNode;
  /**
   * Set the component to disabled.
   */
  disabled?: boolean;
  /**
   * Set the for property in HTMl.
   */
  htmlFor?: string;
  /**
   * Set the component to invalid.
   */
  invalid?: boolean;
}

const LabelLabel = styled(Body, {
  color: colors.bodyNeutralLight,
  fontWeight: fontWeights.bold,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },

  '&:hover': {
    color: colors.headingNeutralLight,
    [darkThemeSelector]: {
      color: colors.headingNeutralDark,
    },
  },
  variants: {
    disabled: {
      true: {
        '&, &:hover': {
          color: colors.gray500,
          [darkThemeSelector]: {
            color: colors.gray300,
          },
        },
      },
      false: {},
    },
    invalid: {
      true: {
        color: colors.bodyNegativeLight,
        '&:hover': {
          color: colors.headingNegativeLight,
        },
        [darkThemeSelector]: {
          color: colors.bodyNegativeDark,
          '&:hover': {
            color: colors.headingNegativeDark,
          },
        },
      },
      false: {},
    },
  },
});

const LabelContainer = styled('label', {
  display: 'block',
  width: '100%',
  cursor: 'pointer',
  userSelect: 'none',

  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
      },
      false: {
        cursor: 'pointer',
      },
    },
  },
});

export function Label({ children, disabled, htmlFor, invalid, ...rest }: LabelProps) {
  const { fieldProps, labelProps } = useLabel(rest);
  return (
    <LabelContainer htmlFor={htmlFor} {...fieldProps} {...labelProps}>
      <LabelLabel disabled={disabled} invalid={invalid}>
        <HStack width="full" align="center" spacing={8}>
          {children}
        </HStack>
      </LabelLabel>
    </LabelContainer>
  );
}
