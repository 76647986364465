export type Platform = 'mac' | 'linux' | 'windows';

function extractPlatformFromString(platform: string): Platform {
  if (platform.startsWith('Mac')) {
    return 'mac';
  }

  if (platform.startsWith('Linux')) {
    return 'linux';
  }

  return 'windows';
}

export function useUsersOS() {
  return extractPlatformFromString(window.navigator.platform);
}
