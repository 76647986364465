import { colors, darkThemeSelector, fontWeights, styled } from '../../../stitches.config';
import { Body } from '../../../text/Body';
import { Small } from '../../../text/Small';

const FieldLabel = styled('label', {
  color: colors.headingNeutralLight,
  fontWeight: fontWeights.bold,

  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },

  variants: {
    internal: {
      true: {
        color: colors.internalHeadingLight,

        [darkThemeSelector]: {
          color: colors.internalHeadingDark,
        },
      },
    },
  },
});

const FieldDescription = styled(Small, {
  variants: {
    internal: {
      true: {
        color: colors.internalBodyLight,

        [darkThemeSelector]: {
          color: colors.internalBodyDark,
        },
      },
    },
  },
});

/** Primary composites */
export const PrimaryFieldLabel = styled(FieldLabel, Body);
export const PrimaryFieldDescription = styled(FieldDescription);

/** Secondary composites */
export const SecondaryFieldLabel = styled(FieldLabel, Small);
export const SecondaryFieldDescription = styled(FieldDescription);
