import { createContext, useContext } from 'react';

import type { ControlSize } from '../controls/shared/types';

const ControlSizeContext = createContext<ControlSize | undefined>(undefined);

/**
 * Allows setting a default size for all descendent controls.
 *
 * @example
 *   <ControlSizeProvider value={ControlSize.Small}>
 *     <Button>Small button</Button>
 *   </ControlSizeProvider>
 */
export const ControlSizeProvider = ControlSizeContext.Provider;

/**
 * Returns the current control size from context if defined, otherwise returns the default size.
 */
export const useControlSize = (
  controlledValue?: ControlSize,
  defaultValue: ControlSize = 'medium',
) => {
  const controlSize = useContext(ControlSizeContext);
  return controlledValue ?? controlSize ?? defaultValue;
};
