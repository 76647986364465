import type { VariantProp } from '../../common/colors';
import type { SpacingScale } from '../../utilities/shared/sizes';
import type { IconName } from '../Icon/Icon';
import { styled } from '../../stitches.config';
import { AssetTile } from '../AssetTile/AssetTile';
import { Icon } from '../Icon/Icon';

const FramedIconLiteral = styled(Icon, {
  display: 'flex',
  width: '100%',
  height: '100%',
});

export interface FramedIconProps {
  /**
   * Set which icon to display.
   */
  icon: IconName;
  /**
   * Set a size of the component.
   */
  size?: SpacingScale;
  /**
   * Set a variant of the component.
   */
  variant?: VariantProp;
}

export function FramedIcon({ icon, size = 28, variant, ...remaining }: FramedIconProps) {
  return (
    <AssetTile size={size} {...remaining}>
      <FramedIconLiteral icon={icon} size={size} variant={variant} />
    </AssetTile>
  );
}
