export const fontFamilies = {
  mono: [
    '"Roboto Mono"',
    '"SF Mono"',
    'SFMono-Regular',
    'ui-monospace',
    '"DejaVu Sans Mono"',
    'Menlo',
    'Consolas',
    'monospace',
  ],
  sans: [
    'Inter',
    '-apple-system',
    'system-ui',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    'Ubuntu',
    '"Helvetica Neue"',
    'Arial',
  ],
  title: [
    'Inter',
    '-apple-system',
    'system-ui',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    'Ubuntu',
    '"Helvetica Neue"',
    'Arial',
  ],
};

export const makeFontString = (names: string[]): string => names.join(', ');
