import { fade, palette } from '../../common/colors';
import { colors, darkThemeSelector, shadows } from '../../stitches.config';
import { selectors } from '../shared/styles';

export const labelColor = '$$labelColor';
export const iconColor = '$$iconColor';
export const loadingIconColor = '$$loadingIconColor';

export const destructiveVividEnabledStyles = {
  backgroundColor: colors.controlBgVividDestructiveInitialLight,
  boxShadow: shadows.controlVividDestructiveInitialLight,
  [labelColor]: colors.red50,
  [iconColor]: colors.red100,

  [darkThemeSelector]: {
    backgroundColor: colors.controlBgVividDestructiveInitialDark,
    boxShadow: shadows.controlVividDestructiveInitialDark,
  },

  [selectors.hover]: {
    backgroundColor: colors.controlBgVividDestructiveHoveredLight,
    boxShadow: shadows.controlVividDestructiveHoveredLight,
    [labelColor]: colors.white,
    [iconColor]: colors.red50,

    [darkThemeSelector]: {
      backgroundColor: colors.controlBgVividDestructiveHoveredDark,
      boxShadow: shadows.controlVividDestructiveHoveredDark,
    },
  },

  [selectors.focus]: {
    backgroundColor: colors.controlBgVividDestructiveFocusedLight,
    boxShadow: shadows.controlVividDestructiveFocusedLight,

    [darkThemeSelector]: {
      backgroundColor: colors.controlBgVividDestructiveFocusedDark,
      boxShadow: shadows.controlVividDestructiveFocusedDark,
    },
  },
};

export const destructiveVividDisabledStyles = {
  backgroundColor: colors.controlBgVividDestructiveDisabledLight,
  boxShadow: shadows.controlVividDestructiveDisabledLight,
  [labelColor]: colors.red200,
  [iconColor]: colors.red300,
  [loadingIconColor]: colors.red50,

  [darkThemeSelector]: {
    boxShadow: shadows.controlVividDestructiveDisabledDark,
  },
};

export const destructiveMutedEnabledStyles = {
  backgroundColor: fade(palette.controlBgMutedDestructiveLight, 0.5),
  boxShadow: shadows.controlMutedDestructiveInitialLight,
  [labelColor]: colors.red800,
  [iconColor]: colors.red700,

  [darkThemeSelector]: {
    backgroundColor: fade(palette.controlBgMutedDestructiveDark, 0.5),
    boxShadow: shadows.controlMutedDestructiveInitialDark,
    [labelColor]: colors.red50,
    [iconColor]: colors.red100,
  },

  [selectors.hover]: {
    backgroundColor: fade(palette.controlBgMutedDestructiveLight, 0.9),
    boxShadow: shadows.controlMutedDestructiveHoveredLight,
    [labelColor]: colors.red900,
    [iconColor]: colors.red800,

    [darkThemeSelector]: {
      backgroundColor: fade(palette.controlBgMutedDestructiveDark, 0.7),
      boxShadow: shadows.controlMutedDestructiveHoveredDark,
      [labelColor]: colors.white,
      [iconColor]: colors.red50,
    },
  },

  [selectors.focus]: {
    backgroundColor: fade(palette.controlBgMutedDestructiveLight, 0.9),
    boxShadow: shadows.controlMutedDestructiveFocusedLight,
    [labelColor]: colors.red900,
    [iconColor]: colors.red800,

    [darkThemeSelector]: {
      backgroundColor: fade(palette.controlBgMutedDestructiveDark, 0.7),
      boxShadow: shadows.controlMutedDestructiveFocusedDark,
      [labelColor]: colors.white,
      [iconColor]: colors.red50,
    },
  },
};

export const destructiveMutedDisabledStyles = {
  backgroundColor: fade(palette.controlBgMutedDestructiveLight, 0.5),
  boxShadow: shadows.controlMutedDestructiveDisabledLight,
  [labelColor]: colors.red800,
  [iconColor]: colors.red700,
  [loadingIconColor]: colors.red700,

  [darkThemeSelector]: {
    backgroundColor: fade(palette.controlBgMutedDestructiveDark, 0.5),
    boxShadow: shadows.controlMutedDestructiveDisabledDark,
    [labelColor]: colors.red50,
    [iconColor]: colors.red100,
    [loadingIconColor]: colors.red100,
  },
};

export const primaryVividEnabledStyles = {
  backgroundColor: colors.controlBgVividPrimaryInitialLight,
  boxShadow: shadows.controlVividPrimaryInitialLight,
  [labelColor]: colors.brand50,
  [iconColor]: colors.brand100,

  [darkThemeSelector]: {
    backgroundColor: colors.controlBgVividPrimaryInitialDark,
    boxShadow: shadows.controlVividPrimaryInitialDark,
  },

  [selectors.hover]: {
    backgroundColor: colors.controlBgVividPrimaryHoveredLight,
    boxShadow: shadows.controlVividPrimaryHoveredLight,
    [labelColor]: colors.white,
    [iconColor]: colors.brand50,

    [darkThemeSelector]: {
      backgroundColor: colors.controlBgVividPrimaryHoveredDark,
      boxShadow: shadows.controlVividPrimaryHoveredDark,
    },
  },

  [selectors.focus]: {
    backgroundColor: colors.controlBgVividPrimaryFocusedLight,
    boxShadow: shadows.controlVividPrimaryFocusedLight,

    [darkThemeSelector]: {
      backgroundColor: colors.controlBgVividPrimaryFocusedDark,
      boxShadow: shadows.controlVividPrimaryFocusedDark,
    },
  },
};

export const primaryVividDisabledStyles = {
  backgroundColor: colors.controlBgVividPrimaryDisabledLight,
  boxShadow: shadows.controlVividPrimaryDisabledLight,
  [labelColor]: colors.brand200,
  [iconColor]: colors.brand300,
  [loadingIconColor]: colors.brand50,

  [darkThemeSelector]: {
    backgroundColor: colors.controlBgVividPrimaryDisabledDark,
    boxShadow: shadows.controlVividPrimaryDisabledDark,
  },
};

export const primaryMutedEnabledStyles = {
  backgroundColor: fade(palette.controlBgMutedPrimaryLight, 0.5),
  boxShadow: shadows.controlMutedPrimaryInitialLight,
  [labelColor]: colors.brand800,
  [iconColor]: colors.brand700,

  [darkThemeSelector]: {
    backgroundColor: fade(palette.controlBgMutedPrimaryDark, 0.5),
    boxShadow: shadows.controlMutedPrimaryInitialDark,
    [labelColor]: colors.brand50,
    [iconColor]: colors.brand100,
  },

  [selectors.hover]: {
    backgroundColor: fade(palette.controlBgMutedPrimaryLight, 0.9),
    boxShadow: shadows.controlMutedPrimaryHoveredLight,
    [labelColor]: colors.brand900,
    [iconColor]: colors.brand800,

    [darkThemeSelector]: {
      backgroundColor: fade(palette.controlBgMutedPrimaryDark, 0.7),
      boxShadow: shadows.controlMutedPrimaryHoveredDark,
      [labelColor]: colors.white,
      [iconColor]: colors.brand50,
    },
  },

  [selectors.focus]: {
    backgroundColor: fade(palette.controlBgMutedPrimaryLight, 0.9),
    boxShadow: shadows.controlMutedPrimaryFocusedLight,
    [labelColor]: colors.brand900,
    [iconColor]: colors.brand800,

    [darkThemeSelector]: {
      backgroundColor: fade(palette.controlBgMutedPrimaryDark, 0.7),
      boxShadow: shadows.controlMutedPrimaryFocusedDark,
      [labelColor]: colors.white,
      [iconColor]: colors.brand50,
    },
  },
};

export const primaryMutedDisabledStyles = {
  backgroundColor: fade(palette.controlBgMutedPrimaryLight, 0.5),
  boxShadow: shadows.controlMutedPrimaryDisabledLight,
  [labelColor]: colors.brand800,
  [iconColor]: colors.brand700,
  [loadingIconColor]: colors.brand700,

  [darkThemeSelector]: {
    backgroundColor: fade(palette.controlBgMutedPrimaryDark, 0.5),
    boxShadow: shadows.controlMutedPrimaryDisabledDark,
    [labelColor]: colors.brand50,
    [iconColor]: colors.brand100,
    [loadingIconColor]: colors.brand100,
  },
};

export const secondaryEnabledStyles = {
  [labelColor]: colors.gray600,
  [iconColor]: colors.gray500,
  boxShadow: shadows.controlSecondaryInitialLight,

  [darkThemeSelector]: {
    [labelColor]: colors.gray50,
    [iconColor]: colors.gray100,
    boxShadow: shadows.controlSecondaryInitialDark,
  },

  [selectors.hover]: {
    [labelColor]: colors.gray800,
    [iconColor]: colors.gray700,
    boxShadow: shadows.controlSecondaryHoveredLight,

    [darkThemeSelector]: {
      [labelColor]: colors.white,
      [iconColor]: colors.gray50,
      boxShadow: shadows.controlSecondaryHoveredDark,
    },
  },

  [selectors.focus]: {
    boxShadow: shadows.controlSecondaryFocusedLight,

    [darkThemeSelector]: {
      boxShadow: shadows.controlSecondaryFocusedDark,
    },
  },
};

export const secondaryDisabledStyles = {
  [labelColor]: colors.gray400,
  [iconColor]: colors.gray300,
  [loadingIconColor]: colors.gray500,
  boxShadow: shadows.controlSecondaryDisabledLight,

  [darkThemeSelector]: {
    [labelColor]: colors.gray200,
    [iconColor]: colors.gray300,
    [loadingIconColor]: colors.gray200,
    boxShadow: shadows.controlSecondaryDisabledDark,
  },
};

export const internalEnabledStyles = {
  [labelColor]: colors.internalBodyLight,
  [iconColor]: colors.internalIconLight,
  boxShadow: shadows.controlInternalInitialLight,

  [darkThemeSelector]: {
    [labelColor]: colors.internalBodyDark,
    [iconColor]: colors.internalIconDark,
    boxShadow: shadows.controlInternalInitialDark,
  },

  [selectors.hover]: {
    [labelColor]: colors.internalHeadingLight,
    [iconColor]: colors.internalBodyLight,
    boxShadow: shadows.controlInternalHoveredLight,

    [darkThemeSelector]: {
      [labelColor]: colors.internalHeadingDark,
      [iconColor]: colors.internalBodyDark,
      boxShadow: shadows.controlInternalHoveredDark,
    },
  },

  [selectors.focus]: {
    boxShadow: shadows.controlInternalFocusedLight,

    [darkThemeSelector]: {
      boxShadow: shadows.controlInternalFocusedDark,
    },
  },
};

export const internalDisabledStyles = {
  [labelColor]: colors.internalBodyLight,
  [iconColor]: colors.internalIconLight,
  [loadingIconColor]: colors.internalIconLight,
  boxShadow: shadows.controlInternalDisabledLight,

  [darkThemeSelector]: {
    [labelColor]: colors.internalBodyDark,
    [iconColor]: colors.internalIconDark,
    [loadingIconColor]: colors.internalIconDark,
    boxShadow: shadows.controlInternalDisabledDark,
  },
};
