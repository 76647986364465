import { ManufacturerIconSVG } from 'atto-svgs';

import type { SpacingScale } from '../../utilities/shared/sizes';
import { styled } from '../../stitches.config';
import { AssetTile } from '../AssetTile/AssetTile';

export const ManufacturerIcons = {
  amazon: ManufacturerIconSVG.Amazon,
  amcrest: ManufacturerIconSVG.Amcrest,
  apple: ManufacturerIconSVG.Apple,
  asix: ManufacturerIconSVG.ASIX,
  asus: ManufacturerIconSVG.Asus,
  cisco: ManufacturerIconSVG.Cisco,
  dell: ManufacturerIconSVG.Dell,
  ecobee: ManufacturerIconSVG.Ecobee,
  foxconn: ManufacturerIconSVG.Foxconn,
  google: ManufacturerIconSVG.Google,
  hp: ManufacturerIconSVG.HP,
  ibm: ManufacturerIconSVG.IBM,
  intel: ManufacturerIconSVG.Intel,
  lenovo: ManufacturerIconSVG.Lenovo,
  lg: ManufacturerIconSVG.LG,
  logitech: ManufacturerIconSVG.Logitech,
  microsoft: ManufacturerIconSVG.Microsoft,
  neat: ManufacturerIconSVG.Neat,
  nest: ManufacturerIconSVG.Nest,
  philips: ManufacturerIconSVG.Philips,
  samsung: ManufacturerIconSVG.Samsung,
  shure: ManufacturerIconSVG.Shure,
  sonos: ManufacturerIconSVG.Sonos,
  ubiquiti: ManufacturerIconSVG.Ubiquiti,
  unknown: ManufacturerIconSVG.Unknown,
  verkada: ManufacturerIconSVG.Verkada,
  yealink: ManufacturerIconSVG.Yealink,
};

export const ManufacturerIconValues = Object.keys(ManufacturerIcons);
export type ManufacturerIconName = keyof typeof ManufacturerIcons;
export const ManufacturerIconNames = Object.keys(ManufacturerIcons) as ManufacturerIconName[];

const ManufacturerIconLiteral = styled('svg', {
  display: 'flex',
  width: '100%',
  height: '100%',
});

export interface ManufacturerIconProps {
  /**
   * Set which manufacturer to display.
   */
  icon: ManufacturerIconName;
  /**
   * Set a size of the component.
   */
  size?: SpacingScale;
}

export function ManufacturerIcon({ icon, size = 28, ...remaining }: ManufacturerIconProps) {
  const NamedIcon = ManufacturerIcons[icon];

  if (!NamedIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${icon}`);
    return null;
  }

  return (
    <AssetTile size={size} {...remaining}>
      <ManufacturerIconLiteral as={NamedIcon} {...remaining} />
    </AssetTile>
  );
}
