import { useCallback, useState } from 'react';

import { fade, palette } from '../common/colors';
import { darkThemeSelector, styled } from '../stitches.config';

export function useVerticalShadowOnScroll(visible: 'top' | 'bottom' | 'both') {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const handleTargetChange = useCallback((target: HTMLElement) => {
    setScrollTop(target.scrollTop);
    setScrollHeight(target.scrollHeight);
    setClientHeight(target.clientHeight);
  }, []);

  const onScrollHandler = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      handleTargetChange(event.currentTarget);
    },
    [handleTargetChange],
  );

  const getBoxShadow = useCallback(() => {
    const isBottom = Math.ceil(clientHeight + scrollTop) >= scrollHeight;
    const isTop = scrollTop === 0;
    const isBetween = scrollTop > 0 && Math.ceil(clientHeight + scrollTop) < scrollHeight;
    return {
      top: !isTop && (visible === 'top' || visible === 'both'),
      bottom: !isBottom && (visible === 'bottom' || visible === 'both'),
      both: isBetween && visible === 'both',
    };
  }, [visible, scrollTop, scrollHeight, clientHeight]);

  return { getBoxShadow: getBoxShadow(), onScrollHandler, handleTargetChange };
}

const topShadowLight = `inset 0 11px 0 -10px ${fade(palette.gray800, 0.04)}, inset 0 12px 3px -10px ${fade(palette.gray800, 0.04)}`;
const topShadowDark = `inset 0 11px 0 -10px ${fade(palette.gray700, 0.8)}, inset 0 11px 1px -10px ${fade(palette.gray900, 0.8)}, inset 0 12px 3px -10px ${fade(palette.gray900, 0.8)}`;

const bottomShadowLight = `inset 0 -11px 0 -10px ${fade(palette.gray800, 0.04)}, inset 0 -12px 3px -10px ${fade(palette.gray800, 0.04)}`;
const bottomShadowDark = `inset 0 -11px 0 -10px ${fade(palette.gray700, 0.8)}, inset 0 -11px 1px -10px ${fade(palette.gray900, 0.8)}, inset 0 -12px 3px -10px ${fade(palette.gray900, 0.8)}`;

export const VerticalScrollShadow = styled('div', {
  maxHeight: '100%',
  overflow: 'auto',

  '&::before, &::after': {
    content: '',
    position: 'absolute',
    zIndex: 100,
    right: 0,
    left: 0,
    display: 'block',
    height: '$12',
    pointerEvents: 'none',
  },

  '&::before': {
    top: 0,
  },

  '&::after': {
    bottom: 0,
  },

  variants: {
    top: {
      true: {},
      false: {},
    },
    bottom: {
      true: {},
      false: {},
    },
    both: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      top: true,
      css: {
        '&::before': {
          boxShadow: topShadowLight,

          [darkThemeSelector]: {
            boxShadow: topShadowDark,
          },
        },
      },
    },
    {
      bottom: true,
      css: {
        '&::after': {
          boxShadow: bottomShadowLight,

          [darkThemeSelector]: {
            boxShadow: bottomShadowDark,
          },
        },
      },
    },
    {
      both: true,
      css: {
        '&::before': {
          boxShadow: topShadowLight,

          [darkThemeSelector]: {
            boxShadow: topShadowDark,
          },
        },

        '&::after': {
          boxShadow: bottomShadowLight,

          [darkThemeSelector]: {
            boxShadow: bottomShadowDark,
          },
        },
      },
    },
  ],
});

export function useHorizontalShadowOnScroll(visible: 'start' | 'end' | 'both') {
  const [scrollStart, setScrollStart] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);

  const handleTargetChange = useCallback((target: HTMLElement) => {
    setScrollStart(target.scrollLeft);
    setScrollWidth(target.scrollWidth);
    setClientWidth(target.clientWidth);
  }, []);

  const onScrollHandler = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      handleTargetChange(event.currentTarget);
    },
    [handleTargetChange],
  );

  const getBoxShadow = useCallback(() => {
    const isEnd = Math.ceil(clientWidth + scrollStart) >= scrollWidth;
    const isStart = scrollStart === 0;
    const isBetween = scrollStart > 0 && Math.ceil(clientWidth + scrollStart) < scrollWidth;
    return {
      start: !isStart && (visible === 'start' || visible === 'both'),
      end: !isEnd && (visible === 'end' || visible === 'both'),
      both: isBetween && visible === 'both',
    };
  }, [visible, scrollStart, scrollWidth, clientWidth]);

  return { getBoxShadow: getBoxShadow(), onScrollHandler, handleTargetChange };
}

const startShadowLight = `inset 11px 0 0 -10px ${fade(palette.gray800, 0.04)}, inset 12px 0 3px -10px ${fade(palette.gray800, 0.04)}`;
const startShadowDark = `inset 11px 0 0 -10px ${fade(palette.gray700, 0.8)}, inset 11px 0 1px -10px ${fade(palette.gray900, 0.8)}, inset 12px 0 3px -10px ${fade(palette.gray900, 0.8)}`;

const endShadowLight = `inset -11px 0 0 -10px ${fade(palette.gray800, 0.04)}, inset -12px 0 3px -10px ${fade(palette.gray800, 0.04)}`;
const endShadowDark = `inset -11px 0 0 -10px ${fade(palette.gray700, 0.8)}, inset -11px 0 1px -10px ${fade(palette.gray900, 0.8)}, inset -12px 0 3px -10px ${fade(palette.gray900, 0.8)}`;

export const HorizontalScrollShadow = styled('div', {
  maxWidth: '100%',
  overflow: 'auto',

  '&::before, &::after': {
    content: '',
    position: 'absolute',
    zIndex: 100,
    top: 0,
    bottom: 0,
    display: 'block',
    width: '$12',
    pointerEvents: 'none',
  },

  '&::before': {
    left: 0,
  },

  '&::after': {
    right: 0,
  },

  variants: {
    start: {
      true: {},
      false: {},
    },
    end: {
      true: {},
      false: {},
    },
    both: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      start: true,
      css: {
        '&::before': {
          boxShadow: startShadowLight,

          [darkThemeSelector]: {
            boxShadow: startShadowDark,
          },
        },
      },
    },
    {
      end: true,
      css: {
        '&::after': {
          boxShadow: endShadowLight,

          [darkThemeSelector]: {
            boxShadow: endShadowDark,
          },
        },
      },
    },
    {
      both: true,
      css: {
        '&::before': {
          boxShadow: startShadowLight,

          [darkThemeSelector]: {
            boxShadow: startShadowDark,
          },
        },

        '&::after': {
          boxShadow: endShadowLight,

          [darkThemeSelector]: {
            boxShadow: endShadowDark,
          },
        },
      },
    },
  ],
});
