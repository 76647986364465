import { selectors, transitions } from '../controls/shared/styles';
import { css, darkThemeSelector, shadows } from '../stitches.config';

export const FocusRing = css({
  position: 'relative',
  zIndex: 0,
  transition: transitions.control,
  [selectors.hover]: {
    zIndex: 1,
  },
  [selectors.focus]: {
    zIndex: 2,
    outline: 'none',
    boxShadow: shadows.focusRingLight,
    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },
});
