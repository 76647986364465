/* eslint-disable no-bitwise */

// Adapted from https://gist.github.com/jed/983661
export function isLightOrDark(color: string) {
  // Check the format of the color, HEX or RGB?
  let r: number;
  let g: number;
  let b: number;
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    const colorMatch = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    if (!colorMatch) {
      return 'unrecognized';
    }
    r = Number.parseInt(colorMatch[1], 10);
    g = Number.parseInt(colorMatch[2], 10);
    b = Number.parseInt(colorMatch[3], 10);
  } else {
    let hexColorString = color.startsWith('#') ? color.slice(1) : color;
    if (hexColorString.length === 3) {
      hexColorString = hexColorString
        .split('')
        .map((char) => char + char)
        .join('');
    }
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    const hexColor = +`0x${hexColorString}`;

    r = hexColor >> 16;
    g = (hexColor >> 8) & 255;
    b = hexColor & 255;
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  }

  return 'dark';
}

export type LightOrDark = ReturnType<typeof isLightOrDark>;
