import type { CSS } from '../../stitches.config';
import type { SpacingScale } from './sizes';
import { type GutterProp, sizing } from '../../common/sizing';

export type GutterSpacing = SpacingScale;

export type GutterProps = {
  context?: 'application' | 'content';
  gutter?: GutterProp;
};

export const gutterCSS = ({ context = 'application', gutter = 'all' }: GutterProps = {}): CSS => {
  if (context === 'content') {
    switch (gutter) {
      case 'all':
        return { padding: sizing.contentSquish };
      case 'vertical':
        return { padding: sizing.contentEndsOnly };
      case 'horizontal':
        return { padding: sizing.contentSidesOnly };
      case 'top':
        return { paddingTop: sizing.contentEnds };
      case 'bottom':
        return { paddingBottom: sizing.contentEnds };
      case 'left':
        return { paddingLeft: sizing.contentSides };
      case 'right':
        return { paddingRight: sizing.contentSides };
      case 'none':
      default:
        return { padding: 0 };
    }
  }

  switch (gutter) {
    case 'all':
      return { padding: sizing.squish };
    case 'vertical':
      return { padding: sizing.endsOnly };
    case 'horizontal':
      return { padding: sizing.sidesOnly };
    case 'top':
      return { paddingTop: sizing.ends };
    case 'bottom':
      return { paddingBottom: sizing.ends };
    case 'left':
      return { paddingLeft: sizing.sides };
    case 'right':
      return { paddingRight: sizing.sides };
    case 'none':
    default:
      return { padding: 0 };
  }
};
