import { merge } from 'lodash-es';

import type { CSS } from '../../stitches.config';
import type { SpacingScale } from './sizes';

export type PaddingSpacing = SpacingScale;

export type PaddingProps = {
  all?: PaddingSpacing;
  x?: PaddingSpacing;
  y?: PaddingSpacing;
  top?: PaddingSpacing;
  right?: PaddingSpacing;
  bottom?: PaddingSpacing;
  left?: PaddingSpacing;
};

export const paddingCSS = ({ all, x, y, top, right, bottom, left }: PaddingProps = {}): CSS =>
  merge(
    {},
    all != null ? { padding: `$${all}` } : {},
    x != null ? { paddingX: `$${x}` } : {},
    y != null ? { paddingY: `$${y}` } : {},
    top != null ? { paddingTop: `$${top}` } : {},
    right != null ? { paddingRight: `$${right}` } : {},
    bottom != null ? { paddingBottom: `$${bottom}` } : {},
    left != null ? { paddingLeft: `$${left}` } : {},
  );
