import React from 'react';

export interface FormattedMoneyProps {
  amount: number;
  hideCentsIfZero?: boolean;
}

export const formatMoney = ({ amount, hideCentsIfZero = true }: FormattedMoneyProps) => {
  const hasCents = amount % 1 !== 0;
  const fractionDigits = !hasCents && hideCentsIfZero ? 0 : 2;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  return formatter.format(amount);
};

export function FormattedMoney({ amount, hideCentsIfZero = true }: FormattedMoneyProps) {
  return <>{formatMoney({ amount, hideCentsIfZero })}</>;
}
