import type { Key } from '@react-types/shared';
import type {
  MultipleSelection,
  Selection,
  SingleSelection,
} from '@react-types/shared/src/selection';

type SingleValueProps = {
  value?: string | null;
  defaultValue?: string;
  onValueChange?: (key: string) => any;
  disabled?: boolean;
};

type ToSingleSelectionProps<T> = Omit<T, 'value' | 'defaultValue' | 'onValueChange' | 'disabled'> &
  SingleSelection & { isDisabled?: boolean };

export type FromSingleSelectionProps<T> = Omit<
  T,
  'selectedKey' | 'defaultSelectedKey' | 'onSelectionChange' | 'isDisabled'
> &
  SingleValueProps;

export const toSingleSelectionProps = <T extends SingleValueProps>({
  value,
  defaultValue,
  onValueChange,
  disabled,
  ...rest
}: T): ToSingleSelectionProps<T> => ({
  ...rest,
  isDisabled: disabled,
  selectedKey: value,
  defaultSelectedKey: defaultValue,
  onSelectionChange: onValueChange ? (key) => onValueChange(String(key)) : undefined,
});

type MultipleValueProps = {
  value?: Iterable<Key>;
  defaultValue?: 'all' | Iterable<Key>;
  onValueChange?: (keys: Selection) => void;
  disabled?: boolean;
};

type ToMultipleSelectionProps<T> = Omit<
  T,
  'value' | 'defaultValue' | 'onValueChange' | 'disabled'
> &
  MultipleSelection & { isDisabled?: boolean };

export type FromMultipleSelectionProps<T> = Omit<
  T,
  'selectedKeys' | 'defaultSelectedKeys' | 'onSelectionChange' | 'isDisabled'
> &
  MultipleValueProps;

export const toMultipleSelectionProps = <T extends MultipleValueProps>({
  value,
  defaultValue,
  onValueChange,
  disabled,
  ...rest
}: T): ToMultipleSelectionProps<T> => ({
  ...rest,
  isDisabled: disabled,
  selectedKeys: value,
  defaultSelectedKeys: defaultValue,
  onSelectionChange: onValueChange,
});
