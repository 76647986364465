import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { FocusRing } from '../../common/focus_rings';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { useDeprecatedSidebarFormFactor } from './DeprecatedSidebarFormFactorContext';

const Container = styled('a', FocusRing, {
  hStack: '$8',
  width: '100%',
  borderRadius: '$8',
  $$labelColor: colors.gray600,
  $$iconColor: colors.gray500,
  [darkThemeSelector]: {
    $$labelColor: colors.gray50,
    $$iconColor: colors.gray100,
  },
  '&:hover': {
    backgroundColor: colors.gray50,
    $$labelColor: colors.gray700,
    $$iconColor: colors.gray600,
    [darkThemeSelector]: {
      backgroundColor: colors.gray700,
      $$labelColor: colors.white,
      $$iconColor: colors.gray50,
    },
  },
  variants: {
    isSelected: {
      true: {
        '&, &:hover': {
          $$labelColor: colors.brand800,
          $$iconColor: colors.brand700,
          backgroundColor: colors.brand50,
        },
      },
      false: {},
    },
    size: {
      medium: {
        padding: '$4 $8',
      },
      large: {
        padding: '$8 $12',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

const StyledIcon = styled(Icon, {
  color: '$$iconColor',
});

const Label = styled(Body, {
  fontWeight: fontWeights.bold,
  color: '$$labelColor',
  [darkThemeSelector]: {
    color: '$$labelColor',
  },
});

export interface DeprecatedSidebarNavItemProps {
  icon?: IconName;
  label?: React.ReactNode;
  isSelected?: boolean;
  size?: 'medium' | 'large';
}

export const DeprecatedSidebarNavItem = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      as = 'a' as Tag,
      size,
      label,
      icon,
      isSelected,
      ...props
    }: PolymorphicComponentProps<Tag, DeprecatedSidebarNavItemProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => {
    const formFactor = useDeprecatedSidebarFormFactor();
    const defaultedSize = size ?? (formFactor === 'mobile' ? 'large' : 'medium');
    return (
      <Container as={as} ref={forwardedRef} {...props} isSelected={isSelected} size={defaultedSize}>
        {icon && <StyledIcon icon={icon} />}
        <Label>{label}</Label>
      </Container>
    );
  },
) as Polymorphic.ForwardRefComponent<React.ElementType, DeprecatedSidebarNavItemProps>;
