import { createContext, useContext } from 'react';

import type { VariantProp } from './colors';

const VariantContext = createContext<VariantProp | undefined>(undefined);

/**
 * Allows setting a default variant for all descendent components.
 *
 * @example
 *   <VariantProvider value={VariantProp.Negative}>
 *     <Badge>Negative badge</Badge>
 *   </VariantProvider>
 */
export const VariantProvider = VariantContext.Provider;

/**
 * Returns the current variant from context if defined, otherwise returns the default variant.
 */
export const useVariant = (
  controlledValue?: VariantProp,
  defaultValue: VariantProp = 'neutral',
) => {
  const variant = useContext(VariantContext);
  return controlledValue ?? variant ?? defaultValue;
};
