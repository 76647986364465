import { colors, darkThemeSelector, fonts, fontWeights, shadows } from '../stitches.config';

export const contentStyles = {
  vStack: '$2',
  overflow: 'hidden',
  alignItems: 'stretch',
  backgroundColor: colors.bgApplicationLight,
  borderRadius: '$8',
  boxShadow: shadows.overlayLight,
  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    boxShadow: shadows.overlayDark,
  },
};

export const itemStyles = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  padding: '$4 $8 $4 $32',
  fontFamily: fonts.sans,
  fontWeight: fontWeights.bold,
  fontSize: '$14',
  lineHeight: '$20',
  borderRadius: '$8',
  color: colors.gray700,
  outline: 'none',
  userSelect: 'none',

  '&[data-disabled]': {
    color: colors.gray500,
    pointerEvents: 'none',
  },

  '&:focus, &[data-focused]': {
    backgroundColor: colors.brand50,
    color: colors.brand800,
  },

  [darkThemeSelector]: {
    color: colors.gray50,
    '&[data-disabled]': {
      color: colors.gray300,
    },
    '&:focus, &[data-focused]': {
      backgroundColor: colors.brand900,
      color: colors.brand50,
    },
  },

  variants: {
    internal: {
      true: {
        color: colors.internalBodyLight,

        [darkThemeSelector]: {
          color: colors.internalBodyDark,
        },
      },
    },
  },
};

export const labelStyles = {
  paddingLeft: '$32',
  color: colors.gray600,
  [darkThemeSelector]: {
    color: colors.gray200,
  },
};

export const separatorStyles = {
  height: 1,
  backgroundColor: colors.strokeNeutralLight,
  margin: '$4 -4px',
  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },
};

export const itemIndicatorStyles = {
  position: 'absolute',
  left: 8,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '$24',
  backgroundColor: colors.bgApplicationLight,
  color: colors.gray500,
  cursor: 'default',
  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    color: colors.gray200,
  },
};
