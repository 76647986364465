import type { ComponentProps } from 'react';
import React from 'react';

import { Icon } from '../../assets/Icon/Icon';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { styled } from '../../stitches.config';
import { Body } from '../../text/Body';

const SpeedLockupIcon = styled(Icon, {
  width: '$12',
  height: '$12',
  color: 'currentColor',
});

const SpeedLockupValue = styled(Body, {
  color: 'currentColor',
});

const SpeedLockupContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$6',
  width: '$92',
});

export type SpeedLockupProps = {
  direction: 'download' | 'upload';
  tooltip?: React.ReactNode;
  value: React.ReactNode;
};

export function SpeedLockup({ direction, tooltip, value }: SpeedLockupProps) {
  return (
    <Tooltip contents={tooltip}>
      <SpeedLockupContainer>
        <SpeedLockupIcon icon={direction === 'download' ? 'download' : 'upload'} />
        <SpeedLockupValue family="monospace">{value}</SpeedLockupValue>
      </SpeedLockupContainer>
    </Tooltip>
  );
}

const DualSpeedLockupContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$8',
});

export type DualSpeedLockipProps = {
  download?: React.ReactNode;
  downloadTooltip?: React.ReactNode;
  upload?: React.ReactNode;
  uploadTooltip?: React.ReactNode;
} & ComponentProps<typeof DualSpeedLockupContainer>;

export function DualSpeedLockup({
  download,
  downloadTooltip,
  upload,
  uploadTooltip,
  ...remaining
}: DualSpeedLockipProps) {
  return (
    <DualSpeedLockupContainer {...remaining}>
      {download && <SpeedLockup direction="download" tooltip={downloadTooltip} value={download} />}
      {upload && <SpeedLockup direction="upload" tooltip={uploadTooltip} value={upload} />}
    </DualSpeedLockupContainer>
  );
}
