import type { Node } from '@react-types/shared';
import type { ListState } from 'react-stately';
import cx from 'classnames';
import React, { useRef } from 'react';
import { useOption } from 'react-aria';

import type { ControlSize } from '../../controls/shared/types';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Body } from '../../text/Body';

const ListBoxOptionLabel = styled(Body, {
  variants: {
    disabled: {
      true: {
        color: colors.gray500,
        [darkThemeSelector]: {
          color: colors.gray300,
        },
      },
    },
    focused: {
      true: {
        color: colors.headingNeutralLight,
        [darkThemeSelector]: {
          color: colors.headingNeutralDark,
        },
      },
    },
    selected: {
      true: {
        color: colors.white,
      },
    },
  },
});

export function ListBoxOption<T>({
  item,
  state,
  size = 'medium',
}: {
  item: Node<T>;
  state: ListState<T>;
  size?: ControlSize;
}) {
  const ref = useRef<HTMLLIElement>(null);
  const { optionProps, isSelected, isFocused, isDisabled } = useOption(
    { key: item.key },
    state,
    ref,
  );

  const classes = cx(
    'm-outline-none m-flex',
    {
      'm-cursor-pointer': !isDisabled,
      'm-cursor-not-allowed': isDisabled,
    },
    {
      'm-bg-gray-50 dark:m-bg-gray-600': !isSelected && isFocused,
      'm-bg-brand-600': isSelected,
    },
    {
      'm-rounded-4 m-px-1.5 m-py-0.5': size === 'small',
      'm-rounded-6 m-px-2 m-py-1': size === 'medium',
      'm-rounded-6 m-px-3 m-py-2': size === 'large' || size === 'x-large',
    },
  );

  return (
    <li {...optionProps} ref={ref} className={classes}>
      <ListBoxOptionLabel disabled={isDisabled} focused={isFocused} selected={isSelected}>
        {item.rendered}
      </ListBoxOptionLabel>
    </li>
  );
}
