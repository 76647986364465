import type { SpacingValue } from '../../common/sizing';
import { spacings } from '../../stitches.config';

export type SizerProp = SpacingValue;
export const sizer = (value: SizerProp) => spacings[value];

export type SpacingScale = SpacingValue;
export type SizingScale =
  | 'auto'
  | 'fit-content'
  | 'full'
  | 'max-content'
  | 'min-content'
  | 'screen'
  | SpacingScale;

/**
 * @deprecated This does nothing, just here for migration reasons.
 */
export function space(size: SpacingValue): SpacingValue {
  return size;
}
