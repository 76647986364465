import { fade, palette } from './colors';
import { createShadowAnimationStack } from './shadow_stacks';

const focusRingLight = `0 0 0 1px ${fade(palette.brand600, 0.7)}, 0 0 0 3px ${fade(
  palette.brand500,
  0.7,
)}`;
const focusRingDark = `0 0 0 1px ${fade(palette.brand100, 0.7)}, 0 0 0 4px ${fade(
  palette.brand200,
  0.7,
)}`;

const control = {
  initialLight: [
    `0 1px 1px 0 ${fade(palette.gray900, 0.08)}`,
    `0 1px 3px 0 ${fade(palette.gray900, 0.12)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
  ],
  hoveredLight: [
    `0 1px 1px 0 ${fade(palette.gray900, 0.12)}`,
    `0 1px 3px 0 ${fade(palette.gray900, 0.16)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
  ],
  focusedLight: [focusRingLight],
  disabledLight: [
    `0 1px 1px 0 ${fade(palette.gray900, 0.04)}`,
    `0 1px 3px 0 ${fade(palette.gray900, 0.08)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.06)}`,
  ],
  initialDark: [
    `0 1px 1px 0 ${fade(palette.gray900, 0.8)}`,
    `0 1px 3px 0 ${fade(palette.gray900, 0.7)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
  ],
  hoveredDark: [
    `0 1px 1px 0 ${fade(palette.gray900, 0.86)}`,
    `0 1px 3px 0 ${fade(palette.gray900, 0.76)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
  ],
  focusedDark: [
    `0 1px 1px 0 ${fade(palette.gray900, 0.86)}`,
    `0 1px 3px 0 ${fade(palette.gray900, 0.76)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
    focusRingDark,
  ],
  disabledDark: [
    `0 1px 1px 0 ${fade(palette.gray900, 0.6)}`,
    `0 1px 3px 0 ${fade(palette.gray900, 0.5)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.06)}`,
  ],
};

export const shadowParts = {
  none: [],

  focusRingLight: [focusRingLight],
  focusRingDark: [focusRingDark],

  // Destructive vivid control, light
  controlVividDestructiveInitialLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeVividDestructiveLight, 1)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeVividDestructiveLight, 1)}`,
  ],
  controlVividDestructiveHoveredLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeVividDestructiveLight, 1)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeVividDestructiveLight, 1)}`,
  ],
  controlVividDestructiveFocusedLight: [
    `0 0 0 0.5px ${palette.controlStrokeVividDestructiveFocusedLight}`,
    `inset 0 0 0 0.5px ${palette.controlStrokeVividDestructiveFocusedLight}`,
    `${control.focusedLight}`,
  ],
  controlVividDestructiveDisabledLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeVividDestructiveLight, 0.85)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeVividDestructiveLight, 0.85)}`,
  ],

  // Destructive muted control, light
  controlMutedDestructiveInitialLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeMutedDestructiveLight, 0.7)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeMutedDestructiveLight, 0.7)}`,
  ],
  controlMutedDestructiveHoveredLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeMutedDestructiveLight, 1)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeMutedDestructiveLight, 1)}`,
  ],
  controlMutedDestructiveFocusedLight: [
    `0 0 0 0.5px ${palette.controlStrokeMutedDestructiveLight}`,
    `inset 0 0 0 0.5px ${palette.controlStrokeMutedDestructiveLight}`,
    `${control.focusedLight}`,
  ],
  controlMutedDestructiveDisabledLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeMutedDestructiveLight, 0.7)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeMutedDestructiveLight, 0.7)}`,
  ],

  // Destructive control, dark
  controlVividDestructiveInitialDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeVividDestructiveDark, 0.85)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeVividDestructiveDark, 0.85)}`,
  ],
  controlVividDestructiveHoveredDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeVividDestructiveDark, 0.9)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeVividDestructiveDark, 0.9)}`,
  ],
  controlVividDestructiveFocusedDark: [
    `0 0 0 0.5px ${palette.controlStrokeVividDestructiveFocusedDark}`,
    `inset 0 0 0 0.5px ${palette.controlStrokeVividDestructiveFocusedDark}`,
    `${control.focusedDark}`,
  ],
  controlVividDestructiveDisabledDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeVividDestructiveDark, 0.5)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeVividDestructiveDark, 0.5)}`,
  ],

  // Destructive muted control, dark
  controlMutedDestructiveInitialDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeMutedDestructiveDark, 0.7)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeMutedDestructiveDark, 0.7)}`,
  ],
  controlMutedDestructiveHoveredDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeMutedDestructiveDark, 1)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeMutedDestructiveDark, 1)}`,
  ],
  controlMutedDestructiveFocusedDark: [
    `0 0 0 0.5px ${palette.controlStrokeMutedDestructiveDark}`,
    `inset 0 0 0 0.5px ${palette.controlStrokeMutedDestructiveDark}`,
    `${control.focusedDark}`,
  ],
  controlMutedDestructiveDisabledDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeMutedDestructiveDark, 0.7)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeMutedDestructiveDark, 0.7)}`,
  ],

  // Primary control, light
  controlVividPrimaryInitialLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeVividPrimaryLight, 0.85)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeVividPrimaryLight, 0.85)}`,
  ],
  controlVividPrimaryHoveredLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeVividPrimaryLight, 0.95)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeVividPrimaryLight, 0.95)}`,
  ],
  controlVividPrimaryFocusedLight: [
    `0 0 0 0.5px ${palette.controlStrokeVividPrimaryFocusedLight}`,
    `inset 0 0 0 0.5px ${palette.controlStrokeVividPrimaryFocusedLight}`,
    `${control.focusedLight}`,
  ],
  controlVividPrimaryDisabledLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeVividPrimaryLight, 0.85)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeVividPrimaryLight, 0.85)}`,
  ],

  // Primary muted control, light
  controlMutedPrimaryInitialLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeMutedPrimaryLight, 0.7)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeMutedPrimaryLight, 0.7)}`,
  ],
  controlMutedPrimaryHoveredLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeMutedPrimaryLight, 1)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeMutedPrimaryLight, 1)}`,
  ],
  controlMutedPrimaryFocusedLight: [
    `0 0 0 0.5px ${palette.controlStrokeMutedPrimaryLight}`,
    `inset 0 0 0 0.5px ${palette.controlStrokeMutedPrimaryLight}`,
    `${control.focusedLight}`,
  ],
  controlMutedPrimaryDisabledLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeMutedPrimaryLight, 0.7)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeMutedPrimaryLight, 0.7)}`,
  ],

  // Primary control, dark
  controlVividPrimaryInitialDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeVividPrimaryDark, 0.7)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeVividPrimaryDark, 0.7)}`,
  ],
  controlVividPrimaryHoveredDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeVividPrimaryDark, 0.9)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeVividPrimaryDark, 0.9)}`,
  ],
  controlVividPrimaryFocusedDark: [
    `0 0 0 0.5px ${palette.controlStrokeVividPrimaryFocusedDark}`,
    `inset 0 0 0 0.5px ${palette.controlStrokeVividPrimaryFocusedDark}`,
    `${control.focusedDark}`,
  ],
  controlVividPrimaryDisabledDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeVividPrimaryDark, 0.5)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeVividPrimaryDark, 0.5)}`,
  ],

  // Destructive muted control, dark
  controlMutedPrimaryInitialDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeMutedPrimaryDark, 0.7)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeMutedPrimaryDark, 0.7)}`,
  ],
  controlMutedPrimaryHoveredDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeMutedPrimaryDark, 1)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeMutedPrimaryDark, 1)}`,
  ],
  controlMutedPrimaryFocusedDark: [
    `0 0 0 0.5px ${palette.controlStrokeMutedPrimaryDark}`,
    `inset 0 0 0 0.5px ${palette.controlStrokeMutedPrimaryDark}`,
    `${control.focusedDark}`,
  ],
  controlMutedPrimaryDisabledDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeMutedPrimaryDark, 0.7)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeMutedPrimaryDark, 0.7)}`,
  ],

  // Secondary control, light
  controlSecondaryInitialLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeBaseLight, 0.12)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeBaseLight, 0.12)}`,
  ],
  controlSecondaryHoveredLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeBaseLight, 0.24)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeBaseLight, 0.24)}`,
  ],
  controlSecondaryFocusedLight: [
    `0 0 0 0.5px ${palette.controlStrokeBaseFocusedLight}`,
    `inset 0 0 0 0.5px ${palette.controlStrokeBaseFocusedLight}`,
    `${control.focusedLight}`,
  ],
  controlSecondaryDisabledLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeBaseLight, 0.3)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeBaseLight, 0.3)}`,
  ],

  // Secondary control, dark
  controlSecondaryInitialDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeBaseDark, 0.44)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeBaseDark, 0.44)}`,
  ],
  controlSecondaryHoveredDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeBaseDark, 0.66)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeBaseDark, 0.66)}`,
  ],
  controlSecondaryFocusedDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeBaseFocusedDark, 0.6)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeBaseFocusedDark, 0.6)}`,
    `${control.focusedDark}`,
  ],
  controlSecondaryDisabledDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeBaseDark, 0.36)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeBaseDark, 0.36)}`,
  ],

  // Internal control, light
  controlInternalInitialLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeInternalLight, 0.12)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeInternalLight, 0.12)}`,
  ],
  controlInternalHoveredLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeInternalLight, 0.24)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeInternalLight, 0.24)}`,
  ],
  controlInternalFocusedLight: [
    `0 0 0 0.5px ${palette.controlStrokeInternalFocusedLight}`,
    `inset 0 0 0 0.5px ${palette.controlStrokeInternalFocusedLight}`,
    `${control.focusedLight}`,
  ],
  controlInternalDisabledLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeInternalLight, 0.3)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeInternalLight, 0.3)}`,
  ],

  // Internal control, dark
  controlInternalInitialDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeInternalDark, 0.44)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeInternalDark, 0.44)}`,
  ],
  controlInternalHoveredDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeInternalDark, 0.66)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeInternalDark, 0.66)}`,
  ],
  controlInternalFocusedDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeInternalFocusedDark, 0.6)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeInternalFocusedDark, 0.6)}`,
    `${control.focusedDark}`,
  ],
  controlInternalDisabledDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeInternalDark, 0.36)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeInternalDark, 0.36)}`,
  ],

  // Raised control, light
  controlRaisedInitialLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeRaisedLight, 0.24)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeRaisedLight, 0.24)}`,
    `${control.initialLight}`,
  ],
  controlRaisedHoveredLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeRaisedLight, 0.3)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeRaisedLight, 0.3)}`,
    `${control.hoveredLight}`,
  ],
  controlRaisedFocusedLight: [
    `0 0 0 0.5px ${palette.controlStrokeRaisedFocusedLight}`,
    `inset 0 0 0 0.5px ${palette.controlStrokeRaisedFocusedLight}`,
    `${control.focusedLight}`,
  ],
  controlRaisedDisabledLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeRaisedLight, 0.18)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeRaisedLight, 0.18)}`,
    `${control.disabledLight}`,
  ],

  // Raised control, dark
  controlRaisedInitialDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeRaisedDark, 0.85)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeRaisedDark, 0.85)}`,
    `${control.initialDark}`,
  ],
  controlRaisedHoveredDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeRaisedDark, 0.95)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeRaisedDark, 0.95)}`,
    `${control.hoveredDark}`,
  ],
  controlRaisedFocusedDark: [
    `0 0 0 0.5px ${palette.controlStrokeRaisedFocusedDark}`,
    `inset 0 0 0 0.5px ${palette.controlStrokeRaisedFocusedDark}`,
    `${control.focusedDark}`,
  ],
  controlRaisedDisabledDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeRaisedDark, 0.85)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeRaisedDark, 0.85)}`,
    `${control.disabledDark}`,
  ],

  // Fields
  fieldInitialLight: [
    `0 2px 1px 0 ${fade(palette.gray900, 0.08)}`,
    `0 2px 3px 0 ${fade(palette.gray900, 0.05)}`,
    `0 0 0 1px ${fade(palette.gray600, 0.3)}`,
  ],
  fieldHoveredLight: [
    `0 2px 1px 0 ${fade(palette.gray900, 0.12)}`,
    `0 2px 3px 0 ${fade(palette.gray900, 0.18)}`,
    `0 0 0 1px ${fade(palette.gray600, 0.4)}`,
  ],
  fieldFocusedLight: [
    `0 0 0 0.5px ${fade(palette.controlStrokeBaseFocusedLight, 0.6)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeBaseFocusedLight, 0.6)}`,
    focusRingLight,
  ],
  fieldDisabledLight: [
    `0 2px 1px 0 ${fade(palette.gray900, 0.08)}`,
    `0 2px 3px 0 ${fade(palette.gray900, 0.05)}`,
    `0 0 0 1px rgba(177, 181, 199, 1)`,
  ],
  fieldErrorLight: [
    `0 2px 1px 0 ${fade(palette.gray900, 0.08)}`,
    `0 2px 3px 0 ${fade(palette.gray900, 0.05)}`,
    `0 0 0 1.5px ${fade(palette.red600, 0.8)}`,
  ],
  fieldInitialDark: [
    `0 2px 1px 0 ${fade(palette.gray900, 0.25)}`,
    `0 2px 3px 0 ${fade(palette.gray900, 0.4)}`,
    `0 0 0 1px ${fade(palette.gray900, 0.5)}`,
  ],
  fieldHoveredDark: [
    `0 2px 1px 0 ${fade(palette.gray900, 0.4)}`,
    `0 2px 3px 0 ${fade(palette.gray900, 0.5)}`,
    `0 0 0 1px ${fade(palette.gray900, 0.7)}`,
  ],
  fieldFocusedDark: [
    `0 0 0 0.5px ${fade(palette.controlStrokeBaseFocusedDark, 0.6)}`,
    `inset 0 0 0 0.5px ${fade(palette.controlStrokeBaseFocusedDark, 0.6)}`,
    focusRingDark,
  ],
  fieldDisabledDark: [
    `0 2px 1px 0 ${fade(palette.gray900, 0.02)}`,
    `0 2px 3px 0 ${fade(palette.gray900, 0.05)}`,
    `0 0 0 1px ${fade(palette.gray900, 0.4)}`,
  ],
  fieldErrorDark: [
    `0 2px 1px 0 ${fade(palette.gray900, 0.25)}`,
    `0 2px 3px 0 ${fade(palette.gray900, 0.4)}`,
    `0 0 0 1px ${fade(palette.red300, 0.8)}`,
  ],

  // Device
  deviceLight: [
    `0 0 0 1px ${fade(palette.gray900, 0.08)}`,
    `0 1px 1px 0 ${fade(palette.gray900, 0.08)}`,
    `0 2px 3px 0 ${fade(palette.gray900, 0.05)}`,
  ],
  deviceDark: [
    `0 0 0 1px ${fade(palette.gray600, 0.8)}`,
    `0 1px 1px 0 ${fade(palette.gray600, 0.8)}`,
    `0 2px 3px 0 ${fade(palette.gray600, 0.7)}`,
  ],

  // Connection
  connectionLight: [
    `0 0 0 0.5px ${palette.strokeApplicationLight}`,
    `inset 0 0 0 0.5px ${palette.strokeApplicationLight}`,
    `0 1px 2px 0 ${fade(palette.gray900, 0.05)}`,
    `0 3px 5px 0 ${fade(palette.gray900, 0.03)}`,
  ],
  connectionDark: [
    `0 0 0 0.5px ${palette.strokeApplicationDark}`,
    `inset 0 0 0 0.5px ${palette.strokeApplicationDark}`,
    `0 1px 2px 0 ${fade(palette.gray700, 0.5)}`,
    `0 3px 5px 0 ${fade(palette.gray700, 0.3)}`,
  ],

  // Layer
  layerLight: [
    `-1px 0 1px 0 ${fade(palette.gray800, 0.04)}`,
    `-2px 0 3px 0 ${fade(palette.gray800, 0.04)}`,
  ],

  layerDark: [
    `-1px 0 0 0 ${fade(palette.gray700, 0.8)}`,
    `-1px 0 1px 0 ${fade(palette.gray900, 0.8)}`,
    `-2px 0 3px 0 ${fade(palette.gray900, 0.8)}`,
  ],

  // Stuck
  stuckLight: [
    `0 1px 1px 0 ${fade(palette.gray800, 0.04)}`,
    `0 2px 3px 0 ${fade(palette.gray800, 0.04)}`,
  ],

  stuckDark: [
    `0 1px 0 0 ${fade(palette.gray700, 0.8)}`,
    `0 1px 1px 0 ${fade(palette.gray900, 0.8)}`,
    `0 2px 3px 0 ${fade(palette.gray900, 0.8)}`,
  ],

  // Overlay
  overlayLight: [
    `0 3px 5px 0 ${fade(palette.gray900, 0.05)}`,
    `0 2px 3px 0 ${fade(palette.gray900, 0.05)}`,
    `0 2px 0 0 ${fade(palette.gray900, 0.05)}`,
    `0 0 0 1px ${fade(palette.gray900, 0.08)}`,
  ],
  overlayDark: [
    `0 3px 5px 0 ${fade(palette.gray700, 0.3)}`,
    `0 2px 3px 0 ${fade(palette.gray700, 0.5)}`,
    `0 2px 0 0 ${fade(palette.gray700, 0.2)}`,
    `0 0 0 1px ${fade(palette.gray700, 0.9)}`,
  ],

  // Dialog
  modalLight: [
    `0 9px 11px 0 ${fade(palette.gray900, 0.05)}`,
    `0 7px 8px 0 ${fade(palette.gray900, 0.05)}`,
    `0 3px 0 0 ${fade(palette.gray900, 0.04)}`,
    `0 0 0 1px ${fade(palette.gray900, 0.08)}`,
  ],
  modalDark: [
    `0 9px 11px 0 ${fade(palette.gray700, 0.3)}`,
    `0 7px 8px 0 ${fade(palette.gray700, 0.5)}`,
    `0 3px 0 0 ${fade(palette.gray700, 0.2)}`,
    `0 0 0 1px ${fade(palette.gray700, 0.9)}`,
  ],

  // Navbar
  navbarLight: [
    `0 1px 2px 0 ${fade(palette.gray900, 0.04)}`,
    `0 3px 5px 0 ${fade(palette.gray900, 0.05)}`,
    `inset 0 -1px 0 0 ${fade(palette.gray900, 0.12)}`,
  ],
  navbarDark: [
    `0 1px 1px 0 ${fade(palette.gray900, 0.6)}`,
    `0 1px 3px 0 ${fade(palette.gray900, 0.5)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.06)}`,
  ],

  // Icon
  manufacturerLight: [
    `0 0 0 1px ${fade(palette.gray900, 0.06)}`,
    `0px 1px 0px ${fade(palette.gray900, 0.06)}`,
    `0px 2px 1px ${fade(palette.gray900, 0.05)}`,
  ],
  manufacturerDark: [
    `0 0 0 1px ${fade(palette.black, 0.85)}`,
    `0 1px 0 0 ${fade(palette.black, 0.7)}`,
    `0 2px 1px 0 ${fade(palette.black, 0.5)}`,
  ],
};

export const shadows = createShadowAnimationStack(shadowParts);
