import { createContext, useContext } from 'react';

import type { SectionPropRelation, SectionPropSize } from './Section';

type SectionRelation = SectionPropRelation;
const SectionRelationContext = createContext<SectionRelation | undefined>(undefined);
export const SectionRelationProvider = SectionRelationContext.Provider;
export const useSectionRelation = (
  controlledValue?: SectionRelation,
  defaultValue: SectionRelation = 'standalone',
) => {
  const sectionRelation = useContext(SectionRelationContext);
  return controlledValue ?? sectionRelation ?? defaultValue;
};

type SectionSize = SectionPropSize;
const SectionSizeContext = createContext<SectionSize | undefined>(undefined);
export const SectionSizeProvider = SectionSizeContext.Provider;
export const useSectionSize = (
  controlledValue?: SectionSize,
  defaultValue: SectionSize = 'medium',
) => {
  const sectionSize = useContext(SectionSizeContext);
  return controlledValue ?? sectionSize ?? defaultValue;
};
