import React, { type ComponentProps } from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { GutterProp } from '../../common/sizing';
import type { BetaProp } from '../../utilities/useBeta';
import type { InternalProp } from '../../utilities/useInternal';
import { Icon } from '../../assets/Icon/Icon';
import { ControlSizeProvider } from '../../common/control_size';
import { FocusRing } from '../../common/focus_rings';
import { sizing } from '../../common/sizing';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import { selectors } from '../../controls/shared/styles';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Small } from '../../text/Small';
import { Text } from '../../text/Text';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import { VStack } from '../../utilities/Stack/VStack';
import { BetaProvider, useBeta } from '../../utilities/useBeta';
import { InternalProvider, useInternal } from '../../utilities/useInternal';
import { Badge } from '../Badge/Badge';
import { Tabs } from '../Tabs/Tabs';
import { LabelTooltip, Tooltip } from '../Tooltip/Tooltip';
import {
  SectionRelationProvider,
  SectionSizeProvider,
  useSectionRelation,
  useSectionSize,
} from './utils';

const SectionHeaderIcon = styled(Icon, {
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },

  variants: {
    hasSize: {
      'x-small': {
        width: '$12',
        height: '$12',
      },
      medium: {
        width: '$16',
        height: '$16',
      },
    },
  },
});

const SectionHeaderHeading = styled(Text, {
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: fontWeights.bold,
});

const SectionHeaderDescription = styled(Small);

const SectionHeaderActions = styled(ControlGroup);

const SectionHeaderTabs = styled(Tabs, {
  minHeight: 'auto',
});

const SectionHeaderStart = styled('div', {
  minWidth: 0,
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$8',
});

const SectionHeaderEnd = styled('div', {
  minWidth: 0,
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$8',
});

const SectionHeaderContainer = styled('div', {
  minWidth: 0,
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  variants: {
    size: {
      'x-small': {
        gap: '$6',
        minHeight: '$20',
      },
      medium: {
        minHeight: '$24',
        gap: '$8',
      },
    },
    relation: {
      stacked: {},
      standalone: {},
    },
  },

  compoundVariants: [
    {
      size: 'x-small',
      relation: 'stacked',
      css: {
        padding: `$4 ${sizing.contentSides}`,
      },
    },
    {
      size: 'medium',
      relation: 'stacked',
      css: {
        padding: `$8 ${sizing.sides}`,
      },
    },
    {
      size: 'medium',
      relation: 'standalone',
      css: {
        padding: `$8 ${sizing.contentSides}`,
      },
    },
  ],
});

export type SectionHeaderProps = {
  /**
   * Provide any actions.
   */
  actions?: React.ReactNode;
  /**
   * Boolean to signify the section as beta.
   */
  beta?: BetaProp;
  /**
   * Provide a count for your pane.
   */
  count?: number;
  /**
   * Provide a description for your pane.
   */
  description?: React.ReactNode;
  /**
   * Provide a heading for your pane.
   */
  heading?: React.ReactNode;
  /**
   * Provide an icon for your pane.
   */
  icon?: IconName;
  /**
   * Boolean to signify the section as internal.
   */
  internal?: InternalProp;
  /**
   * The relation of the section.
   */
  relation?: SectionPropRelation;
  /**
   * The size of the section header.
   */
  size?: SectionPropSize;
  /**
   * Provide any tabs you want to display.
   */
  tabs?: React.ReactNode;
  /**
   * Provide a tooltip for the heading.
   */
  tooltip?: React.ReactNode;
  style?: React.CSSProperties;
};

function SectionFeatureBadge({
  isBeta,
  isInternal,
}: {
  isBeta: BetaProp;
  isInternal: InternalProp;
}) {
  if (!isBeta && !isInternal) return null;

  const value = isBeta || isInternal;
  const featureBadge = <FeatureBadge type={isBeta ? 'beta' : 'internal'} size="small" />;

  if (typeof value === 'string') {
    return (
      <Tooltip contents={value} asChild={false}>
        {featureBadge}
      </Tooltip>
    );
  }

  return featureBadge;
}

export function SectionHeader({
  actions,
  beta,
  count,
  description,
  heading,
  icon,
  internal,
  relation,
  size,
  tabs,
  tooltip,
  ...remaining
}: SectionHeaderProps) {
  const isBeta = useBeta(beta);
  const isInternal = useInternal(internal);
  const sectionRelation = useSectionRelation(relation, 'standalone');
  const hasLockup = icon || heading;

  const sectionSize = useSectionSize(size, 'medium');

  return (
    <InternalProvider value={isInternal}>
      <BetaProvider value={isBeta}>
        <SectionHeaderContainer relation={sectionRelation} size={sectionSize} {...remaining}>
          <SectionHeaderStart>
            {hasLockup && (
              <AlignStack
                gap={sectionSize === 'x-small' ? 4 : 6}
                preset={sectionSize === 'x-small' ? 'small' : 'large'}
                start={icon && <SectionHeaderIcon icon={icon} hasSize={sectionSize} size={14} />}
                end={
                  (count || isBeta || isInternal) && (
                    <>
                      {count != null && (
                        <Badge
                          ends="card"
                          size={sectionSize === 'x-small' ? 'x-small' : 'small'}
                          variant="neutral"
                        >
                          {count}
                        </Badge>
                      )}
                      <SectionFeatureBadge isBeta={isBeta} isInternal={isInternal} />
                    </>
                  )
                }
              >
                {(heading || description) && (
                  <VStack>
                    {heading && (
                      <LabelTooltip
                        contents={tooltip}
                        preset={sectionSize === 'x-small' ? 'small' : 'large'}
                      >
                        <SectionHeaderHeading context="heading">{heading}</SectionHeaderHeading>
                      </LabelTooltip>
                    )}
                    {description && (
                      <SectionHeaderDescription>{description}</SectionHeaderDescription>
                    )}
                  </VStack>
                )}
              </AlignStack>
            )}
            {tabs && <SectionHeaderTabs>{tabs}</SectionHeaderTabs>}
          </SectionHeaderStart>
          <SectionHeaderEnd>
            {actions && (
              <ControlSizeProvider value="small">
                <SectionHeaderActions
                  relation="separate"
                  size={sectionSize === 'x-small' ? 'x-small' : 'small'}
                >
                  {actions}
                </SectionHeaderActions>
              </ControlSizeProvider>
            )}
          </SectionHeaderEnd>
        </SectionHeaderContainer>
      </BetaProvider>
    </InternalProvider>
  );
}

const SectionContentContainer = styled(VStack, {
  position: 'relative',

  variants: {
    gutter: {
      all: {},
      vertical: {},
      horizontal: {},
      none: {},
      bottom: {},
    },
    relation: {
      stacked: {},
      standalone: {},
    },
    size: {
      'x-small': {},
      medium: {},
    },
  },

  compoundVariants: [
    {
      gutter: 'all',
      relation: 'stacked',
      size: 'x-small',
      css: {
        padding: sizing.contentSquish,
      },
    },
    {
      gutter: 'vertical',
      relation: 'stacked',
      size: 'x-small',
      css: {
        padding: sizing.contentEndsOnly,
      },
    },
    {
      gutter: 'horizontal',
      relation: 'stacked',
      size: 'x-small',
      css: {
        padding: sizing.contentSidesOnly,
      },
    },
    {
      gutter: 'top',
      relation: 'stacked',
      size: 'x-small',
      css: {
        paddingTop: sizing.contentEnds,
      },
    },
    {
      gutter: 'right',
      relation: 'stacked',
      size: 'x-small',
      css: {
        paddingRight: sizing.contentSides,
      },
    },
    {
      gutter: 'bottom',
      relation: 'stacked',
      size: 'x-small',
      css: {
        paddingBottom: sizing.contentEnds,
      },
    },
    {
      gutter: 'left',
      relation: 'stacked',
      size: 'x-small',
      css: {
        paddingLeft: sizing.contentSides,
      },
    },
    {
      gutter: 'all',
      relation: 'stacked',
      size: 'medium',
      css: {
        padding: `$8 ${sizing.sides}`,
      },
    },
    {
      gutter: 'vertical',
      relation: 'stacked',
      size: 'medium',
      css: {
        padding: '$8 0',
      },
    },
    {
      gutter: 'horizontal',
      relation: 'stacked',
      size: 'medium',
      css: {
        padding: sizing.sidesOnly,
      },
    },
    {
      gutter: 'top',
      relation: 'stacked',
      size: 'medium',
      css: {
        paddingTop: '$8',
      },
    },
    {
      gutter: 'right',
      relation: 'stacked',
      size: 'medium',
      css: {
        paddingRight: sizing.sides,
      },
    },
    {
      gutter: 'bottom',
      relation: 'stacked',
      size: 'medium',
      css: {
        paddingBottom: '$8',
      },
    },
    {
      gutter: 'left',
      relation: 'stacked',
      size: 'medium',
      css: {
        paddingLeft: sizing.sides,
      },
    },
    {
      gutter: 'all',
      relation: 'standalone',
      size: 'medium',
      css: {
        padding: sizing.contentSquish,
      },
    },
    {
      gutter: 'vertical',
      relation: 'standalone',
      size: 'medium',
      css: {
        padding: sizing.contentEndsOnly,
      },
    },
    {
      gutter: 'horizontal',
      relation: 'standalone',
      size: 'medium',
      css: {
        padding: sizing.contentSidesOnly,
      },
    },
    {
      gutter: 'top',
      relation: 'standalone',
      size: 'medium',
      css: {
        paddingTop: sizing.contentEnds,
      },
    },
    {
      gutter: 'right',
      relation: 'standalone',
      size: 'medium',
      css: {
        paddingRight: sizing.contentSides,
      },
    },
    {
      gutter: 'bottom',
      relation: 'standalone',
      size: 'medium',
      css: {
        paddingBottom: sizing.contentEnds,
      },
    },
    {
      gutter: 'left',
      relation: 'standalone',
      size: 'medium',
      css: {
        paddingLeft: sizing.contentSides,
      },
    },
  ],
});

export type SectionContentProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: GutterProp;
  relation?: SectionPropRelation;
  size?: SectionPropSize;
} & ComponentProps<typeof SectionContentContainer>;

export function SectionContent({
  children,
  gutter = 'none',
  relation,
  size,
  ...remaining
}: SectionContentProps) {
  const sectionRelation = useSectionRelation(relation, 'standalone');
  const sectionSize = useSectionSize(size, 'medium');
  return (
    <SectionContentContainer
      gutter={gutter}
      relation={sectionRelation}
      size={sectionSize}
      {...remaining}
    >
      {children}
    </SectionContentContainer>
  );
}

const SectionFooterHelper = styled(Small);

const SectionFooterEnds = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$8',
  padding: '$6 0',
});

const SectionFooterStart = styled(SectionFooterEnds);

const SectionFooterEnd = styled(SectionFooterEnds);

const SectionFooterContainer = styled('div', {
  position: 'relative',
  hStack: '$8',
  justifyContent: 'space-between',
  minHeight: '$36',

  variants: {
    relation: {
      stacked: {
        padding: sizing.sidesOnly,

        '&:before': {
          left: sizing.sides,
          right: sizing.sides,
        },
      },
      standalone: {
        padding: sizing.contentSidesOnly,

        '&:before': {
          left: sizing.contentSides,
          right: sizing.contentSides,
        },
      },
    },
  },
});

export type SectionFooterProps = {
  /**
   * Provide any actions for the right-side.
   */
  endActions?: React.ReactNode;
  /**
   * Any helper text that should be shown.
   */
  helper?: React.ReactNode;
  relation?: SectionPropRelation;
  /**
   * Provide any actions for the left-side.
   */
  startActions?: React.ReactNode;
};

export function SectionFooter({
  endActions,
  helper,
  relation,
  startActions,
  ...remaining
}: SectionFooterProps) {
  const sectionRelation = useSectionRelation(relation, 'standalone');
  return (
    <SectionFooterContainer relation={sectionRelation} {...remaining}>
      <SectionFooterStart>
        {startActions && (
          <ControlGroup size="small" relation="separate">
            {startActions}
          </ControlGroup>
        )}
        {helper && <SectionFooterHelper>{helper}</SectionFooterHelper>}
      </SectionFooterStart>
      <SectionFooterEnd>
        {endActions && (
          <ControlGroup size="small" relation="separate">
            {endActions}
          </ControlGroup>
        )}
      </SectionFooterEnd>
    </SectionFooterContainer>
  );
}

export const SectionTarget = styled('a', Body, FocusRing, {
  position: 'relative',
  hStack: '$4',
  justifyContent: 'center',
  minHeight: '$36',
  color: colors.blue600,
  cursor: 'pointer',
  fontWeight: fontWeights.bold,

  [darkThemeSelector]: {
    color: colors.blue300,
  },

  [selectors.hover]: {
    color: colors.blue700,

    [darkThemeSelector]: {
      color: colors.blue200,
    },
  },

  '&:first-child': {
    borderRadiusTop: '$8',
  },

  '&:last-child': {
    borderRadiusBottom: '$8',
  },
});

const SectionContainer = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',

  variants: {
    siblingAware: {
      true: {},
      false: {},
    },
    relation: {
      stacked: {},
      standalone: {
        borderRadius: '$8',
      },
    },
    size: {
      'x-small': {},
      medium: {},
    },
  },

  compoundVariants: [
    {
      relation: 'stacked',
      siblingAware: true,
      css: {
        position: 'relative',

        '&::before, &::after': {
          content: '',
          position: 'absolute',
          display: 'block',
          height: '$1',
          backgroundColor: colors.strokeNeutralLight,

          [darkThemeSelector]: {
            backgroundColor: colors.strokeNeutralDark,
          },
        },

        '&::before': {
          top: '-0.5px',
        },

        '&::after': {
          bottom: '-0.5px',
        },

        '&:first-child::before': {
          display: 'none',
        },

        '&:last-child::after': {
          display: 'none',
        },
      },
    },
    {
      relation: 'stacked',
      siblingAware: true,
      size: 'x-small',
      css: {
        padding: '$8 0',

        '&::before, &::after': {
          right: sizing.contentSides,
          left: sizing.contentSides,
        },
      },
    },
    {
      relation: 'stacked',
      siblingAware: true,
      size: 'medium',
      css: {
        padding: '$12 0',

        '&::before, &::after': {
          right: sizing.sides,
          left: sizing.sides,
        },
      },
    },
  ],
});

export type SectionPropRelation = 'stacked' | 'standalone';
export type SectionPropSize = 'x-small' | 'medium';

export type SectionProps = {
  /**
   * Boolean to signify the section as beta.
   */
  beta?: BetaProp;
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Boolean to show internal-only styles.
   */
  internal?: InternalProp;
  /**
   * Determines how the section is related to its siblings.
   */
  relation?: SectionPropRelation;
  /**
   * The size of the section.
   */
  size?: SectionPropSize;
} & ComponentProps<typeof SectionContainer>;

export function Section({
  beta,
  children,
  internal,
  relation = 'standalone',
  siblingAware = true,
  size = 'medium',
  ...remaining
}: SectionProps) {
  return (
    <SectionRelationProvider value={relation}>
      <SectionSizeProvider value={size}>
        <SectionContainer
          relation={relation}
          siblingAware={siblingAware}
          size={size}
          {...remaining}
        >
          <InternalProvider value={internal}>
            <BetaProvider value={beta}>{children}</BetaProvider>
          </InternalProvider>
        </SectionContainer>
      </SectionSizeProvider>
    </SectionRelationProvider>
  );
}

const SectionsContainer = styled(VStack, {
  width: '100%',
});

type SectionsProps = {
  children: React.ReactNode;
} & ComponentProps<typeof SectionsContainer>;

export function Sections({ children, ...remaining }: SectionsProps) {
  return <SectionsContainer {...remaining}>{children}</SectionsContainer>;
}
