import React from 'react';

import type {
  ShellContentProps,
  ShellFooterProps,
  ShellHeaderProps,
  ShellProps,
} from '../Shell/Shell';
import { sizing } from '../../common/sizing';
import { zIndexes } from '../../common/z_indexes';
import { styled } from '../../stitches.config';
import { LayoutDrawer } from '../Layout/Layout';
import { Shell, ShellContent, ShellFooter, ShellHeader } from '../Shell/Shell';

const DrawerHeaderContainer = styled(ShellHeader);

export function DrawerHeader(props: ShellHeaderProps) {
  return <DrawerHeaderContainer {...props} />;
}

export type DrawerContentProps = ShellContentProps;

const DrawerContentContainer = styled(ShellContent);

export function DrawerContent({ children, ...remaining }: DrawerContentProps) {
  return <DrawerContentContainer {...remaining}>{children}</DrawerContentContainer>;
}

const DrawerFooterContainer = styled(ShellFooter);

export type DrawerFooterProps = ShellFooterProps & {
  /**
   * Provide primary actions for your drawer.
   */
  actions?: React.ReactNode;
  /**
   * Provide secondary actions, positioned at the start of the footer.
   */
  secondaryActions?: React.ReactNode;
};

export function DrawerFooter({ actions, secondaryActions, ...remaining }: DrawerFooterProps) {
  return <DrawerFooterContainer start={secondaryActions} end={actions} {...remaining} />;
}

// Guarantees a minimum internal width for the Drawer.
const drawerWidth = sizing.primary * 2 + 320;

export const DrawerContainer = styled(Shell, {
  '@notDesktop': {
    width: '100%',
    minWidth: '100%',
  },

  '@desktop': {
    width: `$${drawerWidth}`,
    minWidth: `$${drawerWidth}`,
  },

  '@notMobile': {
    position: 'relative',
    zIndex: zIndexes.paneDrawer,
  },
});

type DrawerProps = ShellProps;

export function Drawer({ children, contentMode = 'fit', ...remaining }: DrawerProps) {
  return (
    <LayoutDrawer>
      <DrawerContainer contentMode={contentMode} {...remaining}>
        {children}
      </DrawerContainer>
    </LayoutDrawer>
  );
}
