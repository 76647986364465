import type { VariantProp } from '../../common/colors';
import type { SpacingValue } from '../../common/sizing';
import type { HardwareIconName } from '../HardwareIcon/HardwareIcon';
import type { IconName } from '../Icon/Icon';
import type { ManufacturerIconName } from '../ManufacturerIcon/ManufacturerIcon';
import type { ProductIconName } from '../ProductIcon/ProductIcon';
import type { ProviderIconName } from '../ProviderIcon/ProviderIcon';
import type { ServiceIconName } from '../ServiceIcon/ServiceIcon';
import { FramedIcon } from '../FramedIcon/FramedIcon';
import { HardwareIcon, HardwareIconValues } from '../HardwareIcon/HardwareIcon';
import { Icon, IconValues } from '../Icon/Icon';
import { ManufacturerIcon, ManufacturerIconValues } from '../ManufacturerIcon/ManufacturerIcon';
import { ProductIcon, ProductIconValues } from '../ProductIcon/ProductIcon';
import { ProviderIcon, ProviderIconValues } from '../ProviderIcon/ProviderIcon';
import { ServiceIcon, ServiceIconValues } from '../ServiceIcon/ServiceIcon';

export type DynamicIconName =
  | HardwareIconName
  | IconName
  | ManufacturerIconName
  | ProductIconName
  | ProviderIconName
  | ServiceIconName;

export const DynamicIconNameValues = [
  ...HardwareIconValues,
  ...IconValues,
  ...ManufacturerIconValues,
  ...ProductIconValues,
  ...ProviderIconValues,
  ...ServiceIconValues,
] as DynamicIconName[];

export type DynamicIconProps = {
  icon: DynamicIconName;
  size?: SpacingValue;
  variant?: VariantProp;
};

function DynamicIconElement({ icon, size = 16, variant, ...remaining }: DynamicIconProps) {
  if (Object.values(HardwareIconValues).includes(icon as HardwareIconName)) {
    if (size < 20) {
      return <Icon icon={icon as IconName} size={size} variant={variant} {...remaining} />;
    }
    return <HardwareIcon icon={icon as HardwareIconName} size={size} {...remaining} />;
  }
  if (Object.values(ManufacturerIconValues).includes(icon as ManufacturerIconName)) {
    return <ManufacturerIcon icon={icon as ManufacturerIconName} size={size} {...remaining} />;
  }
  if (Object.values(ProductIconValues).includes(icon as ProductIconName)) {
    return <ProductIcon icon={icon as ProductIconName} size={size} {...remaining} />;
  }
  if (Object.values(ProviderIconValues).includes(icon as ProviderIconName)) {
    return <ProviderIcon icon={icon as ProviderIconName} size={size} {...remaining} />;
  }
  if (Object.values(ServiceIconValues).includes(icon as ServiceIconName)) {
    return <ServiceIcon icon={icon as ServiceIconName} size={size} {...remaining} />;
  }
  if (Object.values(IconValues).includes(icon as IconName)) {
    if (size < 20) {
      return <Icon icon={icon as IconName} size={size} variant={variant} {...remaining} />;
    }
    return <FramedIcon icon={icon as IconName} size={size} variant={variant} {...remaining} />;
  }
  return null;
}

export function DynamicIcon({ icon, size = 16, variant, ...remaining }: DynamicIconProps) {
  return <DynamicIconElement icon={icon} size={size} variant={variant} {...remaining} />;
}
