import { merge } from 'lodash-es';

import type { ColorPalette } from '../../common/colors';
import type { CSS } from '../../stitches.config';
import { darkThemeSelector } from '../../stitches.config';

export type FillTypes = ColorPalette;
export type FillProps = {
  dark?: FillTypes;
  light?: FillTypes;
};

export const fillCSS = (dark?: FillTypes, light?: FillTypes): CSS =>
  merge(
    {},
    light != null ? { backgroundColor: `$${light}` } : {},
    dark != null ? { [darkThemeSelector]: { backgroundColor: `$${dark}` } } : {},
  );
