import { createContext, useContext } from 'react';

export type InternalProp = boolean | string;

const InternalContext = createContext<InternalProp | undefined>(undefined);

export const InternalProvider = InternalContext.Provider;

export function useInternal(controlledValue?: InternalProp, defaultValue: InternalProp = false) {
  const isInternal = useContext(InternalContext);
  return controlledValue ?? isInternal ?? defaultValue;
}
