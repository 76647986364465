import React from 'react';

import { colors, darkThemeSelector, styled } from '../../stitches.config';

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'minmax(min-content, 1fr) min-content minmax(min-content, 1fr)',
  alignItems: 'center',
  gap: '$8',
  height: '$40',
  paddingX: '$12',
  strokeBottom: colors.strokeApplicationLight,
  background: colors.bgApplicationLight,
  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    strokeBottom: colors.strokeApplicationDark,
  },
});

const NavigationContainer = styled('div', { hStack: '$2' });
const ControlsContainer = styled('div', { hStack: '$8', justifyContent: 'end' });

export interface DeprecatedHeaderProps {
  navigation?: React.ReactNode;
  logo?: React.ReactNode;
  controls?: React.ReactNode;
}

export function DeprecatedHeader({ controls, logo, navigation }: DeprecatedHeaderProps) {
  return (
    <Container>
      <NavigationContainer>{navigation}</NavigationContainer>
      <div>{logo}</div>
      <ControlsContainer>{controls}</ControlsContainer>
    </Container>
  );
}
