import React from 'react';

import type { BadgeEnds, BadgeSize, BadgeVariant } from './Badge';
import { fade, palette } from '../../common/colors';
import { ControlSizeProvider } from '../../common/control_size';
import { darkThemeSelector, styled } from '../../stitches.config';
import { BadgeContainer } from './Badge';
import { BadgeEndsProvider } from './BadgeContext';

const resetChildSelector = `& ${BadgeContainer}:not(:first-child,:last-child)`;
const firstChildSelector = `& ${BadgeContainer}:first-child:not(:last-child)`;
const lastChildSelector = `& ${BadgeContainer}:last-child:not(:first-child)`;

const BadgeGroupContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  variants: {
    alignment: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
    ends: {
      card: {},
      pill: {},
    },
    relation: {
      joined: {
        [resetChildSelector]: {
          borderRadius: 0,
        },
      },
      separate: {},
    },
    size: {
      'x-small': {},
      small: {},
      medium: {},
      large: {},
    },
    tied: {
      true: {
        [`${BadgeContainer}`]: {
          position: 'relative',

          [`&:not(:first-child)`]: {
            '&::before': {
              content: '',
              position: 'absolute',
              top: '50%',
              left: '-$1',
              display: 'block',
              width: '$2',
              height: '$8',
              transform: 'translateY(-50%)',
              background: fade(palette.gray900, 0.4),
              borderRadius: '99em',

              [darkThemeSelector]: {
                background: fade(palette.white, 0.4),
              },
            },

            '&::after': {
              content: '',
              position: 'absolute',
              top: '50%',
              left: '-$4',
              display: 'block',
              width: '$8',
              height: '$2',
              transform: 'translateY(-50%)',
              background: fade(palette.gray900, 0.4),
              borderRadius: '99em',

              [darkThemeSelector]: {
                background: fade(palette.white, 0.4),
              },
            },
          },
        },
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      relation: 'separate',
      size: 'x-small',
      css: {
        gap: '$4',
      },
    },
    {
      relation: 'separate',
      size: 'small',
      css: {
        gap: '$6',
      },
    },
    {
      relation: 'separate',
      size: 'medium',
      css: {
        gap: '$8',
      },
    },
    {
      relation: 'separate',
      size: 'large',
      css: {
        gap: '$10',
      },
    },
    {
      ends: 'card',
      relation: 'joined',
      size: 'x-small',
      css: {
        [firstChildSelector]: {
          borderRadius: '$4 0 0 $4',
        },

        [lastChildSelector]: {
          borderRadius: '0 $4 $4 0',
        },
      },
    },
    {
      ends: 'card',
      relation: 'joined',
      size: 'small',
      css: {
        [firstChildSelector]: {
          borderRadius: '$6 0 0 $6',
        },

        [lastChildSelector]: {
          borderRadius: '0 $6 $6 0',
        },
      },
    },
    {
      ends: 'card',
      relation: 'joined',
      size: 'medium',
      css: {
        [firstChildSelector]: {
          borderRadius: '$8 0 0 $8',
        },

        [lastChildSelector]: {
          borderRadius: '0 $8 $8 0',
        },
      },
    },
    {
      ends: 'card',
      relation: 'joined',
      size: 'large',
      css: {
        [firstChildSelector]: {
          borderRadius: '$10 0 0 $10',
        },

        [lastChildSelector]: {
          borderRadius: '0 $10 $10 0',
        },
      },
    },
    {
      ends: 'pill',
      relation: 'joined',
      css: {
        [firstChildSelector]: {
          borderRadius: '99em 0 0 99em',
        },

        [lastChildSelector]: {
          borderRadius: '0 99em 99em 0',
        },
      },
    },
  ],
});

export type BadgeGroupProps = {
  children: React.ReactNode;
  alignment?: 'start' | 'center' | 'end';
  ends?: BadgeEnds;
  relation?: 'joined' | 'separate';
  size?: BadgeSize;
  tied?: boolean;
  variant?: BadgeVariant;
};

export function BadgeGroup({
  children,
  alignment = 'start',
  ends = 'card',
  relation = 'separate',
  size = 'medium',
  tied,
  variant,
  ...remaining
}: BadgeGroupProps) {
  return (
    <BadgeGroupContainer
      alignment={alignment}
      ends={ends}
      relation={relation}
      size={size}
      tied={tied}
      {...remaining}
    >
      <ControlSizeProvider value={size}>
        <BadgeEndsProvider value={ends}>{children}</BadgeEndsProvider>
      </ControlSizeProvider>
    </BadgeGroupContainer>
  );
}
