import React from 'react';
import { mergeProps, useField } from 'react-aria';

import type { CheckboxInputProps } from '../../components/CheckboxInput/CheckboxInput';
import type { BaseFieldProps } from './common/types';
import { sizing } from '../../common/sizing';
import { Alert } from '../../components/Alert/Alert';
import { Badge } from '../../components/Badge/Badge';
import { CheckboxInput } from '../../components/CheckboxInput/CheckboxInput';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import filterErrorMessage from '../../utils/filterErrorMessage';
import { PrimaryFieldDescription, PrimaryFieldLabel } from './common/FieldLabel';
import { useCustomFieldProps } from './createCustomFieldPropsProvider';
import { MaybeFieldContainer } from './FieldContainer';

const PrimaryCheckboxFieldParent = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$8',
  width: '100%',
});

const PrimaryCheckboxFieldLabelling = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
});

const PrimaryCheckboxFieldStart = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const PrimaryCheckboxFieldAlert = styled(Alert, {
  padding: sizing.contentSquish,
});

const PrimaryCheckboxFieldContainer = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  gap: '$8',
  padding: sizing.contentSquish,

  '&:not(:last-child)::after': {
    content: '',
    position: 'absolute',
    display: 'block',
    right: sizing.contentSides,
    bottom: '-0.5px',
    left: sizing.contentSides,
    height: '1px',
    background: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      background: colors.strokeNeutralDark,
    },
  },
});

export interface PrimaryCheckboxFieldProps extends BaseFieldProps, CheckboxInputProps {
  internal?: boolean;
}

export function PrimaryCheckboxField(props: PrimaryCheckboxFieldProps) {
  const { fieldProps: customFieldProps, inputProps: customInputProps } = useCustomFieldProps();

  const {
    description,
    errorMessage = customFieldProps.errorMessage,
    internal,
    label = customFieldProps.label,
    ...extraInputProps
  } = props;

  const {
    fieldProps: inputProps,
    descriptionProps,
    errorMessageProps,
    labelProps,
  } = useField({
    description,
    errorMessage,
    label,
  });

  return (
    <MaybeFieldContainer>
      <PrimaryCheckboxFieldContainer>
        <PrimaryCheckboxFieldParent>
          <AlignStack
            direction="row"
            gap={8}
            preset="body"
            start={<CheckboxInput {...mergeProps(customInputProps, inputProps, extraInputProps)} />}
          >
            <PrimaryCheckboxFieldStart>
              <PrimaryCheckboxFieldLabelling>
                <PrimaryFieldLabel {...labelProps}>{label}</PrimaryFieldLabel>
                {internal && (
                  <Badge ends="card" size="small" internal>
                    Internal
                  </Badge>
                )}
              </PrimaryCheckboxFieldLabelling>
              {description && (
                <PrimaryFieldDescription {...descriptionProps}>
                  {description}
                </PrimaryFieldDescription>
              )}
            </PrimaryCheckboxFieldStart>
          </AlignStack>
        </PrimaryCheckboxFieldParent>
      </PrimaryCheckboxFieldContainer>
      {errorMessage && (
        <PrimaryCheckboxFieldAlert
          {...errorMessageProps}
          type="inline"
          relation="stacked"
          variant="negative"
          heading={filterErrorMessage(errorMessage)}
        />
      )}
    </MaybeFieldContainer>
  );
}
