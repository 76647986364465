import { colors, darkThemeSelector, styled } from '../stitches.config';
import { Text } from './Text';

const HeadingBase = {
  color: colors.headingNeutralLight,
  [darkThemeSelector]: {
    color: colors.headingNeutralDark,
  },
  variants: {
    internal: {
      true: {
        color: colors.internalBodyLight,
        [darkThemeSelector]: {
          color: colors.internalBodyDark,
        },
      },
      false: {},
    },
  },
};

export const HeadingSansSizes = {
  '@notDesktop': {
    fontSize: '$24',
    lineHeight: '$32',
  },
  '@desktop': {
    fontSize: '$20',
    lineHeight: '$28',
  },
};

export const HeadingMonoSizes = {
  '@notDesktop': {
    fontSize: '$22',
    lineHeight: '$32',
  },
  '@desktop': {
    fontSize: '$18',
    lineHeight: '$28',
  },
};

export const Heading = styled(Text, HeadingBase, {
  variants: {
    context: {
      body: {},
      heading: {},
    },
    family: {
      regular: HeadingSansSizes,
      monospace: HeadingMonoSizes,
    },
    weight: {
      medium: {},
    },
  },
  defaultVariants: {
    context: 'heading',
    family: 'regular',
    weight: 'bold',
  },
});
