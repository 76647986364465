import type { AriaTextFieldProps } from '@react-types/textfield';
import type { ForwardedRef, RefObject } from 'react';
import React, { useRef } from 'react';
import { useTextField } from 'react-aria';
import { mergeRefs } from 'react-merge-refs';

import type { ControlSize } from '../../controls/shared/types';
import type { RenameKeys } from '../../types/rename_keys';
import type { InputSharedProps } from '../BaseInput/BaseInput';
import { selectors } from '../../controls/shared/styles';
import { styled } from '../../stitches.config';
import { BaseInput } from '../BaseInput/BaseInput';

type RemappedAriaTextFieldProps = RenameKeys<AriaTextFieldProps, { isDisabled: 'disabled' }>;

export interface SearchInputProps extends InputSharedProps, RemappedAriaTextFieldProps {
  invalid?: boolean;
  controlSize?: ControlSize;
  scope?: 'full' | 'scoped';
  inputRef?: RefObject<HTMLInputElement>;
}

const SearchInputField = styled(BaseInput, {
  width: '$56',
  maxWidth: '$56',
  minWidth: '$56',

  [selectors.focus]: {
    width: '$180',
    maxWidth: '$180',
    minWidth: '$180',
  },
});

export const SearchInput = React.forwardRef<HTMLLabelElement, SearchInputProps>((props, ref) => {
  const {
    invalid,
    prefix,
    scope,
    suffix,
    controlSize,
    disabled = false,
    width,
    maxWidth,
    minWidth,
    inputRef: inputRefProp,
  } = props;
  const internalInputRef = useRef<HTMLInputElement>(null);
  const refsArgs = inputRefProp ? [inputRefProp, internalInputRef] : [internalInputRef];
  const inputRef = mergeRefs(refsArgs);
  const { inputProps } = useTextField({ ...props, isDisabled: disabled }, internalInputRef);

  return (
    <SearchInputField
      ref={ref}
      type="search"
      inputRef={inputRef}
      inputProps={inputProps}
      invalid={invalid}
      disabled={disabled}
      icon={scope === 'scoped' ? 'search-scoped' : 'search'}
      prefix={prefix}
      suffix={suffix}
      controlSize={controlSize}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
    />
  );
}) as (
  props: SearchInputProps & { ref?: ForwardedRef<HTMLLabelElement> },
) => ReturnType<typeof SearchInputField>;
