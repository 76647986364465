import type { ComboBoxProps } from '@react-types/combobox';
import * as React from 'react';
import { useComboBox, useFilter } from 'react-aria';
import { Dialog } from 'react-aria-components';
import { useComboBoxState } from 'react-stately';

import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Box } from '../../utilities/Box/Box';
import { BaseInput } from '../BaseInput/BaseInput';
import { ComboBoxList, ComboBoxListEmpty } from './ComboBoxList';

export { Item, Section } from 'react-stately';

const ComboBoxOverlayHeaderTop = styled('div', {
  display: 'flex',
  flex: 1,
});

const ComboBoxOverlayHeaderBottom = styled('div', {
  display: 'flex',
});

const ComboBoxOverlayHeader = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  padding: '$12',
  strokeBottom: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    strokeBottom: colors.strokeNeutralDark,
  },
});

const StyledDialog = styled(Dialog, {
  display: 'flex',
  flexGrow: 1,
});

const ComboBoxOverlayContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

export function ComboBoxOverlay<T extends object>(
  props: Omit<ComboBoxProps<T>, 'children'> & {
    isOpen: boolean;
    onClose: () => void;
    actions?: React.ReactNode;
    /**
     * The minimum number of characters required to be input before showing results.
     * To limit number of items rendered in very large lists.
     */
    minSearchLength?: number;
  },
) {
  // a typical ComboBox sets the current value inside the text input, but we want to use it for filtering only.
  const [filterValue, setFilterValue] = React.useState('');

  const buttonRef = React.useRef(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const listBoxRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const { contains } = useFilter({ sensitivity: 'base' });
  const state = useComboBoxState({
    ...props,
    defaultFilter: contains,
    // override the default behavior that syncs the current value with the input
    inputValue: filterValue,
    onInputChange: (e) => {
      setFilterValue(e);
    },
    onSelectionChange: (key) => {
      props.onSelectionChange?.(key);
      props.onClose();
    },
  });

  // sync open state with the parent element
  React.useEffect(() => {
    if (props.isOpen) {
      // "manual" tells state manager to show the full list, even if an item is selected.
      // we need to call this method explicitly or nothing will be shown when the list opens.
      // this also removes the need for two down arrow keypresses to move the selection down.
      state.open(null, 'manual');
      // using the autoFocus prop on the input messes with focus behavior in Chrome when the list opens.
      inputRef.current?.focus();
    } else if (!props.isOpen) {
      state.close();
    }
  }, [props.isOpen, state]);

  const { inputProps, listBoxProps } = useComboBox(
    {
      ...props,
      inputRef,
      buttonRef,
      listBoxRef,
      popoverRef,
    },
    state,
  );

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    // select nothing if there's no focused item
    if (e.key === 'Enter' && !state.selectionManager.focusedKey) {
      e.preventDefault();
      return;
    }

    // selecting the same item twice doesn't trigger a change event so we handle it manually
    if (e.key === 'Enter' && state.selectionManager.focusedKey === state.selectedKey) {
      props.onSelectionChange?.(state.selectedKey);
      return;
    }

    if (e.key === 'Escape') {
      props.onClose();
      return;
    }

    inputProps.onKeyDown?.(e);
  }

  return (
    <StyledDialog ref={popoverRef}>
      <ComboBoxOverlayContainer>
        <ComboBoxOverlayHeader>
          <ComboBoxOverlayHeaderTop>
            <BaseInput
              inputProps={{
                value: inputProps.value,
                onChange: inputProps.onChange,
                placeholder: 'Search...',
                onKeyDown: onInputKeyDown,
              }}
              icon="search"
              inputRef={inputRef}
              shouldApplyFocusStyles={false}
              type="search"
              width="100%"
            />
          </ComboBoxOverlayHeaderTop>
          {props.actions && (
            <ComboBoxOverlayHeaderBottom>{props.actions}</ComboBoxOverlayHeaderBottom>
          )}
        </ComboBoxOverlayHeader>
        {!props.minSearchLength ||
        (inputProps?.value?.toString() &&
          inputProps.value.toString().length >= props.minSearchLength) ? (
          <ComboBoxList
            {...listBoxProps}
            shouldUseVirtualFocus
            shouldAlwaysFocusFirstItem
            listBoxRef={listBoxRef}
            onSelectionChange={props.onClose}
            noResultsMessage={`No results matching "${state.inputValue}"`}
            state={state}
          />
        ) : (
          <Box padding={{ all: 4 }}>
            <ComboBoxListEmpty>
              Please provide at least {props.minSearchLength}{' '}
              {props.minSearchLength === 1 ? 'character' : 'characters'} to see results.
            </ComboBoxListEmpty>
          </Box>
        )}
      </ComboBoxOverlayContainer>
    </StyledDialog>
  );
}
