import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type { DynamicIconName } from '../../assets/DynamicIcon/DynamicIcon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { DynamicIcon } from '../../assets/DynamicIcon/DynamicIcon';
import { fade, palette } from '../../common/colors';
import { selectors, transitions } from '../../controls/shared/styles';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Text } from '../../text/Text';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';

const DeviceTargetIcon = styled(DynamicIcon, {
  color: '$$iconColor',
});

const DeviceTargetLabel = styled(Text, {
  display: 'flex',
  color: '$$labelColor',
  fontWeight: fontWeights.medium,

  variants: {
    size: {
      large: {
        gap: '$6',
      },
      medium: {
        gap: '$4',
      },
      small: {
        gap: '$4',
      },
    },
    wrap: {
      true: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
      },
      false: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
});

const DeviceTargetContents = styled(AlignStack, {
  position: 'relative',
  zIndex: 2,
});

const DeviceTargetBoundary = styled('div', {
  position: 'absolute',
  zIndex: 1,
  top: '$$boundaryEnds',
  right: '$$boundarySides',
  bottom: '$$boundaryEnds',
  left: '$$boundarySides',
  background: '$$boundaryColor',
  strokeAll: '$$boundaryStroke',
  borderRadius: '$$boundaryRadius',
  transition: transitions.control,
});

const DeviceTargetContainer = styled('div', {
  position: 'relative',
  display: 'inline-flex',
  maxWidth: '100%',
  transition: transitions.control,

  variants: {
    isPressable: {
      true: {
        cursor: 'pointer',
        $$iconColor: colors.linkIconInitialLight,
        $$labelColor: colors.linkInitialLight,

        [darkThemeSelector]: {
          $$iconColor: colors.linkIconInitialDark,
          $$labelColor: colors.linkInitialDark,
        },

        [selectors.hover]: {
          $$boundaryColor: fade(palette.tokenBgAlternativeLight, 0.4),
          $$boundaryStroke: fade(palette.tokenStrokeAlternativeLight, 0.5),
          $$iconColor: colors.bodyAlternativeLight,
          $$labelColor: colors.headingAlternativeLight,

          [darkThemeSelector]: {
            $$boundaryColor: fade(palette.tokenBgAlternativeDark, 0.3),
            $$boundaryStroke: fade(palette.tokenStrokeAlternativeDark, 0.4),
            $$iconColor: colors.bodyAlternativeDark,
            $$labelColor: colors.headingAlternativeDark,
          },
        },

        [selectors.focus]: {
          outline: 'none',
          $$boundaryColor: colors.bgAlternativeLight,
          $$iconColor: colors.bodyAlternativeLight,
          $$labelColor: colors.headingAlternativeLight,

          [darkThemeSelector]: {
            $$boundaryColor: colors.bgAlternativeDark,
            $$iconColor: colors.bodyAlternativeDark,
            $$labelColor: colors.headingAlternativeDark,
          },

          [`${DeviceTargetBoundary}`]: {
            boxShadow: shadows.focusRingLight,

            [darkThemeSelector]: {
              boxShadow: shadows.focusRingDark,
            },
          },
        },
      },
      false: {
        cursor: 'default',
        $$iconColor: colors.iconNeutralLight,
        $$labelColor: colors.bodyNeutralLight,

        [darkThemeSelector]: {
          $$iconColor: colors.iconNeutralDark,
          $$labelColor: colors.bodyNeutralDark,
        },

        [selectors.hover]: {
          $$boundaryColor: fade(palette.tokenBgNeutralLight, 0.4),
          $$boundaryStroke: fade(palette.tokenStrokeNeutralLight, 0.5),
          $$iconColor: colors.bodyNeutralLight,
          $$labelColor: colors.headingNeutralLight,

          [darkThemeSelector]: {
            $$boundaryColor: fade(palette.tokenBgNeutralDark, 0.3),
            $$boundaryStroke: fade(palette.tokenStrokeNeutralDark, 0.4),
            $$iconColor: colors.bodyNeutralDark,
            $$labelColor: colors.headingNeutralDark,
          },
        },
      },
    },
    selected: {
      true: {
        $$boundaryColor: colors.bgBrandLight,
        $$boundaryStroke: colors.strokeBrandLight,
        $$iconColor: colors.bodyBrandLight,
        $$labelColor: colors.headingBrandLight,

        [darkThemeSelector]: {
          $$boundaryColor: colors.bgBrandDark,
          $$boundaryStroke: colors.strokeBrandDark,
          $$iconColor: colors.bodyBrandDark,
          $$labelColor: colors.headingBrandDark,
        },
      },
      false: {},
    },
    size: {
      large: {
        $$boundaryEnds: '-4px',
        $$boundarySides: '-12px',
        $$boundaryRadius: '10px',
      },
      medium: {
        $$boundaryEnds: '-4px',
        $$boundarySides: '-6px',
        $$boundaryRadius: '8px',
      },
      small: {
        display: 'inline-flex',
        $$boundaryEnds: '-2px',
        $$boundarySides: '-4px',
        $$boundaryRadius: '6px',
      },
    },
  },
});

type DeviceTargetPropSize = 'large' | 'medium' | 'small';

export type DeviceTargetProps = {
  'aria-label'?: string;
  children: React.ReactNode;
  icon?: DynamicIconName;
  onClick?: (event: any) => void;
  size?: DeviceTargetPropSize;
  selected?: boolean;
  wrap?: boolean;
};

const getDeviceTargetIconSize = (size: DeviceTargetPropSize) => {
  switch (size) {
    case 'large':
      return 20;
    default:
      return 16;
  }
};

const getDeviceTargetPreset = (size: DeviceTargetPropSize) => {
  if (size === 'large') return 'large';
  if (size === 'medium') return 'body';
  return 'small';
};

export const DeviceTarget = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      as = 'span' as Tag,
      children,
      icon,
      onClick,
      size = 'medium',
      wrap = true,
      selected,
      ...remaining
    }: PolymorphicComponentProps<Tag, DeviceTargetProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => {
    const isPressable = Boolean(onClick || (as !== 'span' && as !== 'div'));

    return (
      <DeviceTargetContainer
        ref={forwardedRef}
        as={as}
        onClick={onClick}
        role={onClick && 'button'}
        tabIndex={isPressable ? 0 : undefined}
        isPressable={isPressable}
        size={size}
        selected={selected}
        {...remaining}
      >
        <DeviceTargetContents
          direction="row"
          gap={size === 'large' ? 6 : 4}
          preset={getDeviceTargetPreset(size)}
          start={icon && <DeviceTargetIcon size={getDeviceTargetIconSize(size)} icon={icon} />}
        >
          <DeviceTargetLabel size={size} wrap={wrap}>
            {children}
          </DeviceTargetLabel>
        </DeviceTargetContents>
        <DeviceTargetBoundary />
      </DeviceTargetContainer>
    );
  },
) as Polymorphic.ForwardRefComponent<'a', DeviceTargetProps>;
