import type { SVGAttributes } from 'react';
import { ProviderIconSVG } from 'atto-svgs';
import React from 'react';

import type { SpacingScale } from '../../utilities/shared/sizes';
import { styled } from '../../stitches.config';
import { AssetTile } from '../AssetTile/AssetTile';

export const ProviderIcons = {
  unknown: ProviderIconSVG.Unknown,
  allstream: ProviderIconSVG.Allstream,
  att: ProviderIconSVG.ATT,
  cogent: ProviderIconSVG.Cogent,
  comcastBusiness: ProviderIconSVG.ComcastBusiness,
  cox: ProviderIconSVG.Cox,
  cradlePoint: ProviderIconSVG.CradlePoint,
  crownCastle: ProviderIconSVG.CrownCastle,
  cruzio: ProviderIconSVG.Cruzio,
  earthLink: ProviderIconSVG.EarthLink,
  fastmetrics: ProviderIconSVG.Fastmetrics,
  for2Fi: ProviderIconSVG.For2Fi,
  frontier: ProviderIconSVG.Frontier,
  google: ProviderIconSVG.Google,
  gtt: ProviderIconSVG.GTT,
  hughesNet: ProviderIconSVG.HughesNet,
  lumen: ProviderIconSVG.Lumen,
  monkeybrains: ProviderIconSVG.Monkeybrains,
  pilot: ProviderIconSVG.Pilot,
  rcn: ProviderIconSVG.RCN,
  sonic: ProviderIconSVG.Sonic,
  spectrum: ProviderIconSVG.Spectrum,
  tMobile: ProviderIconSVG.TMobile,
  verizon: ProviderIconSVG.Verizon,
  viasat: ProviderIconSVG.Viasat,
  wave: ProviderIconSVG.Wave,
  webpass: ProviderIconSVG.Webpass,
  wiLine: ProviderIconSVG.WiLine,
  windstream: ProviderIconSVG.Windstream,
  wow: ProviderIconSVG.WOW,
  xfinity: ProviderIconSVG.Xfinity,
  zayo: ProviderIconSVG.Zayo,
};

export const ProviderIconValues = Object.keys(ProviderIcons);
export type ProviderIconName = keyof typeof ProviderIcons;
export const ProviderIconNames = Object.keys(ProviderIcons) as ProviderIconName[];

const ProviderIconLiteral = styled('svg', {
  display: 'flex',
  width: '100%',
  height: '100%',
});

export interface ProviderIconProps {
  /**
   * Set which piece of provider to display.
   */
  icon: ProviderIconName;
  /**
   * Set a size of the component.
   */
  size?: SpacingScale;
}

export function ProviderIcon({ icon, size = 40, ...remaining }: ProviderIconProps) {
  const NamedProviderIcon = ProviderIcons[icon] as
    | React.ComponentType<SVGAttributes<SVGSVGElement>>
    | undefined;

  if (!NamedProviderIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${icon}`);
    return null;
  }

  return (
    <AssetTile size={size} unset {...remaining}>
      <ProviderIconLiteral as={NamedProviderIcon} {...remaining} />
    </AssetTile>
  );
}
