import { colors, darkThemeSelector, styled } from '../stitches.config';
import { Text } from './Text';

const BodyBase = {
  color: colors.bodyNeutralLight,
  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
  variants: {
    internal: {
      true: {
        color: colors.internalBodyLight,
        [darkThemeSelector]: {
          color: colors.internalBodyDark,
        },
      },
      false: {},
    },
  },
};

export const BodySansSizes = {
  '@notDesktop': {
    fontSize: '$16',
    lineHeight: '$24',
  },
  '@desktop': {
    fontSize: '$14',
    lineHeight: '$20',
  },
};

export const BodyMonoSizes = {
  '@notDesktop': {
    fontSize: '$15',
    lineHeight: '$24',
  },
  '@desktop': {
    fontSize: '$13',
    lineHeight: '$20',
  },
};

export const Body = styled(Text, BodyBase, {
  variants: {
    family: {
      regular: BodySansSizes,
      monospace: BodyMonoSizes,
    },
  },
  defaultVariants: {
    family: 'regular',
  },
});
