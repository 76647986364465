import React from 'react';

import { visuallyHiddenCSS } from '../../common/visually_hidden';
import { colors, darkThemeSelector, styled } from '../../stitches.config';

export interface VerticalSplitProps {
  start?: React.ReactNode;
  end?: React.ReactNode;
  footer?: React.ReactNode;
}

const VerticalSplitBlock = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '400px',
  minHeight: 0,
});

const VerticalSplitHalf = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: '100%',

  '@notDesktop': {
    gap: '$32',
    padding: '$32',
  },

  '@desktop': {
    gap: '$80',
    padding: '$80',
  },
});

export const VerticalSplitStart = styled(VerticalSplitHalf, {
  backgroundColor: colors.bgApplicationLight,

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
  },

  '@notDesktop': {
    alignItems: 'center',
  },

  '@desktop': {
    alignItems: 'flex-end',
  },
});

const VerticalSplitEnd = styled(VerticalSplitHalf, {
  background: colors.captiveChecker,

  [darkThemeSelector]: {
    background: colors.captiveCheckerDark,
  },

  '@notDesktop': visuallyHiddenCSS,

  '@desktop': {
    alignItems: 'flex-start',
  },
});

const VerticalSplitContainer = styled('div', {
  display: 'flex',
  width: '100vw',
  maxWidth: '100vw',
  minWidth: '100vw',
  height: '100vh',
  maxHeight: '100vh',
  minHeight: '100vh',

  '@notDesktop': {
    flexDirection: 'column',
  },

  '@desktop': {
    flexDirection: 'row',
  },
});

export function VerticalSplit({ start, end, footer, ...remaining }: VerticalSplitProps) {
  return (
    <VerticalSplitContainer {...remaining}>
      <VerticalSplitStart>
        {start && <VerticalSplitBlock>{start}</VerticalSplitBlock>}
        {footer && <VerticalSplitBlock>{footer}</VerticalSplitBlock>}
      </VerticalSplitStart>
      <VerticalSplitEnd>{end && <VerticalSplitBlock>{end}</VerticalSplitBlock>}</VerticalSplitEnd>
    </VerticalSplitContainer>
  );
}
