import type { HelpTextProps } from '@react-types/shared/src/inputs';
import type { ReactNode } from 'react';
import { Slot } from '@radix-ui/react-slot';
import React from 'react';
import { mergeProps, useField } from 'react-aria';

import type { FieldPropsWithElement } from './common/types';
import { ControlSizeProvider } from '../../common/control_size';
import { sizing } from '../../common/sizing';
import { Alert } from '../../components/Alert/Alert';
import { Badge } from '../../components/Badge/Badge';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import filterErrorMessage from '../../utils/filterErrorMessage';
import { ErrorMessages, useErrorMessages } from './common/ErrorMessageRegistry';
import { FieldCompositeProvider } from './common/FieldCompositeProvider';
import { SecondaryFieldDescription, SecondaryFieldLabel } from './common/FieldLabel';
import { useDescriptionAndErrorIds } from './common/useDescriptionAndErrorIds';
import { useCustomFieldProps } from './createCustomFieldPropsProvider';
import { MaybeFieldContainer } from './FieldContainer';

const SecondaryFieldContainer = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  padding: `$10 ${sizing.contentSides}`,

  '&:not(:last-child)::after': {
    content: '',
    position: 'absolute',
    display: 'block',
    right: sizing.contentSides,
    bottom: '-0.5px',
    left: sizing.contentSides,
    height: '1px',
    background: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      background: colors.strokeNeutralDark,
    },
  },
});

const SecondaryFieldDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
});

const SecondaryFieldHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
});

const SecondaryFieldBadges = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
});

const SecondaryFieldLabelling = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
});

const SecondaryFieldControls = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
});

export const SecondaryFieldFields = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
});

const SecondaryFieldAlert = styled(Alert, {
  padding: sizing.contentSquish,
});

interface SecondaryFieldProps extends FieldPropsWithElement {
  badges?: React.ReactNode;
  controls?: React.ReactNode;
  /**
   * Marks the field as optional with a badge.
   */
  optional?: boolean;
}

export function SecondaryField(props: SecondaryFieldProps) {
  const { fieldProps: customFieldProps, inputProps: customInputProps } = useCustomFieldProps();

  const {
    badges,
    label = customFieldProps.label,
    description = customFieldProps.description,
    errorMessage = customFieldProps.errorMessage,
    controls,
    element,
    internal,
    optional,
    ...remaining
  } = props;

  const {
    labelProps,
    fieldProps: inputProps,
    descriptionProps,
    errorMessageProps,
  } = useField({
    label,
    description,
    errorMessage,
  });

  return (
    <MaybeFieldContainer>
      <SecondaryFieldContainer {...remaining}>
        <SecondaryFieldDetails>
          <SecondaryFieldHeader>
            <SecondaryFieldLabelling>
              <SecondaryFieldLabel {...labelProps}>{label}</SecondaryFieldLabel>
              {optional && (
                <Badge ends="card" size="small" variant="neutral">
                  Optional
                </Badge>
              )}
              {internal && <FeatureBadge type="internal" />}
              {badges && (
                <SecondaryFieldBadges>
                  <ControlSizeProvider value="small">{badges}</ControlSizeProvider>
                </SecondaryFieldBadges>
              )}
            </SecondaryFieldLabelling>
            {controls && (
              <SecondaryFieldControls>
                <ControlSizeProvider value="small">{controls}</ControlSizeProvider>
              </SecondaryFieldControls>
            )}
          </SecondaryFieldHeader>
          {description && (
            <SecondaryFieldDescription {...descriptionProps}>
              {description}
            </SecondaryFieldDescription>
          )}
        </SecondaryFieldDetails>
        <SecondaryFieldFields>
          <ControlSizeProvider value="small">
            <Slot {...mergeProps(inputProps, customInputProps)}>{element}</Slot>
          </ControlSizeProvider>
        </SecondaryFieldFields>
      </SecondaryFieldContainer>
      {errorMessage && (
        <SecondaryFieldAlert
          {...errorMessageProps}
          type="inline"
          relation="stacked"
          variant="negative"
          heading={filterErrorMessage(errorMessage)}
        />
      )}
    </MaybeFieldContainer>
  );
}

export type SecondaryFieldCompositeProps = {
  badges?: ReactNode;
  controls?: React.ReactNode;
  label: ReactNode;
  fields: ReactNode;
  internal?: boolean;
  /**
   * Marks the field as optional with a badge.
   */
  optional?: boolean;
} & HelpTextProps;

export function SecondaryFieldComposite({
  badges,
  description,
  errorMessage,
  controls,
  fields,
  label,
  internal,
  optional,
  ...remaining
}: SecondaryFieldCompositeProps) {
  const { messages, registerMessage, unregisterMessage } = useErrorMessages();

  const { descriptionId, errorMessageId, describedBy } = useDescriptionAndErrorIds(
    filterErrorMessage(errorMessage),
    description,
  );

  return (
    <FieldCompositeProvider
      registerMessage={registerMessage}
      unregisterMessage={unregisterMessage}
      describedBy={describedBy}
    >
      <SecondaryFieldContainer {...remaining}>
        <SecondaryFieldDetails>
          <SecondaryFieldHeader>
            <SecondaryFieldLabelling>
              <SecondaryFieldLabel>{label}</SecondaryFieldLabel>
              {optional && (
                <Badge ends="card" size="small" variant="neutral">
                  Optional
                </Badge>
              )}
              {internal && <FeatureBadge type="internal" />}
              {badges && (
                <SecondaryFieldBadges>
                  <ControlSizeProvider value="small">{badges}</ControlSizeProvider>
                </SecondaryFieldBadges>
              )}
            </SecondaryFieldLabelling>
            {controls && (
              <SecondaryFieldControls>
                <ControlSizeProvider value="small">{controls}</ControlSizeProvider>
              </SecondaryFieldControls>
            )}
          </SecondaryFieldHeader>
          {description && (
            <SecondaryFieldDescription id={descriptionId}>{description}</SecondaryFieldDescription>
          )}
        </SecondaryFieldDetails>
        <SecondaryFieldFields>
          <ControlSizeProvider value="small">{fields}</ControlSizeProvider>
        </SecondaryFieldFields>
      </SecondaryFieldContainer>
      <ErrorMessages messages={messages} />
      {errorMessage && (
        <SecondaryFieldAlert
          id={errorMessageId}
          type="inline"
          relation="stacked"
          variant="negative"
          heading={filterErrorMessage(errorMessage)}
        />
      )}
    </FieldCompositeProvider>
  );
}
