import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import type { DeprecatedSidebarNavItemProps } from '../DeprecatedSidebars/DeprecatedSidebarNavItem';
import { DeprecatedSidebarNavItem } from '../DeprecatedSidebars/DeprecatedSidebarNavItem';

export interface DeprecatedDesktopSidebarNavItemProps extends DeprecatedSidebarNavItemProps {}

export const DeprecatedDesktopSidebarNavItem = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      size = 'medium',
      ...props
    }: PolymorphicComponentProps<Tag, DeprecatedDesktopSidebarNavItemProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => <DeprecatedSidebarNavItem ref={forwardedRef} size={size} {...props} />,
) as Polymorphic.ForwardRefComponent<React.ElementType, DeprecatedDesktopSidebarNavItemProps>;
