import { Slot } from '@radix-ui/react-slot';
import { mergeProps, useField } from 'react-aria';

import type { FieldPropsWithElement } from './common/types';
import { Alert } from '../../components/Alert/Alert';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import filterErrorMessage from '../../utils/filterErrorMessage';
import { useCustomFieldProps } from './createCustomFieldPropsProvider';

export function FloatingField(props: FieldPropsWithElement) {
  const { fieldProps: customFieldProps, inputProps: customInputProps } = useCustomFieldProps();

  const {
    label = customFieldProps.label,
    description = customFieldProps.description,
    errorMessage = customFieldProps.errorMessage,
    element,
  } = props;

  const { fieldProps: inputProps, errorMessageProps } = useField({
    label,
    description,
    errorMessage,
  });

  return (
    <Tooltip
      tight
      contents={
        errorMessage ? (
          <Alert
            {...errorMessageProps}
            type="inline"
            variant="negative"
            heading={filterErrorMessage(errorMessage)}
          />
        ) : undefined
      }
    >
      <Slot {...mergeProps(inputProps, customInputProps)}>{element}</Slot>
    </Tooltip>
  );
}
