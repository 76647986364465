const layers = {
  base: 0,
  frame: 100,
  pane: 200,
  overlay: 500,
  dialog: 1000,
  alert: 2000,
} as const;

export const zIndexes = {
  // Base
  base: layers.base,

  // Frames
  frame: layers.frame,

  // Panes
  pane: layers.pane,
  paneDrawer: layers.pane + 1,

  // Overlays
  overlay: layers.overlay,

  // Dialogs
  dialog: layers.dialog,
  dialogContent: layers.dialog + 1,

  // Alerts
  alert: layers.alert,
};
