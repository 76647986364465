import cx from 'classnames';
import { merge } from 'lodash-es';
import React from 'react';

import type { CSS } from '../../stitches.config';
import type { AlignTypes } from '../shared/Align';
import type { CursorTypes } from '../shared/Cursor';
import type { DisplayTypes } from '../shared/Display';
import type { FillProps } from '../shared/Fill';
import type { GradientTypes } from '../shared/Gradient';
import type { HeightTypes } from '../shared/Height';
import type { JustifyTypes } from '../shared/Justify';
import type { MaxHeightTypes } from '../shared/MaxHeight';
import type { MaxWidthTypes } from '../shared/MaxWidth';
import type { MinHeightTypes } from '../shared/MinHeight';
import type { MinWidthTypes } from '../shared/MinWidth';
import type { OpacityTypes } from '../shared/Opacity';
import type { OverflowProps } from '../shared/Overflow';
import type { PaddingProps } from '../shared/Padding';
import type { RadiusProps } from '../shared/Radius';
import type { ShadowTypes } from '../shared/Shadow';
import type { TranslateXTypes } from '../shared/TranslateX';
import type { TranslateYTypes } from '../shared/TranslateY';
import type { WidthTypes } from '../shared/Width';
import type { PolymorphicComponentPropsWithRef, PolymorphicRef } from '../types/polymorphicAsProp';
import { styled } from '../../stitches.config';
import { alignCSS } from '../shared/Align';
import { fillCSS } from '../shared/Fill';
import { gradientCSS } from '../shared/Gradient';
import { heightCSS } from '../shared/Height';
import { justifyCSS } from '../shared/Justify';
import { maxHeightCSS } from '../shared/MaxHeight';
import { maxWidthCSS } from '../shared/MaxWidth';
import { minHeightCSS } from '../shared/MinHeight';
import { minWidthCSS } from '../shared/MinWidth';
import { opacityCSS } from '../shared/Opacity';
import { overflowCSS } from '../shared/Overflow';
import { paddingCSS } from '../shared/Padding';
import { radiusCSS } from '../shared/Radius';
import { shadowCSS } from '../shared/Shadow';
import { translateXCSS } from '../shared/TranslateX';
import { translateYCSS } from '../shared/TranslateY';
import { widthCSS } from '../shared/Width';

const BaseBox = styled('div');

interface Props {
  children?: React.ReactNode;
  className?: string;

  align?: AlignTypes;
  bg?: FillProps;
  cursor?: CursorTypes;
  display?: DisplayTypes;
  focus?: 'group' | 'self' | 'within';
  gradient?: GradientTypes;
  height?: HeightTypes;
  maxHeight?: MaxHeightTypes;
  minHeight?: MinHeightTypes;
  justify?: JustifyTypes;
  opacity?: OpacityTypes;
  overflow?: OverflowProps;
  padding?: PaddingProps;
  position?: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky';
  pointerEvents?: 'auto' | 'none';
  radius?: RadiusProps;
  shadow?: ShadowTypes;
  translateX?: TranslateXTypes;
  translateY?: TranslateYTypes;
  width?: WidthTypes;
  maxWidth?: MaxWidthTypes;
  minWidth?: MinWidthTypes;
}

export type BoxProps<Tag extends React.ElementType> = PolymorphicComponentPropsWithRef<Tag, Props>;

type BoxComponent = <C extends React.ElementType = 'span'>(
  props: BoxProps<C>,
) => React.ReactNode | null;

export const Box: BoxComponent = React.forwardRef(
  <Tag extends React.ElementType = 'div'>(
    {
      align,
      as,
      bg,
      className,
      cursor,
      display,
      focus,
      gradient,
      height,
      maxHeight,
      minHeight,
      justify,
      opacity,
      overflow,
      padding,
      pointerEvents,
      position,
      radius,
      shadow,
      translateX,
      translateY,
      width,
      maxWidth,
      minWidth,
      ...remaining
    }: BoxProps<Tag>,
    ref: PolymorphicRef<Tag>,
  ) => {
    const classes = cx(
      // Focus
      {
        'm-focus-group': focus === 'group',
        'm-focus': focus === 'self',
        'm-focus-within': focus === 'within',
        'm-transition-all m-duration-150 m-ease-linear': focus,
      },

      className,
    );

    const baseCSS: CSS = {
      cursor,
      display,
      pointerEvents,
      position,
    };

    const css = merge(
      baseCSS,
      alignCSS(align),
      justifyCSS(justify),
      opacityCSS(opacity),
      overflowCSS(overflow?.all, overflow?.x, overflow?.y),
      fillCSS(bg?.dark, bg?.light),
      gradientCSS(gradient),
      heightCSS(height),
      maxHeightCSS(maxHeight),
      maxWidthCSS(maxWidth),
      minHeightCSS(minHeight),
      minWidthCSS(minWidth),
      radiusCSS(radius),
      translateXCSS(translateX),
      translateYCSS(translateY),
      widthCSS(width),
      paddingCSS(padding),
      shadowCSS(shadow),
    );

    return <BaseBox {...remaining} as={as} ref={ref} className={classes} css={css} />;
  },
);
