import React from 'react';

import type { BandVariant } from '../Band/Band';
import { styled } from '../../stitches.config';
import { Band } from '../Band/Band';

const Base = styled('div', {
  display: 'inline-flex',
  position: 'relative',
});

const CenteredItem = styled('div', {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  inset: 0,
});

export type BandSize = 'large' | 'medium' | 'small';

export interface BandWithIconProps {
  /**
   * Content to display in the center of the band
   */
  children: React.ReactNode;
  /**
   * Size of the icon
   */
  size?: BandSize;
  /**
   * Color variant applied to the band around the icon
   */
  variant?: BandVariant;
}

export function BandWithIcon({
  children,
  size = 'medium',
  variant = 'alternative',
  ...remaining
}: BandWithIconProps) {
  return (
    <Base {...remaining}>
      <Band size={size} variant={variant} />
      <CenteredItem>{children}</CenteredItem>
    </Base>
  );
}
