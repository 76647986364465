import React from 'react';

import { DeprecatedCloseButton } from './DeprecatedCloseButton';

export interface DeprecatedCloseDrawerButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export function DeprecatedCloseDrawerButton(props: DeprecatedCloseDrawerButtonProps) {
  return (
    <DeprecatedCloseButton
      type="button"
      {...props}
      aria-label={props['aria-label'] ?? 'Close drawer'}
    />
  );
}
