import React from 'react';

import type { HasChildren } from '../../types/components';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Caption } from '../../text/Caption';

const Label = styled('h5', Caption, {
  padding: '0 $8',
  marginBottom: '$4',
  fontWeight: fontWeights.bold,
  color: colors.gray500,
  [darkThemeSelector]: {
    color: colors.gray200,
  },
});

export function DeprecatedSidebarGroupLabel({ children }: HasChildren) {
  return <Label>{children}</Label>;
}
