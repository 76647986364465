import { ServiceIconSVG } from 'atto-svgs';

import type { SpacingScale } from '../../utilities/shared/sizes';
import { styled } from '../../stitches.config';
import { AssetTile } from '../AssetTile/AssetTile';

export const ServiceIcons = {
  accesspeople: ServiceIconSVG.AccessPeopleHR,
  adp: ServiceIconSVG.ADP,
  apple: ServiceIconSVG.Apple,
  asana: ServiceIconSVG.Asana,
  auth0: ServiceIconSVG.Auth0,
  azure: ServiceIconSVG.Azure,
  bamboo: ServiceIconSVG.BambooHR,
  breathe: ServiceIconSVG.BreatheHR,
  cas: ServiceIconSVG.CAS,
  cezanne: ServiceIconSVG.CezanneHR,
  chrome: ServiceIconSVG.Chrome,
  classlink: ServiceIconSVG.ClassLink,
  cloudflare: ServiceIconSVG.Cloudflare,
  cyberark: ServiceIconSVG.CyberArk,
  datadog: ServiceIconSVG.Datadog,
  docusign: ServiceIconSVG.Docusign,
  duo: ServiceIconSVG.Duo,
  figma: ServiceIconSVG.Figma,
  firebase: ServiceIconSVG.Firebase,
  firefox: ServiceIconSVG.Firefox,
  fourth: ServiceIconSVG.Fourth,
  github: ServiceIconSVG.Github,
  godaddy: ServiceIconSVG.GoDaddy,
  google: ServiceIconSVG.Google,
  googlecloud: ServiceIconSVG.GoogleCloud,
  greenhouse: ServiceIconSVG.Greenhouse,
  hibob: ServiceIconSVG.HiBob,
  jira: ServiceIconSVG.JIRA,
  jumpcloud: ServiceIconSVG.JumpCloud,
  keycloak: ServiceIconSVG.Keycloak,
  kisi: ServiceIconSVG.Kisi,
  lastpass: ServiceIconSVG.LastPass,
  linear: ServiceIconSVG.Linear,
  logingov: ServiceIconSVG.LoginGOV,
  loom: ServiceIconSVG.Loom,
  microsoft: ServiceIconSVG.Microsoft,
  miniorange: ServiceIconSVG.MiniOrange,
  netiq: ServiceIconSVG.NetIQ,
  notion: ServiceIconSVG.Notion,
  okta: ServiceIconSVG.Okta,
  onelogin: ServiceIconSVG.OneLogin,
  onepassword: ServiceIconSVG.OnePassword,
  oracle: ServiceIconSVG.Oracle,
  personio: ServiceIconSVG.Personio,
  pingidentity: ServiceIconSVG.PingIdentity,
  quip: ServiceIconSVG.Quip,
  ramp: ServiceIconSVG.Ramp,
  reactnativeexpo: ServiceIconSVG.ReactNativeExpo,
  rippling: ServiceIconSVG.Rippling,
  salesforce: ServiceIconSVG.Salesforce,
  sentry: ServiceIconSVG.Sentry,
  shibboleth: ServiceIconSVG.Shibboleth,
  simplesamlphp: ServiceIconSVG.SimpleSAMLPHP,
  slack: ServiceIconSVG.Slack,
  splunk: ServiceIconSVG.Splunk,
  supabase: ServiceIconSVG.Supabase,
  twitter: ServiceIconSVG.Twitter,
  vercel: ServiceIconSVG.Vercel,
  vmware: ServiceIconSVG.VMWare,
  workday: ServiceIconSVG.Workday,
  zoom: ServiceIconSVG.Zoom,
  airtable: ServiceIconSVG.Airtable,
};

export const ServiceIconValues = Object.keys(ServiceIcons);
export type ServiceIconName = keyof typeof ServiceIcons;
export const ServiceIconNames = Object.keys(ServiceIcons) as ServiceIconName[];

const ServiceIconLiteral = styled('svg', {
  display: 'flex',
  width: '100%',
  height: '100%',
});

export interface ServiceIconProps {
  /**
   * Set which manufacturer to display.
   */
  icon: ServiceIconName;
  /**
   * Set a size of the component.
   */
  size?: SpacingScale;
}

export function ServiceIcon({ icon, size = 28, ...remaining }: ServiceIconProps) {
  const NamedIcon = ServiceIcons[icon];

  if (!NamedIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${icon}`);
    return null;
  }
  return (
    <AssetTile size={size} {...remaining}>
      <ServiceIconLiteral as={NamedIcon} {...remaining} />
    </AssetTile>
  );
}
