import React from 'react';

import { selectors } from '../../controls/shared/styles';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { ViewportProvider } from '../../utilities/useViewport';

export const LayoutContainer = styled('div', {
  position: 'relative',
  display: 'grid',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  background: colors.bgNeutralLight,

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
  },

  '@mobile': {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content min-content min-content 1fr min-content',
    gridTemplateAreas: '"indicator" "banner" "dock" "page" "workbench"',
    minWidth: '$320',
  },

  '@notMobile': {
    gridTemplateColumns: 'min-content min-content minmax(0, 1fr) min-content',
    gridTemplateRows: 'min-content min-content 1fr min-content',
    gridTemplateAreas: `
      "indicator banner banner banner" 
      "indicator dock page workbench-end" 
      "indicator dock page workbench-end" 
      "indicator workbench-bottom workbench-bottom workbench-bottom"
    `,
  },
});

export type LayoutProps = {
  children?: React.ReactNode;
};

export function Layout({ children, ...remaining }: LayoutProps) {
  return (
    <ViewportProvider>
      <LayoutContainer id="main" {...remaining}>
        {children}
      </LayoutContainer>
    </ViewportProvider>
  );
}

export const LayoutBanner = styled('div', {
  position: 'relative',
  minWidth: 0,
  gridArea: 'banner',

  '@mobile': {
    zIndex: 250,
  },

  '@notMobile': {
    zIndex: 50,
  },
});

export const LayoutDock = styled('div', {
  position: 'relative',
  minWidth: 0,
  gridArea: 'dock',
  overflow: 'hidden',

  '@mobile': {
    zIndex: 250,
  },

  '@notMobile': {
    zIndex: 50,
  },

  variants: {
    collapsed: {
      true: {},
      false: {},
    },
  },
});

export const LayoutPage = styled('div', {
  position: 'relative',
  zIndex: 100,
  minWidth: 0,
  gridArea: 'page',
  display: 'grid',
  gridTemplateRows: 'min-content min-content 1fr',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  background: colors.bgApplicationLight,
  boxShadow: shadows.layerLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.layerDark,
  },

  '@mobile': {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content min-content 1fr',
    gridTemplateAreas: '"breadcrumb" "skimmer" "pane"',
    strokeTop: colors.strokeApplicationLight,
    [darkThemeSelector]: {
      strokeTop: colors.strokeApplicationDark,
    },
  },

  '@notMobile': {
    gridTemplateRows: 'min-content 1fr',
    gridTemplateColumns: 'fit-content(100%) minmax(0, 1fr) fit-content(100%)',
    gridTemplateAreas: '"breadcrumb breadcrumb breadcrumb" "skimmer pane drawer"',
  },

  [selectors.safari]: {
    borderTopRightRadius: '$10',
  },
});

export const LayoutBreadcrumb = styled('div', {
  minWidth: 0,
  gridArea: 'breadcrumb',
  overflow: 'hidden',
});

export const LayoutSkimmer = styled('div', {
  minWidth: 0,
  width: '$360',
  gridArea: 'skimmer',
  overflow: 'hidden',
  background: colors.bgApplicationLight,
  boxShadow: shadows.layerLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.layerDark,
  },

  '@notDesktop': {
    zIndex: 0,
  },

  '@desktop': {
    zIndex: 1,
  },
});

export const LayoutPane = styled('div', {
  minWidth: 0,
  gridArea: 'pane',
  overflow: 'hidden',
  background: colors.bgApplicationLight,
  boxShadow: shadows.layerLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.layerDark,
  },

  '&:only-child': {
    gridArea: 'pane / pane / drawer / drawer',
  },

  '@notDesktop': {
    zIndex: 0,
  },

  '@desktop': {
    zIndex: 2,
  },
});

export const LayoutDrawer = styled('div', {
  minWidth: 0,
  gridArea: 'drawer',
  overflow: 'hidden',
  background: colors.bgApplicationLight,
  boxShadow: shadows.layerLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.layerDark,
  },

  '@notDesktop': {
    zIndex: 1,
    gridArea: 'skimmer / skimmer / pane / pane',
    width: '100%',
  },

  '@desktop': {
    zIndex: 3,
  },
});

export const LayoutWorkbenchBottom = styled('div', {
  minWidth: 0,

  '@mobile': {
    gridArea: 'workbench',
  },

  '@notMobile': {
    gridArea: 'workbench-bottom',
  },
});

export const LayoutWorkbenchEnd = styled('div', {
  minWidth: 0,

  '@mobile': {
    gridArea: 'workbench',
  },

  '@notMobile': {
    gridArea: 'workbench-end',
  },
});
