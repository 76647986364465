/* eslint-disable @typescript-eslint/no-use-before-define */
import type { Node } from '@react-types/shared';
import type { AriaListBoxOptions } from 'react-aria';
import type { ListState } from 'react-stately';
import * as React from 'react';
import { useListBox, useListBoxSection, useOption } from 'react-aria';

import { Menu, MenuGroup, MenuItem } from '../../controls/Menu/Menu';
import { styled } from '../../stitches.config';
import { Small } from '../../text/Small';

interface ComboBoxListProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
}

interface ComboBoxListSectionProps {
  section: Node<unknown>;
  state: ListState<unknown>;
  focusedKey?: React.Key;
}

interface ComboBoxListOptionProps {
  item: Node<unknown>;
  state: ListState<unknown> & {
    isOpen?: boolean;
  };
  focusedKey?: React.Key;
}

// react-aria's internal SelectionManager doesn't support auto-focusing the first item as you type.
// In this hook we're listening for UI changes and manually focusing a key if none is focused.
// To avoid flickering between renders, we'll return the key we want to focus and manually pass it into <Option> as backup.
// (This happens fast enough that I'm not conerned about the delta between our visual focus and the actual focus.)
function useAlwaysFocusFirstItem(state: ListState<unknown>) {
  const nonSectionKeys = [...state.collection]
    .map((item) => {
      if (item.type === 'section' && state.collection.getChildren) {
        const children = state.collection.getChildren(item.key);
        return [...children].map((child) => child.key);
      }
      return item.key;
    })
    .flat();

  const firstKey = nonSectionKeys[0];

  let { focusedKey } = state.selectionManager;

  React.useLayoutEffect(() => {
    // focus the first item if none is focused
    if (!state.selectionManager.focusedKey && firstKey) {
      state.selectionManager.setFocusedKey(firstKey);
    }
  }, [firstKey, state.selectionManager]);

  if (!focusedKey && firstKey) {
    focusedKey = firstKey;
  }

  return { focusedKey };
}

function ComboBoxListOption({ item, state, focusedKey }: ComboBoxListOptionProps) {
  const ref = React.useRef<HTMLLIElement>(null);
  const {
    optionProps,
    isDisabled,
    isSelected,
    isFocused: isOptionFocused,
  } = useOption(
    {
      key: item.key,
    },
    state,
    ref,
  );

  const isManuallyFocused = focusedKey ? item.key === focusedKey : undefined;
  const isFocused = (isManuallyFocused ?? isOptionFocused) && !isDisabled;

  React.useEffect(() => {
    if (isSelected && state.isOpen) {
      ref.current?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [isSelected, state.isOpen]);

  return (
    <MenuItem
      {...optionProps}
      ref={ref}
      data-focused={isFocused ? '' : undefined}
      disabled={isDisabled}
      enabled={isSelected}
    >
      {item.rendered}
    </MenuItem>
  );
}

function ComboBoxListSection({ section, state, focusedKey }: ComboBoxListSectionProps) {
  const { itemProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  return (
    <MenuGroup {...itemProps} {...groupProps} label={section.rendered}>
      {[...section.childNodes].map((node) => (
        <ComboBoxListOption key={node.key} item={node} state={state} focusedKey={focusedKey} />
      ))}
    </MenuGroup>
  );
}

export const ComboBoxListEmpty = styled('div', Small, {
  display: 'flex',
  justifyContent: 'center',
  padding: '$12',
});

const ComboBoxListContainer = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: 0,
  padding: '$4',
  overflow: 'auto',
  outline: 'none',
});

export function ComboBoxList(
  props: ComboBoxListProps & {
    shouldAlwaysFocusFirstItem?: boolean;

    noResultsMessage?: string;
    state: ListState<unknown> & {
      isOpen?: boolean;
    };
  },
) {
  const ref = React.useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state, noResultsMessage } = props;
  const { listBoxProps } = useListBox(
    {
      ...props,
    },
    state,
    listBoxRef,
  );

  const { focusedKey } = useAlwaysFocusFirstItem(state);

  return (
    <ComboBoxListContainer {...listBoxProps} ref={listBoxRef}>
      <Menu>
        {[...state.collection].map((item) =>
          item.type === 'section' ? (
            <ComboBoxListSection
              key={item.key}
              section={item}
              state={state}
              focusedKey={focusedKey}
            />
          ) : (
            <ComboBoxListOption key={item.key} item={item} state={state} focusedKey={focusedKey} />
          ),
        )}
        {state.collection.size === 0 && noResultsMessage && (
          <ComboBoxListEmpty>{noResultsMessage}</ComboBoxListEmpty>
        )}
      </Menu>
    </ComboBoxListContainer>
  );
}
