import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { IconName, IconSize } from '../../assets/Icon/Icon';
import type { VariantProp } from '../../common/colors';
import type { DynamicTextPropType } from '../../text/DynamicText';
import { Icon } from '../../assets/Icon/Icon';
import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '../../common/animations';
import { selectors } from '../../controls/shared/styles';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { DynamicText } from '../../text/DynamicText';
import { Small } from '../../text/Small';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';

const TooltipTrigger = styled(TooltipPrimitive.Trigger, {
  [selectors.focus]: {
    outline: 'none',
  },
});

export const TooltipContainer = styled('div', Small, {
  display: 'flex',
  width: 'fit-content',
  maxWidth: '$280',
  backgroundColor: colors.bgApplicationLight,
  boxShadow: shadows.overlayLight,
  borderRadius: '$8',
  color: colors.gray700,

  [darkThemeSelector]: {
    color: colors.gray50,
    backgroundColor: colors.bgApplicationDark,
    boxShadow: shadows.overlayDark,
  },

  [selectors.focus]: {
    outline: 'none',
  },

  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '150ms',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="instant-open"], &[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideUpAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideDownAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },

  variants: {
    tight: {
      true: {},
      false: {
        padding: '$4 $6',
      },
    },
    hideOnDesktop: {
      true: {
        '@desktop': {
          display: 'none',
        },
      },
      false: {},
    },
  },
});

export type TooltipProps = {
  /**
   * Set whether the tooltip should wrap its children or be mounted to its children.
   */
  asChild?: boolean;
  /**
   * How the tooltip aligns to its trigger, defaults to center.
   */
  align?: 'center' | 'end' | 'start';
  /**
   * Any child will be set as the trigger for the tooltip.
   */
  children?: React.ReactNode;
  /**
   * Pass in any content to display inside the tooltip.
   */
  contents?: React.ReactNode;
  /**
   * The delay in milliseconds before the tooltip is shown, defaults to 100.
   */
  delay?: number;
  /**
   * Hides the tooltip for desktop users.
   */
  hideOnDesktop?: boolean;
  /**
   * The preferred side of the trigger to render against when open.
   */
  side?: 'top' | 'right' | 'bottom' | 'left';
  /**
   * The distance in pixels from the trigger, defaults to 6.
   */
  sideOffset?: number;
  /**
   * Treats the tooltip as a toggle, opening it (and closing it) when the trigger is clicked,
   * instead of on hover.
   */
  toggle?: boolean;
  /**
   * If true, remove padding from the tooltip container.
   */
  tight?: boolean;
  /**
   * If provided, controls the open state of the tooltip.
   */
  isOpen?: boolean;
};

export function Tooltip({
  align,
  asChild = true,
  children,
  contents,
  delay = 100,
  hideOnDesktop,
  side,
  sideOffset = 6,
  toggle = false,
  tight = false,
  isOpen,
}: TooltipProps) {
  const isControlled = isOpen !== undefined;
  const [open, setOpen] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const rootProps: { open?: boolean } = {};
  if (isControlled) {
    rootProps.open = isOpen;
  } else if (toggle) {
    rootProps.open = open;
  }

  const handleClick = useCallback(() => {
    if (toggle) {
      setOpen((v) => !v);
    }
  }, [toggle, setOpen]);

  useEffect(() => {
    function handleDocumentClick(event: MouseEvent) {
      if (
        contentRef.current &&
        triggerRef.current &&
        !contentRef.current.contains(event.target as Node) &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [contentRef, triggerRef, setOpen]);

  return (
    <TooltipPrimitive.Provider delayDuration={delay} skipDelayDuration={250}>
      <TooltipPrimitive.Root {...rootProps}>
        <TooltipTrigger
          ref={triggerRef}
          asChild={asChild}
          onClick={isControlled ? undefined : handleClick}
          type={asChild ? undefined : 'button'}
        >
          {children}
        </TooltipTrigger>
        <TooltipPrimitive.Content
          ref={contentRef}
          align={align}
          avoidCollisions
          side={side}
          sideOffset={sideOffset}
        >
          <TooltipContainer hideOnDesktop={hideOnDesktop} tight={tight}>
            {contents}
          </TooltipContainer>
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}

const IconTooltipIcon = styled(Icon, {
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

const IconTooltipContainer = styled('div', {
  display: 'flex',
});

export type IconTooltipProps = TooltipProps & {
  icon?: IconName;
  size?: IconSize;
  variant?: VariantProp;
};

export function IconTooltip({
  icon = 'question',
  size = 14,
  variant = 'neutral',
  ...remaining
}: IconTooltipProps) {
  return (
    <Tooltip {...remaining}>
      <IconTooltipContainer>
        <IconTooltipIcon icon={icon} size={size} variant={variant} />
      </IconTooltipContainer>
    </Tooltip>
  );
}

export type LabelTooltipProps = IconTooltipProps & {
  context?: 'body' | 'heading';
  preset?: DynamicTextPropType;
};

export function LabelTooltip({
  contents,
  context = 'body',
  children,
  icon = 'question',
  size = 14,
  preset = 'body',
  variant = 'neutral',
  ...remaining
}: LabelTooltipProps) {
  return (
    <AlignStack
      direction="row"
      gap={4}
      preset={preset}
      width="fit-content"
      end={
        contents ? (
          <IconTooltip
            contents={contents}
            icon={icon}
            size={size}
            variant={variant}
            {...remaining}
          />
        ) : undefined
      }
    >
      <DynamicText context={context} type={preset} variant={variant}>
        {children}
      </DynamicText>
    </AlignStack>
  );
}
