import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Icon } from '../../assets/Icon/Icon';
import { Button } from '../../controls/Button/Button';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Small } from '../../text/Small';
import { VStack } from '../../utilities/Stack/VStack';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { IconTooltip } from '../Tooltip/Tooltip';

export type ValidationError = {
  isValidationError: true;
  message: string;
};

export type FileUploaderError = Error | ValidationError;

function isValidationError(error: unknown): error is ValidationError {
  return error !== null && typeof error === 'object' && 'isValidationError' in error;
}

export type FileInfo = {
  id: string; // this is really just necessary to provide a unique key for use in lists
  uploadPercentage: number;
  fileObject: File;
  error: FileUploaderError | null;
};

export type ReactDropzoneProps = Exclude<Parameters<typeof useDropzone>[0], undefined>;

const DEFAULT_ALLOWED_ACCEPT = {
  image: {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/gif': ['.gif'],
    'image/svg+xml': ['.svg'],
  },
  video: {
    'video/mp4': ['.mp4'],
    'video/quicktime': ['.mov'],
  },
  csv: {
    'text/csv': ['.csv'],
  },
  json: {
    'application/json': ['.json'],
  },
  pdf: {
    'application/pdf': ['.pdf'],
  },
};

export type DropzoneUploaderProps = {
  files: FileInfo[];
  onFileRemoved: (id: string) => any;
  onDrop: NonNullable<ReactDropzoneProps['onDrop']>;
  accept?: ReactDropzoneProps['accept'];
  onRetryUpload?: (id: string) => any;
  allowedTypes?: {
    images?: boolean;
    videos?: boolean;
    csv?: boolean;
    json?: boolean;
    pdf?: boolean;
  };
  maxFiles?: number;
  compact?: boolean; // renders a compact version of the component, could eventually use ResizeObserver to apply this behavior automatically.
  callToAction?: string;
};

function generateAccept(
  accept: ReactDropzoneProps['accept'],
  allowedTypes: DropzoneUploaderProps['allowedTypes'],
) {
  if (accept) return accept; // prioritize explicit accept prop
  if (!allowedTypes) return undefined;

  let generatedAccept: Exclude<ReactDropzoneProps['accept'], undefined> = {};
  if (allowedTypes.images) {
    generatedAccept = { ...generatedAccept, ...DEFAULT_ALLOWED_ACCEPT.image };
  }
  if (allowedTypes.videos) {
    generatedAccept = { ...generatedAccept, ...DEFAULT_ALLOWED_ACCEPT.video };
  }
  if (allowedTypes.csv) {
    generatedAccept = { ...generatedAccept, ...DEFAULT_ALLOWED_ACCEPT.csv };
  }
  if (allowedTypes.json) {
    generatedAccept = { ...generatedAccept, ...DEFAULT_ALLOWED_ACCEPT.json };
  }
  if (allowedTypes.pdf) {
    generatedAccept = { ...generatedAccept, ...DEFAULT_ALLOWED_ACCEPT.pdf };
  }
  return generatedAccept;
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  border: '2px',
  borderRadius: '$8',
  borderStyle: 'dashed',
  borderColor: colors.strokeNeutralLight,
  [darkThemeSelector]: {
    borderColor: colors.gray600,
  },

  variants: {
    empty: {
      true: { justifyContent: 'center' },
    },
    active: {
      true: {
        backgroundColor: colors.bgNeutralLight,
        [darkThemeSelector]: {
          backgroundColor: colors.bgNeutralDark,
        },
      },
    },
  },
});

const EmptyDropContainer = styled('div', {
  paddingY: '$32',
});

const AlreadyUploadedDropContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  paddingY: '$12',
  paddingX: '$8',
  variants: {
    active: {
      true: {
        backgroundColor: colors.bgNeutralLight,
        [darkThemeSelector]: {
          backgroundColor: colors.bgNeutralDark,
        },
      },
    },
    compact: {
      true: {
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '$12',
      },
    },
  },
  marginTop: '-1px', // required to prevent double border w/ list items above on small screens
  borderTop: '1px solid',
  borderColor: colors.strokeNeutralLight,
  [darkThemeSelector]: {
    borderColor: colors.strokeNeutralDark,
  },
});

const FileList = styled('ul', {
  width: '100%',
  flexGrow: 1,
});

const ListItem = styled('li', {
  width: '100%',
  paddingX: '$16',
  borderBottom: '1px solid',
  borderColor: colors.strokeNeutralLight,
  [darkThemeSelector]: {
    borderColor: colors.strokeNeutralDark,
  },
  paddingY: '$8',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  variants: {
    canUploadMore: {
      false: {
        '&:last-child': {
          borderBottom: 'none', // or just `border: 'none'` for all borders
        },
      },
    },
  },
});

const ListHeader = styled('div', {
  borderBottom: '1px solid',
  width: '100%',
  paddingX: '$16',
  paddingY: '$8',
  borderColor: colors.strokeNeutralLight,
  [darkThemeSelector]: {
    borderColor: colors.strokeNeutralDark,
  },
});

const ErrorContainer = styled('div', {
  display: 'flex',
  gap: '$8',
  alignItems: 'center',
  justifyContent: 'flex-end',
  variants: {
    compact: {
      false: {
        minWidth: '170px',
      },
    },
  },
});

const UploadFailedLabel = styled('span', {
  paddingLeft: '$4',
});

const ProgressContainer = styled('div', {
  display: 'block',
  minWidth: '100px',
  paddingLeft: '$8',
});

const ErrorBody = styled(Body, {
  display: 'flex',
  alignItems: 'center',
  color: colors.controlStrokeBaseErrorLight,
  [darkThemeSelector]: {
    color: colors.controlStrokeBaseErrorDark,
  },
});

export const DropzoneIcon = styled(Icon, {
  [darkThemeSelector]: {
    color: colors.gray50,
  },
});

function generateCTA(allowedTypes?: DropzoneUploaderProps['allowedTypes']): string {
  if (allowedTypes?.images && allowedTypes?.videos) {
    return 'Drop media here to upload...';
  }
  if (allowedTypes?.images) {
    return 'Drop images here to upload...';
  }
  if (allowedTypes?.videos) {
    return 'Drop videos here to upload...';
  }
  return 'Drop files here to upload...';
}

function DeleteButton(props: { onClick: () => void }) {
  return (
    <Button
      icon="trash-can"
      variant="secondary"
      arrangement="hidden-label"
      size="small"
      onClick={props.onClick}
    >
      Delete
    </Button>
  );
}

const DeleteContainer = styled('div', {
  paddingLeft: '$2',
});

/**
 * **🚨 You probably don't want to use this component directly!**
 *
 * This component is only handles the UI for uploading files.
 *
 * The UnifiedUploader component from `@meterup/common wraps` this _and_ handles the heavy lifting of uploading files.
 *
 * Unless you have a good reason, _use that instead._
 */
export function DropzoneUploader(props: DropzoneUploaderProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: props.onDrop,
    accept: generateAccept(props.accept, props.allowedTypes),
    maxFiles: props.maxFiles,
    multiple: props.maxFiles !== 1,
  });

  const [hoveredId, setHoveredId] = useState<string | null>(null);

  const cta = props.callToAction ?? generateCTA(props.allowedTypes);

  let canUploadMore = true;
  if (props.maxFiles !== undefined && props.files.length >= props.maxFiles) {
    canUploadMore = false;
  }

  if (props.files.length > 0) {
    return (
      <Container>
        <ListHeader>
          <Small weight="bold">Filename</Small>
        </ListHeader>
        <FileList>
          {props.files.map((file) => {
            const errorMessage = isValidationError(file.error)
              ? file.error.message
              : 'Upload failed';
            return (
              <ListItem
                canUploadMore={canUploadMore}
                key={file.id}
                onMouseEnter={() => setHoveredId(file.id)}
                onMouseLeave={() => setHoveredId(null)}
              >
                <Small
                  style={{
                    maxWidth: 'calc(100% - 32px)', // size of the delete button
                    overflow: 'clip',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {file.fileObject.name}
                </Small>
                {!file.error &&
                  (file.uploadPercentage === 100 || hoveredId === file.id ? (
                    <DeleteContainer>
                      <DeleteButton onClick={() => props.onFileRemoved(file.id)} />
                    </DeleteContainer>
                  ) : (
                    <ProgressContainer>
                      <ProgressBar progressPercentage={file.uploadPercentage} />
                    </ProgressContainer>
                  ))}
                {file.error && (
                  <ErrorContainer compact={props.compact}>
                    <ErrorBody>
                      {!props.compact && (
                        <>
                          <Icon icon="warning" size={16} />
                          <UploadFailedLabel>{errorMessage}</UploadFailedLabel>
                        </>
                      )}
                      {props.compact && (
                        <IconTooltip
                          contents={errorMessage}
                          icon="warning"
                          size={16}
                          variant="negative"
                        />
                      )}
                    </ErrorBody>
                    {props.onRetryUpload && (
                      <Button
                        icon="arrows-rotate"
                        variant="secondary"
                        arrangement="hidden-label"
                        size="small"
                        onClick={() => props.onRetryUpload?.(file.id)}
                      >
                        Retry
                      </Button>
                    )}
                    <DeleteButton onClick={() => props.onFileRemoved(file.id)} />
                  </ErrorContainer>
                )}
              </ListItem>
            );
          })}
        </FileList>
        {canUploadMore && (
          <AlreadyUploadedDropContainer
            {...getRootProps()}
            active={isDragActive}
            compact={props.compact}
          >
            <input {...getInputProps()} />
            <span>
              <DropzoneIcon icon="document" size={16} /> <Body>{cta}</Body>
            </span>
            <Button icon="search" variant="secondary" arrangement="leading-icon">
              Browse...
            </Button>
          </AlreadyUploadedDropContainer>
        )}
      </Container>
    );
  }

  // TBD: could we use empty state component here?
  return (
    <Container {...getRootProps()} active={isDragActive} empty>
      <EmptyDropContainer>
        <input {...getInputProps()} />
        <VStack spacing={8} justify="center" align="center">
          <Body>
            {/* Icon is wrapped in Body to get automatic dark/light mode color */}
            <Icon icon="document" size={20} />
          </Body>
          <Body>{cta}</Body>
          <Button icon="search" variant="secondary" arrangement="leading-icon">
            Browse...
          </Button>
        </VStack>
      </EmptyDropContainer>
    </Container>
  );
}
