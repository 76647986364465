import type { ValidationResult } from '@react-types/shared/src/inputs';
import type { ReactNode } from 'react';

export default function filterErrorMessage(
  errorMessage?: ReactNode | ((v: ValidationResult) => ReactNode),
) {
  if (typeof errorMessage !== 'function') {
    return errorMessage;
  }
  return undefined;
}
