const urls = {
  checkeredLight: `url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' opacity='0.02' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H4V4H0V0Z' fill='black'/%3E%3Cpath d='M4 4H8V8H4V4Z' fill='black'/%3E%3Cpath d='M0 4H4V8H0V4Z' fill='white'/%3E%3Cpath d='M4 0H8V4H4V0Z' fill='white'/%3E%3C/svg%3E%0A")`,
  checkeredDark: `url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' opacity='0.02' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H4V4H0V0Z' fill='black'/%3E%3Cpath d='M4 4H8V8H4V4Z' fill='black'/%3E%3Cpath d='M0 4H4V8H0V4Z' fill='white'/%3E%3Cpath d='M4 0H8V4H4V0Z' fill='white'/%3E%3C/svg%3E%0A")`,
  dottedLight: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.04'%3E%3Ccircle cx='10' cy='10' r='2' fill='%23131522'/%3E%3C/g%3E%3C/svg%3E%0A")`,
  dottedDark: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.08'%3E%3Ccircle cx='10' cy='10' r='2' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A")`,
};

export const backgrounds = {
  checkeredLight: `${urls.checkeredLight} center center`,
  checkeredDark: `${urls.checkeredDark} center center`,
  dottedLight: `${urls.dottedLight} center center`,
  dottedDark: `${urls.dottedDark} center center`,
};
