import React from 'react';

import { backgrounds } from '../../common/backgrounds';
import { sizing } from '../../common/sizing';
import { colors, darkThemeSelector, styled } from '../../stitches.config';

const VisualizerTip = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$6',
});

const VisualizerHorizontals = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$6',
  width: '100%',
});

const VisualizerStart = styled(VisualizerHorizontals);
const VisualizerEnd = styled(VisualizerHorizontals, {
  justifyContent: 'flex-end',
});

const VisualizerVerticals = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$8',
  padding: `$4 $8`,
  overflow: 'auto',
  background: colors.bgNeutralLight,
  strokeAll: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    strokeAll: colors.strokeNeutralDark,
  },
});

const VisualizerTop = styled(VisualizerVerticals, {
  borderRadiusTop: '$10',
});

const VisualizerBottom = styled(VisualizerVerticals, {
  borderRadiusBottom: '$10',
});

const VisualizerCenter = styled('div', {
  margin: '0 auto',
});

const VisualizerScroll = styled('div', {
  display: 'flex',
  width: '100%',
  padding: '$60 $40',
  overflow: 'auto',
});

const VisualizerArea = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderRadius: '$10',
  background: backgrounds.dottedLight,
  backgroundColor: colors.bgNeutralLight,
  strokeAll: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    background: backgrounds.dottedDark,
    backgroundColor: colors.bgNeutralDark,
    strokeAll: colors.strokeNeutralDark,
  },
});

const VisualizerContainer = styled('div', {
  width: '100%',
  padding: sizing.squish,
});

export type VisualizerProps = {
  controls?: React.ReactNode;
  properties?: React.ReactNode;
  state?: React.ReactNode;
  status?: React.ReactNode;
  tip?: React.ReactNode;
  visuals: React.ReactNode;
};

export function Visualizer({
  controls,
  properties,
  state,
  status,
  tip,
  visuals,
  ...remaining
}: VisualizerProps) {
  return (
    <VisualizerContainer {...remaining}>
      <VisualizerArea>
        {(state || controls) && (
          <VisualizerTop>
            <VisualizerStart>{state}</VisualizerStart>
            <VisualizerTip>{tip}</VisualizerTip>
            <VisualizerEnd>{controls}</VisualizerEnd>
          </VisualizerTop>
        )}
        <VisualizerScroll>
          <VisualizerCenter>{visuals}</VisualizerCenter>
        </VisualizerScroll>
        {(status || properties) && (
          <VisualizerBottom>
            <VisualizerStart>{status}</VisualizerStart>
            <VisualizerEnd>{properties}</VisualizerEnd>
          </VisualizerBottom>
        )}
      </VisualizerArea>
    </VisualizerContainer>
  );
}
