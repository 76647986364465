import type { BadgeSize } from '../../components/Badge/Badge';
import { Badge } from '../../components/Badge/Badge';
import { Tooltip } from '../../components/Tooltip/Tooltip';

type FeatureBadgeArrangement = 'icon-label' | 'icon-only' | 'label-only';
type FeatureBadgeType = 'beta' | 'internal';

type FeatureBadgeProps = {
  arrangement?: FeatureBadgeArrangement;
  size?: BadgeSize;
  type: FeatureBadgeType;
};

const getFeatureBadgeArrangement = ({ arrangement }: { arrangement: FeatureBadgeArrangement }) => {
  if (arrangement === 'icon-label') return 'leading-icon';
  if (arrangement === 'icon-only') return 'hidden-label';
  return undefined;
};

const getFeatureBadgeIcon = ({ type }: { type: FeatureBadgeType }) => {
  if (type === 'internal') return 'operator';
  if (type === 'beta') return 'beta';
  return undefined;
};

const getFeatureBadgeVariant = ({ type }: { type: FeatureBadgeType }) => {
  if (type === 'beta') return 'brand';
  return 'neutral';
};

const getFeatureBadgeLabel = ({ type }: { type: FeatureBadgeType }) => {
  if (type === 'internal') return 'Internal';
  if (type === 'beta') return 'Beta';
  return 'Unknown';
};

export default function FeatureBadge({
  arrangement = 'label-only',
  size = 'small',
  type,
}: FeatureBadgeProps) {
  const renderBadge = (
    <Badge
      arrangement={getFeatureBadgeArrangement({ arrangement })}
      icon={arrangement !== 'label-only' ? getFeatureBadgeIcon({ type }) : undefined}
      ends="card"
      internal={type === 'internal'}
      size={size}
      variant={getFeatureBadgeVariant({ type })}
    >
      {getFeatureBadgeLabel({ type })}
    </Badge>
  );

  if (arrangement === 'icon-only') {
    return <Tooltip contents={getFeatureBadgeLabel({ type })}>{renderBadge}</Tooltip>;
  }

  return renderBadge;
}
