import React from 'react';

import { fade, palette } from '../../common/colors';
import { selectors } from '../../controls/shared/styles';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';

export type TextareaSize = 'small' | 'medium' | 'large' | 'x-large';

export interface TextareaProps {
  disabled?: boolean;
  invalid?: boolean;
  name?: string;
  onBlur?: (event: any) => void;
  onChange?: (event: any) => void;
  onFocus?: (event: any) => void;
  placeholder?: string;
  rows?: number;
  size?: TextareaSize;
  value?: string;
}

const TextareaContainer = styled('textarea', {
  display: 'flex',
  width: '100%',
  overflow: 'auto',
  backgroundColor: colors.bgApplicationLight,
  strokeAll: fade(palette.controlStrokeBaseLight, 0.12),
  color: colors.headingNeutralLight,
  cursor: 'text',
  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    strokeAll: fade(palette.controlStrokeBaseDark, 0.44),
    color: colors.headingNeutralDark,
  },
  [selectors.hover]: {
    strokeAll: fade(palette.controlStrokeBaseLight, 0.18),
    [darkThemeSelector]: {
      strokeAll: fade(palette.controlStrokeBaseDark, 0.66),
    },
  },
  [selectors.focus]: {
    outline: 'none',
    boxShadow: shadows.focusRingLight,
    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },
  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none',
        backgroundColor: colors.bgNeutralLight,
        strokeAll: fade(palette.controlStrokeBaseLight, 0.3),
        cursor: 'not-allowed',
        [darkThemeSelector]: {
          backgroundColor: colors.bgNeutralDark,
          strokeAll: fade(palette.controlStrokeBaseDark, 0.36),
        },
      },
      false: {},
    },
    invalid: {
      true: {
        strokeAll: fade(palette.controlStrokeBaseErrorLight, 0.8),
        [darkThemeSelector]: {
          strokeAll: fade(palette.controlStrokeBaseErrorDark, 0.8),
        },
      },
      false: {},
    },
    size: {
      small: {
        minHeight: '$20',
        padding: '$4 $6',
        fontSize: '$12',
        lineHeight: '$16',
        borderRadius: '$8',
      },
      medium: {
        minHeight: '$28',
        padding: '$4 $8',
        fontSize: '$14',
        lineHeight: '$20',
        borderRadius: '$8',
      },
      large: {
        minHeight: '$36',
        padding: '$8 $12',
        fontSize: '$14',
        lineHeight: '$20',
        borderRadius: '$8',
      },
      'x-large': {
        minHeight: '$44',
        padding: '$10 $14',
        fontSize: '$16',
        lineHeight: '$24',
        borderRadius: '$8',
      },
    },
  },
});

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      disabled = false,
      invalid = false,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      rows = 3,
      size = 'medium',
      value,
      ...remaining
    },
    ref,
  ) => {
    const handleOnBlur = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onBlur) {
        event.preventDefault();
        onBlur(event);
      }
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        event.preventDefault();
        onChange(event);
      }
    };

    const handleOnFocus = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onFocus) {
        event.preventDefault();
        onFocus(event);
      }
    };

    return (
      <TextareaContainer
        ref={ref}
        name={name}
        id={name}
        rows={rows}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        invalid={invalid}
        size={size}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        {...remaining}
      />
    );
  },
);
