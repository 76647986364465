import React from 'react';

import { colors, darkThemeSelector, styled } from '../../stitches.config';

export const FieldContainerBase = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  background: colors.bgNeutralLight,
  strokeAll: colors.strokeNeutralLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    strokeAll: colors.strokeNeutralDark,
  },

  [`& > div:first-child`]: {
    borderRadiusTop: '$8',
  },

  [`& > div:last-child`]: {
    borderRadiusBottom: '$8',
  },

  variants: {
    background: {
      application: {
        background: colors.bgApplicationLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
        },
      },
      neutral: {
        background: colors.bgNeutralLight,

        [darkThemeSelector]: {
          background: colors.bgNeutralDark,
        },
      },
    },
  },
});

const FieldContainerContext = React.createContext(false);

export function useFieldContainerContext() {
  return React.useContext(FieldContainerContext);
}

export const FieldContainer = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    background?: 'application' | 'neutral';
  }
>(({ background = 'neutral', ...props }, ref) => (
  <FieldContainerContext.Provider value>
    <FieldContainerBase background={background} {...props} ref={ref} />
  </FieldContainerContext.Provider>
));

export function MaybeFieldContainer({
  background = 'neutral',
  children,
}: {
  background?: 'application' | 'neutral';
  children: React.ReactNode;
}) {
  const isInsideFieldContainer = useFieldContainerContext();
  const WrapperEl = isInsideFieldContainer ? React.Fragment : FieldContainer;
  const wrapperProps = isInsideFieldContainer ? {} : { background };
  return <WrapperEl {...wrapperProps}>{children}</WrapperEl>;
}
