import type { PortProps } from '../Port/Port';
import { Device } from '../Device/Device';
import { Port } from '../Port/Port';
import { Slots } from '../Slots/Slots';

export type SwitchProps = {
  ports: PortProps[];
  sfpRows: 1 | 2;
};

const isOdd = (number: number) => number % 2 !== 0;

const getOrientation = (rows: 1 | 2, port: PortProps) => {
  if (rows === 2) {
    return port.number && isOdd(port.number) ? 'up' : 'down';
  }
  return port.orientation ?? port.port === 'sfp' ? 'down' : 'up';
};

export function Switch({ ports, sfpRows = 2, ...remaining }: SwitchProps) {
  const sfpPorts: PortProps[] = [];
  const ethernetPorts = ports.filter((port) => {
    if (port.port === 'sfp') {
      sfpPorts.push(port);
      return false;
    }

    return true;
  });
  return (
    <Device
      {...remaining}
      slots={
        <>
          <Slots
            start={1}
            rows={2}
            slots={ethernetPorts.map(({ active, connection, onClick, ...port }) => ({
              diagram: (
                <Port
                  key={port.number}
                  orientation={getOrientation(2, port)}
                  {...port}
                  onClick={undefined}
                />
              ),
              connection,
              active,
              onClick,
            }))}
          />
          {sfpPorts.length > 0 && (
            <Slots
              start={sfpPorts[0].number}
              rows={sfpRows}
              orientation={sfpRows === 1 ? 'top' : undefined}
              slots={sfpPorts.map(({ active, connection, onClick, ...port }) => ({
                diagram: (
                  <Port
                    key={port.number}
                    orientation={getOrientation(sfpRows, port)}
                    {...port}
                    onClick={undefined}
                  />
                ),
                connection,
                active,
                onClick,
              }))}
            />
          )}
        </>
      }
    />
  );
}
