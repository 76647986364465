import React from 'react';
import { useLabel } from 'react-aria';

import type { CheckboxInputProps } from '../CheckboxInput/CheckboxInput';
import type { LabelProps } from '../Label/Label';
import { CheckboxInput } from '../CheckboxInput/CheckboxInput';
import { Label } from '../Label/Label';

export interface CheckboxInputWithLabelProps extends CheckboxInputProps, LabelProps {
  children: React.ReactNode;
}

export function CheckboxInputWithLabel({
  checked = false,
  children,
  disabled = false,
  indeterminate = false,
  invalid,
  name,
  onChange,
  ...rest
}: CheckboxInputWithLabelProps) {
  const { labelProps, fieldProps } = useLabel(rest);
  return (
    <Label htmlFor={name} disabled={disabled} invalid={invalid} {...labelProps}>
      <CheckboxInput
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        indeterminate={indeterminate}
        {...fieldProps}
      />
      {children}
    </Label>
  );
}
