import { createContext, useContext } from 'react';

import type { ControlVariant } from '../controls/shared/types';

const ControlVariantContext = createContext<ControlVariant | undefined>(undefined);

/**
 * Allows setting a default size for all descendent controls.
 *
 * @example
 *   <ControlVariantProvider value={ControlVariant.Small}>
 *     <Button>Small button</Button>
 *   </ControlVariantProvider>
 */
export const ControlVariantProvider = ControlVariantContext.Provider;

/**
 * Returns the current control size from context if defined, otherwise returns the default size.
 */
export const useControlVariant = (
  controlledValue?: ControlVariant,
  defaultValue: ControlVariant = 'primary',
) => {
  const controlSize = useContext(ControlVariantContext);
  return controlledValue ?? controlSize ?? defaultValue;
};
