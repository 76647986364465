import { match } from 'ts-pattern';

import { darkThemeSelector, shadows } from '../../stitches.config';

export type ShadowTypes = 'overlay' | 'modal';
export type ShadowProps = { shadow?: ShadowTypes };

export const shadowCSS = (shadow?: ShadowTypes) =>
  match(shadow)
    .with('overlay', () => ({
      boxShadow: shadows.overlayLight,
      [darkThemeSelector]: { boxShadow: shadows.overlayDark },
    }))
    .with('modal', () => ({
      boxShadow: shadows.modalLight,
      [darkThemeSelector]: { boxShadow: shadows.modalDark },
    }))
    .with(undefined, () => ({}))
    .exhaustive();
