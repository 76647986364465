import { merge } from 'lodash-es';
import React from 'react';

import type { ColorProps } from '../../utilities/shared/Color';
import type { SizingScale } from '../../utilities/shared/sizes';
import { keyframes, styled } from '../../stitches.config';
import { colorCSS } from '../../utilities/shared/Color';
import { heightCSS } from '../../utilities/shared/Height';
import { widthCSS } from '../../utilities/shared/Width';

const BaseSVG = styled('svg');

const circleBounce = keyframes({
  '0%': { transform: 'translate(0,0)' },
  '8.333333%': { transform: 'translate(0,0)' },
  '25%': { transform: 'translate(0,-8px)' },
  '41.666667%': { transform: 'translate(0,4px)' },
  '58.333333%': { transform: 'translate(0,-2px)' },
  '75%': { transform: 'translate(0,0)' },
  '100%': { transform: 'translate(0,0)' },
});

const Circle = styled('circle', {
  fill: 'currentColor',
  animationName: circleBounce.name,
  animationDuration: '1200ms',
  animationIterationCount: 'infinite',
  animationFillMode: 'forwards',
  willChange: 'transform',
  animationTimingFunction: 'cubic-bezier(0.42,0,0.58,1)',
});

export type LoadingIconProps = {
  /**
   * Set an HTML classname.
   */
  className?: string;
  /**
   * Accepts any color value from our [colorset](/?path=/docs/colors).
   */
  color?: ColorProps;
  /**
   * A numeric value which will be represented in `px` and in increments of 4.
   */
  size?: SizingScale;
};

export function LoadingIcon({ className, color, size, ...remaining }: LoadingIconProps) {
  const css = merge(colorCSS(color?.dark, color?.light), widthCSS(size), heightCSS(size));

  return (
    <BaseSVG
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      css={css}
      className={className}
      {...remaining}
    >
      <g transform="translate(7,20)">
        <Circle r="3" />
      </g>
      <g transform="translate(20,20)">
        <Circle r="3" style={{ animationDelay: '100ms' }} />
      </g>
      <g transform="translate(33,20)">
        <Circle r="3" style={{ animationDelay: '200ms' }} />
      </g>
    </BaseSVG>
  );
}
