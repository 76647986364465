import type * as Polymorphic from '@radix-ui/react-polymorphic';
import type { ReactNode } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { zIndexes } from '../../common/z_indexes';
import { ControlGroup } from '../../controls/ControlGroup/ControlGroup';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import { isDefined } from '../../utilities/isDefined';

const ToastIcon = styled(Icon, {
  display: 'flex',
  color: '$$iconColor',
});

const ToastHeading = styled(Body, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$4',
  color: '$$headingColor',
  fontWeight: fontWeights.bold,
});

const ToastContainer = styled('div', {
  position: 'relative',
  zIndex: zIndexes.alert,
  display: 'flex',
  flexDirection: 'row',
  width: 'max-content',
  maxWidth: '$720',
  padding: '$8 $16',
  backgroundColor: '$$backgroundColor',
  boxShadow: shadows.overlayLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    boxShadow: shadows.overlayDark,
  },

  '&:before': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '$10',
    strokeAll: '$$strokeColor',
  },

  variants: {
    variant: {
      alternative: {
        $$backgroundColor: colors.bgAlternativeLight,
        $$strokeColor: colors.strokeAlternativeLight,
        $$headingColor: colors.headingAlternativeLight,
        $$iconColor: colors.iconAlternativeLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgAlternativeDark,
          $$strokeColor: colors.strokeAlternativeDark,
          $$headingColor: colors.headingAlternativeDark,
          $$iconColor: colors.iconAlternativeDark,
        },
      },
      attention: {
        $$backgroundColor: colors.bgAttentionLight,
        $$strokeColor: colors.strokeAttentionLight,
        $$headingColor: colors.headingAttentionLight,
        $$iconColor: colors.iconAttentionLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgAttentionDark,
          $$strokeColor: colors.strokeAttentionDark,
          $$headingColor: colors.headingAttentionDark,
          $$iconColor: colors.iconAttentionDark,
        },
      },
      brand: {
        $$backgroundColor: colors.bgBrandLight,
        $$strokeColor: colors.strokeBrandLight,
        $$headingColor: colors.headingBrandLight,
        $$iconColor: colors.iconBrandLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgBrandDark,
          $$strokeColor: colors.strokeBrandDark,
          $$headingColor: colors.headingBrandDark,
          $$iconColor: colors.iconBrandDark,
        },
      },
      negative: {
        $$backgroundColor: colors.bgNegativeLight,
        $$strokeColor: colors.strokeNegativeLight,
        $$headingColor: colors.headingNegativeLight,
        $$iconColor: colors.iconNegativeLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgNegativeDark,
          $$strokeColor: colors.strokeNegativeDark,
          $$headingColor: colors.headingNegativeDark,
          $$iconColor: colors.iconNegativeDark,
        },
      },
      neutral: {
        $$backgroundColor: colors.bgNeutralLight,
        $$strokeColor: colors.strokeNeutralLight,
        $$headingColor: colors.headingNeutralLight,
        $$iconColor: colors.iconNeutralLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgNeutralDark,
          $$strokeColor: colors.strokeNeutralDark,
          $$headingColor: colors.headingNeutralDark,
          $$iconColor: colors.iconNeutralDark,
        },
      },
      positive: {
        $$backgroundColor: colors.bgPositiveLight,
        $$strokeColor: colors.strokePositiveLight,
        $$headingColor: colors.headingPositiveLight,
        $$iconColor: colors.iconPositiveLight,

        [darkThemeSelector]: {
          $$backgroundColor: colors.bgPositiveDark,
          $$strokeColor: colors.strokePositiveDark,
          $$headingColor: colors.headingPositiveDark,
          $$iconColor: colors.iconPositiveDark,
        },
      },
    },
  },
});

export type ToastVariant =
  | 'alternative'
  | 'attention'
  | 'brand'
  | 'negative'
  | 'neutral'
  | 'positive';

export interface ToastProps {
  actions?: ReactNode;
  heading: ReactNode;
  icon?: IconName;
  variant?: ToastVariant;
}

export const Toast = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      actions,
      heading,
      icon,
      variant = 'neutral',
      ...props
    }: PolymorphicComponentProps<Tag, ToastProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => (
    <ToastContainer {...props} ref={forwardedRef} variant={variant}>
      <AlignStack
        direction="row"
        gap={{
          start: 6,
          end: 12,
        }}
        preset="body"
        start={isDefined(icon) && <ToastIcon icon={icon} />}
        end={
          isDefined(actions) && (
            <ControlGroup relation="separate" size="small">
              {actions}
            </ControlGroup>
          )
        }
      >
        {heading && <ToastHeading>{heading}</ToastHeading>}
      </AlignStack>
    </ToastContainer>
  ),
) as Polymorphic.ForwardRefComponent<'div', ToastProps>;
