import type { SpacingScale } from '../../utilities/shared/sizes';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';

const AssetTilePositioner = styled('div', {
  display: 'flex',

  variants: {
    ratio: {
      'x-small': {
        width: '100%',
        maxWidth: '100%',
        minWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        minHeight: '100%',
      },
      small: {
        width: '64%',
        maxWidth: '64%',
        minWidth: '64%',
        height: '64%',
        maxHeight: '64%',
        minHeight: '64%',
      },
      medium: {
        width: '56%',
        maxWidth: '56%',
        minWidth: '56%',
        height: '56%',
        maxHeight: '56%',
        minHeight: '56%',
      },
      large: {
        width: '48%',
        maxWidth: '48%',
        minWidth: '48%',
        height: '48%',
        maxHeight: '48%',
        minHeight: '48%',
      },
    },
    unset: {
      true: {
        width: '100%',
        maxWidth: '100%',
        minWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        minHeight: '100%',
      },
      false: {},
    },
  },
});

const AssetTileContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.bgApplicationLight,

  variants: {
    appearance: {
      visible: {
        boxShadow: shadows.manufacturerLight,

        [darkThemeSelector]: {
          boxShadow: shadows.manufacturerDark,
        },
      },
      hidden: {},
    },
    radius: {
      'x-small': {
        borderRadius: '$4',
      },
      small: {
        borderRadius: '$6',
      },
      medium: {
        borderRadius: '$10',
      },
      large: {
        borderRadius: '$12',
      },
    },
  },
});

export type AssetTileProps = {
  /**
   * The element to show inside of the tile.
   */
  children: React.ReactNode;
  /**
   * Set a size of the component.
   */
  size?: SpacingScale;
  /**
   * Unsets the sizing of the SVG inside of the tile.
   */
  unset?: boolean;
};

const getTileSizeString = (size: number) => {
  switch (true) {
    case size >= 44:
      return 'large';
    case size >= 32:
      return 'medium';
    case size >= 20:
      return 'small';
    default:
      return 'x-small';
  }
};

export function AssetTile({ children, size = 28, unset = false, ...remaining }: AssetTileProps) {
  return (
    <AssetTileContainer
      appearance={getTileSizeString(size) === 'x-small' ? 'hidden' : 'visible'}
      radius={getTileSizeString(size)}
      css={{
        width: `$${size}`,
        maxWidth: `$${size}`,
        minWidth: `$${size}`,
        height: `$${size}`,
        maxHeight: `$${size}`,
        minHeight: `$${size}`,
      }}
      {...remaining}
    >
      <AssetTilePositioner ratio={getTileSizeString(size)} unset={unset}>
        {children}
      </AssetTilePositioner>
    </AssetTileContainer>
  );
}
