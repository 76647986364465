import { Slot } from '@radix-ui/react-slot';
import React, { useContext } from 'react';
import { mergeProps, useId, useLabel } from 'react-aria';

import type { FieldPropsWithElement } from './common/types';
import { VisuallyHidden } from '../../common/visually_hidden';
import filterErrorMessage from '../../utils/filterErrorMessage';
import { CompositeAriaContext } from './common/CompositeAria';
import { ErrorMessageFakePortal } from './common/ErrorMessageRegistry';
import { useCustomFieldProps } from './createCustomFieldPropsProvider';

interface CompositeFieldProps extends FieldPropsWithElement {}

export function CompositeField(props: CompositeFieldProps) {
  const { fieldProps: customFieldProps, inputProps: customInputProps } = useCustomFieldProps();

  const {
    label = customFieldProps.label,
    errorMessage = customFieldProps.errorMessage,
    element,
  } = props;

  const { labelProps, fieldProps: inputProps } = useLabel({
    label,
  });

  const errorId = useId();
  const ariaPropsFromContext = useContext(CompositeAriaContext);

  const ariaProps = errorMessage ? { 'aria-describedby': errorId } : ariaPropsFromContext;

  return (
    <>
      <VisuallyHidden as="label" {...labelProps}>
        {label}
      </VisuallyHidden>
      <Slot {...mergeProps(customInputProps, inputProps, ariaProps)}>{element}</Slot>
      {errorMessage && (
        <ErrorMessageFakePortal id={errorId} errorMessage={filterErrorMessage(errorMessage)} />
      )}
    </>
  );
}
