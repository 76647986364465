import React from 'react';

import type { PolymorphicComponentProps } from '../../utilities/types/polymorphicAsProp';
import { selectors } from '../../controls/shared/styles';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { Text } from '../../text/Text';

const LinkContainer = styled('a', Text, {
  position: 'relative',
  color: colors.linkInitialLight,
  fontWeight: '$bold',
  textDecoration: 'none',
  cursor: 'pointer',

  [darkThemeSelector]: {
    color: colors.linkInitialDark,
  },

  [selectors.hover]: {
    color: colors.linkHoverLight,

    [darkThemeSelector]: {
      color: colors.linkHoverDark,
    },
  },

  [selectors.focus]: {
    outline: 'none',

    '&::before': {
      content: '',
      position: 'absolute',
      inset: '-$2 -$4',
      display: 'flex',
      boxShadow: shadows.focusRingLight,
      borderRadius: '$6',

      [darkThemeSelector]: {
        boxShadow: shadows.focusRingDark,
      },
    },
  },

  variants: {
    muted: {
      true: {
        color: colors.gray500,

        [darkThemeSelector]: {
          color: colors.gray300,
        },
      },
      false: {},
    },
    invalid: {
      true: {
        color: colors.red600,

        [darkThemeSelector]: {
          color: colors.red300,
        },
      },
      false: {},
    },
  },
});
LinkContainer.displayName = 'LinkContainer';

export interface LinkProps {
  /**
   * Mark the component as having an issue.
   */
  invalid?: boolean;
  /**
   * Mark the component as muted.
   */
  muted?: boolean;
}

function LinkInner<Tag extends React.ElementType>(
  { invalid = false, muted = false, children, ...props }: PolymorphicComponentProps<Tag, LinkProps>,
  ref: PolymorphicRef<Tag>,
) {
  return (
    <LinkContainer muted={muted} invalid={invalid} {...props} ref={ref}>
      {children}
    </LinkContainer>
  );
}

type PropsOf<T extends React.ElementType> = React.ComponentPropsWithRef<T>;

type PolymorphicRef<T extends React.ElementType> = React.ComponentPropsWithRef<T>['ref'];

type PolymorphicProps<T extends React.ElementType = React.ElementType, TProps = {}> = {
  as?: T;
} & TProps &
  Omit<PropsOf<T>, keyof TProps | 'as' | 'ref'> & { ref?: PolymorphicRef<T> };

export const Link = React.forwardRef(LinkInner) as <T extends React.ElementType = 'a'>(
  props: PolymorphicProps<T, LinkProps>,
) => React.JSX.Element | null;
