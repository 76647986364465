import { createContext, useContext } from 'react';

export type BetaProp = boolean | string;

const BetaContext = createContext<BetaProp | undefined>(undefined);

export const BetaProvider = BetaContext.Provider;

export function useBeta(controlledValue?: BetaProp, defaultValue: BetaProp = false) {
  const isBeta = useContext(BetaContext);
  return controlledValue ?? isBeta ?? defaultValue;
}
