import React from 'react';

import { Button } from '../Button/Button';

type CloseButtonProps = {
  'aria-label'?: React.ReactNode;
  onClick?: React.ReactEventHandler;
};

export const CloseButton = React.forwardRef<HTMLButtonElement, CloseButtonProps>(
  ({ 'aria-label': ariaLabel = 'Close', onClick, ...remaining }, ref) => (
    <Button
      type="button"
      onClick={onClick}
      arrangement="hidden-label"
      icon="cross"
      size="small"
      variant="secondary"
      {...remaining}
      ref={ref}
    >
      {ariaLabel}
    </Button>
  ),
);
