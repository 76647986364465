import React from 'react';

import type { HasChildren } from '../../types/components';
import { colors, darkThemeSelector, styled } from '../../stitches.config';

const Container = styled('div', {
  position: 'relative',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  strokeRight: colors.strokeApplicationLight,
  background: colors.bgApplicationLight,
  [darkThemeSelector]: {
    strokeRight: colors.strokeApplicationDark,
    background: colors.bgApplicationDark,
  },
});

export function DeprecatedDesktopSidebar({ children }: HasChildren) {
  return <Container>{children}</Container>;
}
