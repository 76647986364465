import * as Ariakit from '@ariakit/react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { SummaryListPairsProps } from '../../components/SummaryList/SummaryList';
import { Icon } from '../../assets/Icon/Icon';
import { SummaryList } from '../../components/SummaryList/SummaryList';
import { Button } from '../../controls/Button/Button';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { Body } from '../../text/Body';

const HoverCardIcon = styled(Icon, {
  width: '$16',
  height: '$16',
});

const HoverCardHeading = styled(Body, {
  fontWeight: '$bold',
});

const HoverCardStart = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$6',
});

const HoverCardHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$8',
});

const HoverCardContainer = styled(Ariakit.Hovercard, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$12',
  padding: '$12 $16',
  maxWidth: '$320',
  background: colors.bgApplicationLight,
  borderRadius: '$10',
  boxShadow: shadows.overlayLight,

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    boxShadow: shadows.overlayDark,
  },
});

const HoverCardRender = styled('div');

const HoverCardAnchor = styled(Ariakit.HovercardAnchor);

const HoverCardProvider = styled(Ariakit.HovercardProvider);

export type HoverCardProps = {
  /**
   * The trigger for the hover card.
   */
  children: React.ReactNode;
  /**
   * The heading of the hover card.
   */
  heading: React.ReactNode;
  /**
   * The icon of the hover card.
   */
  icon?: IconName;
  /**
   * The link to the object the hover card describes.
   */
  link?: {
    /**
     * The component to render the link as.
     */
    as?: React.ElementType;
    /**
     * The to of the link.
     */
    to?: any;
  };
  /**
   * Whether the hover card is open.
   */
  open?: boolean;
  /**
   * The summary list pairs of the hover card.
   */
  summary?: SummaryListPairsProps[];
};

export function HoverCard({ children, heading, icon, link, open, summary }: HoverCardProps) {
  return (
    <HoverCardProvider>
      <HoverCardAnchor render={<HoverCardRender />} focusable>
        {children}
      </HoverCardAnchor>
      <HoverCardContainer gutter={8} flip portal open={open}>
        <HoverCardHeader>
          <HoverCardStart>
            {icon && <HoverCardIcon icon={icon} />}
            {heading && <HoverCardHeading>{heading}</HoverCardHeading>}
          </HoverCardStart>
          {link && (
            <Button
              as={link.as}
              to={link.to}
              arrangement="hidden-label"
              icon="chevron-right"
              size="small"
              variant="secondary"
            >
              Go to {heading}
            </Button>
          )}
        </HoverCardHeader>
        {summary && <SummaryList gutter="none" pairs={summary} />}
      </HoverCardContainer>
    </HoverCardProvider>
  );
}
