import { merge } from 'lodash-es';

export type RadiusTypes = 0 | 2 | 4 | 6 | 8 | 'full';
export type RadiusProps = {
  all?: RadiusTypes;
  top?: RadiusTypes;
  right?: RadiusTypes;
  bottom?: RadiusTypes;
  left?: RadiusTypes;
  topLeft?: RadiusTypes;
  topRight?: RadiusTypes;
  bottomLeft?: RadiusTypes;
  bottomRight?: RadiusTypes;
};

const radiusCSSInternal = (value: RadiusTypes) => (value === 'full' ? 99999 : value);

export const radiusCSS = (radius: RadiusProps = {}) =>
  merge(
    radius.all != null ? { borderRadius: radiusCSSInternal(radius.all) } : {},
    radius.top != null ? { borderRadiusTop: radiusCSSInternal(radius.top) } : {},
    radius.right != null ? { borderRadiusRight: radiusCSSInternal(radius.right) } : {},
    radius.bottom != null ? { borderRadiusBottom: radiusCSSInternal(radius.bottom) } : {},
    radius.left != null ? { borderRadiusLeft: radiusCSSInternal(radius.left) } : {},
    radius.topLeft != null ? { borderTopLeftRadius: radiusCSSInternal(radius.topLeft) } : {},
    radius.topRight != null ? { borderTopRightRadius: radiusCSSInternal(radius.topRight) } : {},
    radius.bottomRight != null
      ? { borderBottomRightRadius: radiusCSSInternal(radius.bottomRight) }
      : {},
    radius.bottomLeft != null
      ? { borderBottomLeftRadius: radiusCSSInternal(radius.bottomLeft) }
      : {},
  );
