import type { CSS } from '../../stitches.config';
import type { SpacingScale } from './sizes';

export type TranslateXTypes = SpacingScale;

export type TranslateXProps = {
  translateX?: TranslateXTypes;
};

export const translateXCSS = (translateX?: TranslateXTypes): CSS =>
  translateX != null
    ? {
        translateX: `$${translateX}`,
      }
    : {};
