import type { AccordionTriggerProps as BaseAccordionTriggerProps } from '@radix-ui/react-accordion';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React from 'react';

import { Icon } from '../../assets/Icon/Icon';
import { transitions } from '../../controls/shared/styles';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Body } from '../../text/Body';

const AccordionTriggerHeader = styled(AccordionPrimitive.Header, {
  all: 'unset',
  display: 'flex',
  padding: '$2 $0',
  justifyContent: 'space-between',
});

const AccordingTriggerStyled = styled(AccordionPrimitive.Trigger, {
  hStack: '$8',
});

const AccordionTriggerChevron = styled(Icon, {
  width: '$8',
  height: '$8',
  color: colors.bodyNeutralLight,
  transform: transitions.control,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },

  '[data-state=open] &': { transform: 'rotate(90deg)' },
});

type AccordionTriggerProps = BaseAccordionTriggerProps & {
  actions?: React.ReactNode;
};

const AccordionTriggerActionsContainer = styled('div', {
  hStack: '$8',
  padding: '$2 $20',
});

export const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, actions, ...props }, forwardedRef) => (
    <AccordionTriggerHeader>
      <AccordingTriggerStyled {...props} ref={forwardedRef}>
        <AccordionTriggerChevron icon="chevron-right" aria-hidden />
        {children ? <Body>{children}</Body> : null}
      </AccordingTriggerStyled>
      {actions && <AccordionTriggerActionsContainer>{actions}</AccordionTriggerActionsContainer>}
    </AccordionTriggerHeader>
  ),
);

const AccordionContentStyled = styled(AccordionPrimitive.Content, {
  overflow: 'hidden',
});

const AccordionContentText = styled('div', Body, {
  marginLeft: '$16',
});

export const AccordionContent = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLProps<HTMLDivElement>, HTMLDivElement>
>(({ children, ...props }, forwardedRef) => (
  <AccordionContentStyled {...props} ref={forwardedRef}>
    <AccordionContentText>{children}</AccordionContentText>
  </AccordionContentStyled>
));

export const AccordionItem = styled(AccordionPrimitive.Item, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '$0',
  padding: '$8 $16',
  strokeBottom: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    strokeBottom: colors.strokeNeutralDark,
  },

  '&[data-state=open]': { gap: '$10' },
});

export const Accordion = styled(AccordionPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '$6',
  strokeAll: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    strokeAll: colors.strokeNeutralDark,
  },

  [`& ${AccordionItem}:last-child`]: {
    strokeBottom: colors.transparent,
  },
});
