import React from 'react';

import { Box } from '../../utilities/Box/Box';

export interface ProgressBarProps {
  progressPercentage: number;
}

export function ProgressBar({ progressPercentage }: ProgressBarProps) {
  return (
    <Box
      position="relative"
      display="flex"
      height={4}
      bg={{ light: 'brand300', dark: 'brand800' }}
      overflow={{ all: 'hidden' }}
      radius={{ all: 'full' }}
    >
      <Box
        display="flex"
        bg={{ light: 'brand600', dark: 'brand600' }}
        radius={{ all: 'full' }}
        className="m-transition m-duration-150 m-ease-in-out "
        style={{ width: `${progressPercentage}%` }}
      />
    </Box>
  );
}
